import React from 'react';

const TableFields = [
  {
    keyName: 'name',
    columnName: 'Gruppo',
  },
  // {
  //   keyName: ['group.plants'],
  //   columnName: 'Impianti',
  // },
  {
    keyName: 'description',
    columnName: 'Descrizione',
  },
  {keyName: 'plant_group',
    columnName: 'Impianti',
    type : 'custom',
    filterElement:'id',
    component : (props) => {
      let combinedValue = [];
      if (props.value && props.value.length > 0) {
        props.value.map(
          item => combinedValue = [...combinedValue, item.name]
        );
        combinedValue = combinedValue.join(', ');
      }
      else
        combinedValue = '-';
      return <div style={{overflowX : 'hidden', textOverflow : 'ellipsis'}}>{combinedValue}</div>
    }
  }
]

export default TableFields
