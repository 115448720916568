import React from 'react';
import {Redirect} from "react-router-dom";
import {
  PageProvider,
} from "@cobrains/react-kit";
import { compose } from "redux";
import { connect } from "react-redux";
import { Route, Switch } from 'react-router';

function Routing(props){
  const isLogged = !!((props.auth && props.auth.user));

  return(
    <Switch>
      {props.routes.map((route, index) =>
        (route.private)? (
            <PrivateRoute
              key={`route-${index}`}
              isLogged={isLogged}
              exact={route.exact}
              path={route.path}
              layout={route.layout}
              component={() => route.component}
            />
          )
          : (
            <Route
              key={`route-${index}`}
              exact={route.exact}
              path={route.path}
              component={(props) => route.component({...props, isLogged})}
            />
          )
      )}
      <Redirect from='/signin' to='/' />
      <Redirect from='/' to='/signin' />
      <Redirect from='*' to='/404' />
    </Switch>
  )
}

const PrivateRoute = ({ component: Component, isLogged, ...rest }) => (
  <Route {...rest} render={(props) => (isLogged) ? (<PageProvider {...props} />) : <Redirect to='/signin' />} />
)

const mapStateToProps = state => ({
  auth: state.auth,
})

export default compose(connect(mapStateToProps))(Routing);
