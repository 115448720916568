import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en/translation.json';
import translationIT from './locales/it/translation.json';

// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: 'it',
        debug: (process.env.NODE_ENV === 'development'),
        resources: {
            en: {
                translation: translationEN
            },
            it: {
                translation: translationIT
            },
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        parseMissingKeyHandler: (key) => {
          return `TRANSLATION_NOT_FOUND`;
        }
    }, (err, t) => {
        if (err) return console.log('something went wrong loading', err);
    });

// The method we'll use instead of a regular string
export function translate(name, params = {}) {
    return i18n.t(name, params);
};

export function changeLocale(lng){
  i18n.changeLanguage(`${lng}`)
}


export default i18n;
