import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import moment from 'moment';
import DashboardCard from '../../../components/cards/DashboardCard';
import Background from '../../../assets/img/GoGreenForLife-Preview.png'

const useStyles = makeStyles(theme => ({
  root: {
    display : 'flex',
    padding: "40px",
    [theme.breakpoints.down('md')]:{
      padding : '15px',
    },
    height: 'calc(100vh - 56px)'
  },
  wrapper: {
    display: 'flex',
    flexDirection : 'column',
    flex : 1
  },
  header: {
    fontSize: 44
  },
  footer: {
  },
  main: {
    flex : 3,
    order: 2,
    background: theme.palette.background.main,
    backgroundImage:`url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    minHeight: 512,
    backgroundSize : 'contain'
  },
  date_time: {
    fontSize: 24
  },
  cards: {
    flex : 1,
    display : 'flex',
    flexDirection: 'column',
    justifyContent : 'flex-end'
  },
}));

function Dashboard(props){
  const classes = useStyles(props);
  const today = new Date();
  const [date, setDate] = React.useState( today);


  React.useEffect(() => {
    setInterval( () => {
      const today = new Date();
      setDate(today)
    },1000)
  }, []);

  const cards = [
    {icon: 'aziende', url:'/groups',  size:24, title: 'Gruppi'},
    {icon: 'utent1', url:'/users',  size:24, title: 'Utenti'},
    {icon: 'impianti', url:'/plants',  size:24, title: 'Impianti'},
    {icon: 'flussi_economici', url:'/dashboards',  size:24, title: 'Dashboards'},
  ];

  return (
    <div className={classes.root}>
      <div style={{display : 'flex' , flexDirection : 'column'}}>
        <Typography className={classes.header}>
          Bentornato
          {' '}
          {props.user.username}
        </Typography>
        <div className={classes.wrapper}>
          <Typography className={classes.date_time}>
            { moment(date).format('h:mm a')}
            <br />
            { moment(date).format('MMMM Do YYYY')}
          </Typography>
          <div className={classes.cards}>
            <Typography style={{fontSize : 20}}>Vai a</Typography>
            {cards.map(item => <DashboardCard {...item} />)}
          </div>
        </div>
      </div>
      <div className={classes.main} />
    </div>
  )
}
const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapActionsToProps = {

};

export default compose(
  withStyles({withTheme: true}),
  connect(mapStateToProps , mapActionsToProps)
)(Dashboard)

