
const TableFields = [
  {
    keyName: 'username',
    columnName: 'Username',
  },
  {
    keyName: 'email',
    columnName: 'Email',
  },
  {
    keyName: 'type',
    columnName: 'Tipo di utente',
  },
  {
    keyName : 'group.name',
    columnName : 'Gruppo'
  }
];

export default TableFields
