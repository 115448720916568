import {initialReactKitState, getCrudInitialState} from '@cobrains/react-kit'


const CRUD_MODELS = [
  'users',
  'plants',
  'groups',
  'tokens',
  'slider',
  'custom',
  'visual',
];

const crudInitialState = getCrudInitialState(CRUD_MODELS);

export const initialState = {
  ...initialReactKitState,
    ...crudInitialState,
};
