import React from 'react';
export function getItems(props , setOpenPassword){
  return [{
      key: 'add',
      label: 'Aggiungi',
      component: 'button',
      variant: 'contained',
      color: 'primary',
      icon: (defaultStyle) => <i className="icon-aggiungi" style={{ ...defaultStyle }} />,
      func : () => {
        props.onSetDialog('add', true);
        props.onPrepareUser()
      },
      style:{borderRadius:40, marginRight:8}
    },
    {
      key: 'edit',
      label: 'Modifica',
      component: 'button',
      variant: 'outlined',
      icon: (defaultStyle) => <i className="icon-modifica" style={{ ...defaultStyle }} />,
      disabled: (props.users.selected.length !== 1),
      func : () => {
        props.onSetDialog('edit', true);
        props.onPrepareUser(props.users.selected[0].id)
      },
      style:{borderRadius:40, marginRight:8}
    },
    {
      key: 'password',
      component: 'button',
      label: 'Cambia password',
      variant: 'outlined',
      icon: (defaultStyle) => <i className="icon-password" style={{ ...defaultStyle }} />,
      style:{borderRadius:40, marginRight:8},
      disabled: (props.users.selected.length < 1),
      func : () => setOpenPassword(true)
    },
    {
      key: 'delete',
      component: 'button',
      label: 'Elimina',
      variant: 'outlined',
      icon: (defaultStyle) => <i className="icon-elimina" style={{ ...defaultStyle }} />,
      disabled: (props.users.selected.length < 1),
      func : () => props.onSetDialog('delete', true),
      style:{borderRadius:40, marginRight:8}
    }]
}
