import SliderLayout from '../../views/application/slider/SliderLayout';
import DataDashboard from '../../views/application/data-dashboard/DataDashboardLayout';
import PlantDashboardLayout from '../../views/application/plant-Dashboard/PlantDashboardLayout';

export const DashboardRoutes = [
  /*{
    path: '/dashboard',
    name: 'Dashboard',
    component: SliderLayout,
    exact: true,
    private:false,
    seo:{
      title: 'Dashboard',
    },
  },*/
  {
    path: '/:id/slider',
    name: 'Slider',
    component: SliderLayout,
    exact: true,
    private:false,
    seo:{
      title: 'Slider',
    },
  },
  {
    path: '/:id/dashboard',
    name: 'Dashboard',
    component: DataDashboard,
    exact: true,
    private:false,
    seo:{
      title: 'Dashboard',
    },
  },
  {
    path: '/:id/plant',
    name: 'Plant Dashboard',
    component: PlantDashboardLayout,
    exact: true,
    private:false,
    seo:{
      title: 'Plant Dashboard',
    },
  }

];
