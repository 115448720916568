import React , {useEffect, useRef} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import { translate } from '../../../../translations/i18n';
import DataDashboard from './DataDashboard';

const useStyles = makeStyles(theme => ({
  root: {
    width:'28%',
    background:'#fff',
    borderRight: '1px solid #e0e0e0',
    [theme.breakpoints.down('sm')]:{
      background:'#f8f8f8',
    }
  },
  container: {
    padding: 20
  },
  img : {
    width : '50%',
    [theme.breakpoints.down('sm')]:{
      marginLeft : 'auto',
      width : '40%',
      padding : 8
    }
  },
  headerWrapper : {
    display : 'flex',
    flexDirection : 'column',
    alignItems : 'center',
    height : '26vh',
    justifyContent : 'flex-end'
  },
  textWrapper : {
    display : 'flex',
    alignItems : 'flex-end',
    justifyContent : 'space-between',
    width : '100%',
    paddingBottom : 28,
    [theme.breakpoints.down('sm')]:{
      flexDirection: 'column',
      alignItems : 'flex-start',
      justifyContent : 'flex-end',
      paddingBottom : 20,
    }
  },
  tileText : {
    fontSize : 24,
    padding : '0px 32px',
    fontWeight : 700,
    [theme.breakpoints.down('md')]:{
      fontSize : 18,
      padding : '0px 20px'
    },
    [theme.breakpoints.down('sm')]:{
    fontSize : 20
    }
  },
  tileTextSecond : {
    fontSize : 24,
    padding : '0px 32px',
    fontWeight : 700,
    textAlign : 'right',
    [theme.breakpoints.down('md')]:{
      fontSize : 18,
      padding : '0px 20px'
    },
    [theme.breakpoints.down('sm')]:{
      fontSize : 12,
      textAlign : 'left'
    }
  },
  text : {
    fontSize : 18,
    [theme.breakpoints.down('md')]:{
      fontSize : 14,
    }
  },
  textPlantName:{
    fontSize : 20,
    [theme.breakpoints.down('md')]:{
      fontSize : 14,
    },
      [theme.breakpoints.down('sm')]:{
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
  },
  textAllSites:{
    fontSize : 20,
    [theme.breakpoints.down('md')]:{
      fontSize : 16,
    },
    [theme.breakpoints.down('sm')]:{
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },
  paddingMd:{
    backgroundColor : '#fff',
    padding : '20px 32px',
    [theme.breakpoints.down('md')]:{
      padding : '20px 20px'
    },
    [theme.breakpoints.down('sm')]:{
      display : 'flex',
      flexDirection : 'column',
      alignItems:'flex-start',
      background:'#f8f8f8',
      padding : '8px 20px'
    }
  }
}));


export default function DataSideBarMenu(props){
  const classes = useStyles(props);
  const {index = 0 , mock , data = [] , sliders} = props;
  const ElementRef = (data.length > 0) && data.map(() => React.createRef());
  let totalPower = 0;
  data.map(item => totalPower =  (item.diagnostic && item.diagnostic.power) ? totalPower + parseFloat(item.diagnostic.power) : totalPower + 0)

  useEffect(() => {
    if (props.index > data.length - 1)
    props.exit();
    else if (ElementRef[props.index] && ElementRef[props.index].current)
      ElementRef[props.index].current.scrollIntoView({ block: 'end',  behavior: 'smooth' })
  }, [props.index]);

  return(
    <div className={classes.root}>
      <Helmet>
        <style type="text/css">
          {`
        .list{
        overflow : auto;
        height : 73vh;
         -ms-overflow-style: none;
        }
        @media screen and (min-width: 320px) and (max-width: 769px) and (orientation: portrait) {
        .list{
        overflow : auto;
        height : 73vw;
         -ms-overflow-style: none;
        }
        }
        .list::-webkit-scrollbar {
        display: none;
        }
      `}
        </style>
      </Helmet>

      <div className={classes.headerWrapper}>

        {(sliders.logo) && (
          <div style={{height : '100%' , width : '100%' , display : 'flex' , alignItems : 'center' , justifyContent : 'center'}}>
            <img alt='' src={sliders.logo} className={classes.img} />
          </div>
        )}

        <div className={classes.textWrapper} style={{borderBottom: `1px solid rgba(0,0,0,0.12)` }}>
          <Typography className={classes.tileText}>
            {translate('data.sites',{lgn : props.language})}
          </Typography>

          <Typography className={classes.tileTextSecond}>
            {translate('data.installed_power',{lgn : props.language})}
          </Typography>
        </div>
      </div>
      <div className="list">
        {((data.length > 0) && !data[0].is_group) && (
          <ListItem
            style={{borderBottom : '1px solid #e0e0e0'}}
            disableGutters
            className={classes.paddingMd}
            button
            id="element_$Total"
          >
            <Typography className={classes.textAllSites} style={{flex : 1 ,fontWeight : 700 }}>
              {translate('data.all_plants',{lgn : props.language})}
            </Typography>

            <Typography className={classes.text} style={{fontWeight : 700 }}>
              {Math.round(totalPower).toLocaleString(props.language, {maximumFractionDigits:2})}
            </Typography>
          </ListItem>
        )}
        {(data.length > 0) && data.map((item,idx) => {
          const power = (item.diagnostic && item.diagnostic.power) ? item.diagnostic.power : 0
            return (
            (idx === 0 && item.is_group === true) ? (
              <ListItem
                style={{ borderBottom: '1px solid #e0e0e0', backgroundColor : (idx === index) && 'rgba(0, 0, 0, 0.08)' }}
                disableGutters
                className={classes.paddingMd}
                button
                id="element_$Total"
              >
                <Typography className={classes.textAllSites} style={{ flex: 1, fontWeight: 700 }}>
                  {translate('data.all_plants',{lgn : props.language})}
                </Typography>

                <Typography className={classes.text} style={{ fontWeight: 700 }}>
                  {Math.round(parseFloat(power)).toLocaleString(props.language, {maximumFractionDigits:2})}
                </Typography>
              </ListItem>
            )
            : (
              <ListItem
                style={{borderBottom : '1px solid #e0e0e0' , backgroundColor : (idx === index) && 'rgba(0, 0, 0, 0.08)' }}
                className={classes.paddingMd}
                disableGutters
                button
                ref={ElementRef[idx]}
                id={`element_${idx}`}
              >
                <Typography className={classes.textPlantName} style={{flex : 1}}>
                  {item.plant_name}
                </Typography>
                <Typography className={classes.text}>
                  {Math.round(parseFloat(power)).toLocaleString(props.language, {maximumFractionDigits:2})}
                </Typography>
              </ListItem>
          )
            );
        })}
      </div>
    </div>
  )
}

