/** groups-redux.js */
import {
  createCrud,
  deleteCrud,
  editCrud,
  getCrud,
  selectedCrud,
  setCrudDialog,
  resetSelectedCrud,
  prepareCrud,
  resetPrepareCrud,
  crudActionsGenerator,
  addQueryFilter,
  resetDataCrud,
  crudReducerGenerator, prepareTable, resetPrepareTable,
} from '@cobrains/react-kit';
import { initialState } from '../initialState';

/** groups-redux.js */
const customPageActions = crudActionsGenerator("customPage");
export const customPageReducer = crudReducerGenerator("customPage", initialState.customPage);

/** Thunk */
export const getGroups = (params) => getCrud(customPageActions, `custom-view`, params);
export const prepareGroup = (id) => prepareCrud(customPageActions, `custom-view/${(id)?`${id}/`:''}prepare`);
export const resetPrepareGroup = () => resetPrepareCrud(customPageActions);
export const prepareGroupTable = (id) => prepareTable(customPageActions, `custom-view/${(id)?`${id}/`:''}prepare`);
export const resetPrepareGroupTable = () => resetPrepareTable(customPageActions);
export const createGroup = (data) => {

  const tmpData = data;

  delete tmpData.type;

  return createCrud(customPageActions, `custom-view`, tmpData);
};
export const editGroup = (data , id , clear = false) => {

  const tmpData = data;
  delete tmpData.type;

  return editCrud(customPageActions, `custom-view/${id}`, {tmpData, id} , clear);
};
export const uploadImage = (data , id , clear = false) => {return editCrud(customPageActions, `custom-view/${id}`, {data, id} , clear);}
export const deleteGroup = (id) => deleteCrud(customPageActions, `custom-view/${id}`, id);
export const groupSelected = (item) => selectedCrud(customPageActions, item);
export const groupResetSelected = () => resetSelectedCrud(customPageActions);
export const setDialog = (name, status) => setCrudDialog(customPageActions, {name, status});
export const addGroupQueryFilter = (filter) => addQueryFilter(customPageActions, filter);
export const resetGroupsData = () => resetDataCrud(customPageActions);
