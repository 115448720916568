import React , {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
      minWidth: '100%',
      padding: 8,
      marginBottom: 12,
      border: '1px solid rgba(0,0,0,0.32)',
      borderRadius: 40,
      display : 'flex' ,
      justifyContent : 'flex-start',
      alignItems : 'center'
  },
  minWidth:{
    minWidth : 600,
  },
  paddingContent:{
    padding : '20px 24px'
  },
  copy:{
    cursor : 'pointer',
    fontweight : 600,
    color : theme.palette.primary.main,
    paddingRight : 12
  }
}));

export default function ConfirmDialog(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {onCancel} = props;
  const {
    open = true,
    title='Dialog Title',
  } = props;



  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => onCancel()}
      classes={{paperWidthSm : classes.minWidth}}
      aria-labelledby="responsive-dialog-title"
    >
      <div style={{width : '100%', display : 'flex', alignItems : 'center'}}>
        <DialogTitle id="responsive-dialog-title">
          {title}

        </DialogTitle>
        <div style={{flex : 1 , paddingRight  : 12 , display : 'flex', alignItems : 'center' , justifyContent : 'flex-end'}}>
          <Icon onClick={() => onCancel()} style={{cursor : 'pointer'}}>
        close
          </Icon>
        </div>
      </div>

      <DialogContent classes={{root : classes.paddingContent}}>

        <div className={classes.root}>
          <Typography style={{paddingLeft : 12}}>
            {window.location.host}
            /
            {props.name}
            /plant
          </Typography>
          <div style={{flex : 1}} />
          <Typography
            className={classes.copy}
            onClick={() => {
              let tmpName = props.name
              tmpName.replace(' ', '%20');
            navigator.clipboard.writeText(`${window.location.host}/${tmpName}/plant`);
            if (props.onCopy)
              props.onCopy()
          }}
          >
            COPIA
          </Typography>
        </div>

      </DialogContent>
    </Dialog>
  );
}



