import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import leaf from '../../../../assets/icons/leaf'
import { translate } from '../../../../translations/i18n';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width:'100%',
    height : '100%',
    display : 'flex',
    flexDirection : 'column',
    justifyContent : 'flex-end',
    alignItems : 'center',
    [theme.breakpoints.down('md')]:{
      paddingBottom : '0px !important'
    },
  },
  wrapperIcon : {
    width: '53%',
    margin: '5% 0px',
    [theme.breakpoints.down('md')]:{
      margin : '20% 0px 15%',
      width: '60%',
    },
      [theme.breakpoints.down('sm')]:{
      margin : '10% 0px 5% 0px',
      width: '50%',
    }
  },

  unitText:{
    fontSize : 40 ,
    [theme.breakpoints.down('sm')]:{
      fontSize : '18px !important'
    },
    fontWeight : 'bold'
  },
  descText:{
    fontSize : 16,
    color : '#888888',
    textAlign : 'center'
  }

}));

export default function Co2Graph(props) {
  const classes = useStyles(props);
  const { color = 'rgb(78, 131, 60)' , value = 0} = props

  return(
    <div className={classes.wrapper} style={(window.innerWidth <= 1440) ? {paddingBottom : 20} : {}}>
      <div className={classes.wrapperIcon}>
        {leaf({color})}
      </div>

      <div style={{width : '100%' , alignItems : 'center' , display : 'flex' , flexDirection : 'column' , marginBottom : 24}}>
        <Typography className={classes.unitText} style={(window.innerWidth <= 1440) ? {fontSize : 28,} : {}}>
          {Math.round(value * 1000).toLocaleString(props.language, {maximumFractionDigits:2})}
          {' '}
          kg
        </Typography>

        <Typography className={classes.descText} style={(window.innerWidth <= 1440) ? {fontSize : 12,} : {}}>
          {translate('data.co2_emissions_avoided',{lgn : props.language})}
        </Typography>
      </div>
    </div>
  )
}
