import { combineReducers } from "redux";
import { api, auth, environment, messages, config } from "@cobrains/react-kit";
import { usersPageReducer } from './pages/users-page-redux';
import { plantsPageReducer } from './pages/plants-page-redux';
import { groupsPageReducer,tokensPageReducer } from './pages/groups-page-redux';
import { sliderPageReducer } from './pages/slider-page-redux';
import { customPageReducer } from './pages/custom-dash-page-redux';
import { visualPageReducer } from './pages/custom-slider-page-redux';


export default combineReducers({
    auth,
    api,
    environment,
    messages,
    config,
    usersPage: usersPageReducer,
    plantsPage: plantsPageReducer,
    groupsPage: groupsPageReducer,
    tokensPage: tokensPageReducer,
    sliderPage: sliderPageReducer,
    customPage: customPageReducer,
  visualPage: visualPageReducer,
})
