
const crudFields = [
  {
    key: 'id',
    visible:false
  },
  {
    key: 'name',
    label: 'Nome',
  },
  {
    key: 'description',
    label: 'Descrizione',
  },
  {
    key : 'auth_token',
    label: 'Token Api',
    style : {width: '205%'}
  }
];

export default crudFields
