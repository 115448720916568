import { withStyles } from '@material-ui/core/styles'
import { compose } from 'redux'
import {
  FormGenerator,
  apiCall, addMessage,
  getBreakpoints
} from '@cobrains/react-kit';
import {connect} from 'react-redux'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from "@material-ui/core/CircularProgress";
import CrudFields from './settings/crud/CrudFields'
import {
  getGroups,
  editGroup,
  createToken,
  createGroup,
  deleteGroup,
  groupSelected,
  setDialog,
  groupResetSelected,
  prepareGroup,
  setTokenDialog,
  resetPrepareGroup,
  resetPrepareToken,
  addGroupQueryFilter,
  resetGroupsData, prepareToken,
} from '../../../redux/pages/groups-page-redux';
import SideBarMenu from '../../../components/ui/sidebar-menu/SideBarMenu';
import ColorPicker  from '../../../components/ui/colorpicker/ColorPicker';
import FileCard from '../../../components/cards/FileCard';
import ShareDialog from './components/ShareDialog'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth : '100%' ,
    overflow : 'auto'
  },
  outlinedRoot:{
    borderRadius: '40px !important',
    padding : '8px !important',
    minWidth: 60
  },
  textField: {
    padding : '0px 32px 0px 16px',
    [`& fieldset`]: {
      borderRadius: 40,
      minWidth: '100%'
    },
    [`& input`]: {
      padding : '8px !important',
      minWidth: '100%'
    },
    [`& select`]: {
      padding: 4
    },
    [`& textarea`]:{
      padding: '2px 12px'
    }
  },
  card: {
    justifyContent: 'space-between',
    flex: '1 1 auto',
    maxWidth: 345,
    display: 'flex',
    padding: 8
  },
  media: {
    width: '100%',
    background : '#f0f0f0'
    // paddingTop: '56.25%', // 16:9
  },
  container : {
    display : 'flex',
    flexDirection : 'column',
    flex: 1,
    maxWidth : '100%'
  },
  column: {
    display:'flex',
    width:'100%',
    height:'100%',
    padding : '10px 20px',
    overflow : 'auto',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]:
      {    flexDirection: 'row',
      flex: 1,
      flexWrap: 'wrap'
      }
  },
  row: {
    display:'flex',
    width:'100%',
    padding: 20,
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  action: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  titleContainer:{
    padding : '34px 10px',
    height : 63,
    background : '#fff',
    position : 'sticky',
    top : 0,
    borderBottom: '1px solid #dcdcdc',
    display : 'flex',
    alignItems : 'center',
    justifyContent : 'flex-start'
  },
  apiEdit:{
    textAlign: 'left',
    cursor : 'pointer',
    textDecoration: 'underline',
    textStyle:'italic',
    font: '15px/26px Nunito sans-serif',
    letterSpacing: 0,
    color: '#2B2B2B',
    opacity: 0.5,
  },
  section : {
    display : 'flex',
    flexDirection : 'column',
    justifyContent : 'flex-start',
    alignItems : 'flex-start',
  },
  sectionTitle : {
    padding : '20px 0px 10px 0px',
    fontSize : 16 ,
    fontWeight : 600,
  },
  sectionContainer : {
    display : 'flex',
    flexWrap : 'wrap',
    width : '100%'
  },
  multiline:{
    padding: '10px 8px !important'
},
  secondarySection:{
    display : 'flex',
    justifyContent:'space-between',
    flex : 1,
    [theme.breakpoints.down('sm')]:{
      flexWrap : 'wrap'
    }
  },
  plantSection:{
    display : 'flex' ,
    flexDirection : 'column' ,
    flex : 1 ,
    paddingLeft : 40 ,
    borderLeft : '1px solid rgba(0,0,0,0.12)',
    [theme.breakpoints.down('sm')]:{
      paddingLeft : 0,
      borderLeft : 'unset',
    }
  }
}));

function DashboardCrud (props){
  const classes = useStyles(props);
  const [index, setIndex] = React.useState(null);
  const [name, setName] = React.useState('');
  const [access, setAccess] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const {groups} = props;



  useEffect(() => {
    props.onResetDataGroups();
    props.onResetSelected();
    props.onGetGroups();
  }, []);

  useEffect(() => {
   setAccess(props.groups.selected.private)
  } , [props.groups.selected.private])

  const handleEdit = (e) => {
    groups.selected[e.target.name] = e.target.value
    setIndex(props.groups.selected.id);
    props.onEditGroup({[e.target.name]: e.target.value} , props.groups.selected.id, false)
  };

  const handleEditColor = (color) => {
    groups.selected.color = color.hex;
    setIndex(props.groups.selected.id)
    props.onEditGroup({color: color.hex} , props.groups.selected.id , false)
  };

  function setAccessCall(value){
    setAccess(value);
    props.onEditGroup({private : value} , props.groups.selected.id, false)
  }

  return (
    (!props.loaders.get) ? (
      <div className={classes.root} style={{maxHeight:`calc(100vh - 56px)`, overflow:'hidden', display:'flex'}}>
        <SideBarMenu
          data={props.groups.data.content.items}
          getSelected={(item) => props.onGroupSelected(item)}
        />

        <div className={classes.container}>
          <div className={classes.titleContainer}>
            <Typography style={{fontSize : 20 , fontWeight : 600 , flex : 1 , paddingLeft : 20}}>
              {(props.groups.selected) ? props.groups.selected.name : ''}
            </Typography>

            <Icon style={{margin : '0px 12px', cursor : 'pointer', color : (access === true) ? '#000' : 'rgba(0,0,0,0.3)'}} onClick={() => setAccessCall(true)}>lock</Icon>

            <Icon style={{margin : '0px 12px', cursor : 'pointer', color : (access === false) ? '#000' : 'rgba(0,0,0,0.3)'}} onClick={() => setAccessCall(false)}>public</Icon>

            <Button
              endIcon={<Icon>share</Icon>}
              variant='contained'
              color='primary'
              onClick={() => setOpen(true)}
              style={{borderRadius : 18 , marginLeft : 4}}
            >
            CONDIVIDI
            </Button>
          </div>

          <div className={classes.column}>
            <div className={classes.section}>
              <Typography className={classes.sectionTitle}>SlideShow settings</Typography>
              <div className={classes.sectionContainer}>
                <FileCard
                  title="Logo"
                  image={(groups.selected.logo)?(groups.selected.logo):(null)}
                  onDrop={(image) => {
                    const formData = new FormData();
                    formData.append('logo', image[0])
                    props.onEditGroup(formData, props.groups.selected.id, false)
                  }}
                  onDelete={() => {
                    props.onEditGroup({logo : null}, props.groups.selected.id, false)
                  }}
                />
                <FileCard
                  title="Immagine"
                  image={(groups.selected.image)?(groups.selected.image):(null)}
                  onDrop={(image) => {
                    const formData = new FormData();
                    formData.append('image', image[0])
                    props.onEditGroup(formData , props.groups.selected.id , false)
                  }}
                  onDelete={() => {
                    props.onEditGroup({image : null}, props.groups.selected.id, false)
                  }}
                />
                <FileCard
                  title="Background"
                  image={(groups.selected.background_image)?(groups.selected.background_image):(null)}
                  onDrop={(image) => {
                    const formData = new FormData();
                    formData.append('background_image', image[0])
                    props.onEditGroup(formData , props.groups.selected.id , false)
                  }}
                  onDelete={() => {
                    props.onEditGroup({background_image : null}, props.groups.selected.id, false)
                  }}
                />
                <FileCard
                  title="Video"
                  type='video'
                  maxMb={20}
                  image={(groups.selected.video)?(groups.selected.video):(null)}
                  onDrop={(image) => {
                    const formData = new FormData();
                    formData.append('video', image[0])
                    props.onEditGroup(formData , props.groups.selected.id, false)
                }}
                  onDelete={() => {
                    props.onEditGroup({video : null}, props.groups.selected.id, false)
                  }}
                />
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems : 'center' ,
                  padding: '30px 0px' ,
                  flex: 1,
                  width: '48%',
                  maxWidth: '50%',
                  justifyContent: 'flex-start'}}
                >
                  <Typography style={{fontSize : 16}}>Frase</Typography>
                  <TextField
                    style={{flex : 1 , maxWidth : 410 , margin : '0px auto'}}
                    className={classes.textField}
                    variant="outlined"
                    name="slang"
                    multiline
                    rows="3"
                    InputProps={{
                      className :  classes.multiline
                    }}
                    inputProps={{
                      maxlength : 90,
                    }}
                    value={(groups.selected !== undefined)?(groups.selected.slang):(null)}
                    onChange={(e)=> handleEdit(e)}
                  />
                </div>
              </div>
            </div>

            <div className={classes.secondarySection}>
              <div style={{display : 'flex' , flexDirection : 'column',flex : 0.5}}>
                <Typography className={classes.sectionTitle}>Dashboard settings</Typography>
                <div style={{display: 'flex', flexDirection: 'column' , padding : '20px 0px' , alignItems : 'flex-start' ,  flex : 1, minHeight:155, justifyContent : 'space-between'}}>
                  <ColorPicker
                    color={(groups.selected !== undefined)?(groups.selected.color):(null)}
                    onChangeComplete={(color)=> handleEditColor(color)}
                  />
                  <div style={{display: 'flex', flexDirection: 'row', alignItems : 'center', padding: '12px 0px'}}>
                    <Typography style={{marginRight : 12, fontSize : 16}}>Lingua</Typography>
                    <FormControl className={classes.textField} style={{borderRadius: 40}}>
                      <Select
                        classes={{outlined : classes.outlinedRoot}}
                        variant="outlined"
                        name="language"
                        value={(groups.selected.language)?(groups.selected.language):(null)}
                        onChange={(e)=> handleEdit(e)}
                      >
                        <MenuItem value="" />
                        <MenuItem value="it">IT</MenuItem>
                        <MenuItem value="en">ENG</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>

              <div className={classes.plantSection}>
                <Typography className={classes.sectionTitle}>Impianti</Typography>
                <div style={{display: 'flex', flexDirection: 'row' , padding : '20px 0px' , alignItems : 'flex-start' , justifyContent : 'space-between' ,  flex : 1 , flexWrap : 'wrap'}}>
                  {(props.groups.selected.plant_group !== undefined && props.groups.selected.plant_group.length > 0) &&
            Array.from(props.groups.selected.plant_group).map((item) => (
              <div style={{display: 'flex', flexDirection: 'row', alignItems : 'center' , width : '50%' , padding : '12px 0px'}}>
                <Typography style={{marginRight : 12}}>{item.name}</Typography>
                <Typography
                  className={classes.apiEdit}
                  onClick={() => {
                          props.onSetTokenDialog('edit', true);
                          setIndex(item.id);
                          setName(item.name);
                          props.onPrepareToken(item.id)
                  }}
                >
                Manage api
                </Typography>
              </div>
          ))}
                </div>
              </div>
            </div>


            {(props.prepare.fields.length > 0) && (
            <FormGenerator
              buttonVariant='contained'
              fields={[props.prepare.fields, CrudFields]}
              isPending={false}
              onSubmit={(token) => {
              props.onCreateToken({id : index, ...token})
              props.onSetTokenDialog('edit', false)
              props.onResetPrepareToken();
            }}
              onCancel={() => {
              props.onSetTokenDialog('edit', false)
              props.onResetPrepareToken();
            }}
              errors={props.errors.edit}
              dialog
              dialogProps={{
              onClose: () => {
                props.onSetTokenDialog('edit', false);
                props.onResetPrepareToken();
              },
              open:props.dialogs.edit,
              title:'Gestione Token',
              subtitle: `${name} - Aggiungi o sostituisci i token delle API`
            }}
            />
        )}
            <ShareDialog
              open={open}
              onCancel={() => setOpen(false)}
              name={(props.groups.selected) ? props.groups.selected.name : ''}
              title="Link per la condivisione"
              onCopy={() => props.onAddMessage('Link copiato negli appunti ','success')}
            />
          </div>
        </div>
      </div>
  )
      : (
        <div style={{height : '90vh' , width : '100%',display : 'flex',justifyContent : 'center',alignItems:'center'}}>
          <CircularProgress size={40} color="#000" />
        </div>
    )
    )
}


const mapStateToProps = state => ({
  user: state.auth.user,
  groups: state.groupsPage,
  dialogs: state.tokensPage.dialogs,
  loaders: state.groupsPage.loaders,
  errors: state.groupsPage.errors,
  prepare: state.tokensPage.prepare,
  filters: state.groupsPage.filters
});

const mapActionsToProps = {
  onApiCall : apiCall,
  onGetGroups: getGroups,
  onGroupSelected: groupSelected,
  onSetDialog: setDialog,
  onSetTokenDialog: setTokenDialog,
  onCreateGroup: createGroup,
  onCreateToken: createToken,
  onEditGroup: editGroup,
  onDeleteGroup: deleteGroup,
  onResetSelected: groupResetSelected,
  onPrepareGroup: prepareGroup,
  onPrepareToken: prepareToken,
  onResetPrepareGroup: resetPrepareGroup,
  onResetPrepareToken: resetPrepareToken,
  /* Common */
  onAddMessage : addMessage,
  onAddQueryFilter: addGroupQueryFilter,
  onResetDataGroups: resetGroupsData
};

export default compose(
    withStyles({withTheme: true}),
  connect(mapStateToProps , mapActionsToProps)
)(DashboardCrud)
