import React, { useState, useEffect, useRef } from 'react';
import { Paper, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReactEcharts from 'echarts-for-react';
import moment from 'moment'
import option from '../charts/ChartOptions';
import DateText from './DateText';
import ProductionGraph from './ProductionGraph';
import CurrentProductionGraph from './CurrentPowerBox';
import Co2Graph from './Co2Graph';
import DailyProductionBox from './DailyProductionBox';
import daylightImage from '../../../../assets/img/GEM-daylight.jpg';
import sunsetImage from '../../../../assets/img/GEM-sunset.jpg';
import dawnImage from '../../../../assets/img/GEM-dawn.jpeg';
import mockLogo from '../../../../assets/img/logo_vailog_white.png';

const useStyles = makeStyles(theme => ({
  wrapper: {
    overflowY : 'hidden',
    padding: '40px',
    [theme.breakpoints.down('md')]: {
      padding: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    width: '100%',
    // height : '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  titleContainer: {
    height: 80,
    padding: '0px 20px 20px 20px',
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      height: 40,
      padding: '12px 0px 0px 0px',
    },
  },
  text: {
    fontFamily: 'Nunito sans',
    color : '#fff',
    fontSize: 28,
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  plantText: {
    fontFamily: 'Nunito sans',
    flex: 1,
    textAlign : 'center',
    fontSize: '2rem',
    marginRight : '6%',
    color : '#fff',
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      fontSize: 22,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.up(2560)]: {
      fontSize: '2.3rem !important',
    },
  },
  graphContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      // maxHeight: '100%',
      minHeight : 200,
      flex: 1,
    },
    [theme.breakpoints.down('lg')] : {
      maxHeight: '90%',
    },
    [theme.breakpoints.up('lg')] : {
      flex : 1
    }
  },
  bigGraph: {
    position : 'relative',
    // margin: 20,
    backgroundColor : 'rgba(0,0,0,0)',
    flex: 1,
    height: '50%',
    minHeight : 315,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 16,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      margin: 10,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      minHeight : 160,
      borderRadius: 0,
      boxShadow: 'unset',
      height: '30%',
      marginBottom : 12
    },
  },
  smallGraphContainer: {
    display: 'flex',
    flexWrap : 'wrap',
    height: '44%',
    alignItems : 'flex-start'
  },
  smallGraph: {
    borderRadius : 0,
    flexBasis : '33%',
    maxHeight : '40%',
    minHeight : '40%',
    boxShadow: '0px 0px 15px #00000029',
    flex: 1,
    backgroundColor : 'rgba(255,255,255,0.7)',
    margin: 20,
    [theme.breakpoints.down('md')]: {
      margin: 10,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 4,
      flexBasis : '33%',
      borderRadius: 0,
      maxHeight : '33%',
      minHeight : '33%',
      background: 'rgba(255,255,255,0.85)',
      boxShadow: 'unset',
      border: '1px solid #ecedec',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerContainer: {
    padding: '24px 40px 0px 40px',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      padding: '0px !important',
    },
  },
  unitText: {
    fontFamily: 'Nunito sans',
    color : '#fff',
    fontSize: 40,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px !important',
    },
    [theme.breakpoints.up(2560)]: {
      fontSize: '3.3rem !important',
    },
    fontWeight: 'bold',
  },
  descunitText:{
    fontFamily: 'Nunito sans',
    textAlign:'left',
    fontSize : 19,
    fontWeight : 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    },
    [theme.breakpoints.up(2560)]: {
      fontSize: '1.8rem !important',
    },
  },
  descText: {
    fontFamily: 'Nunito sans',
    fontSize: 12,
    color: '#fff',
  },
  logoContainer : {
    width : 300 ,
    height : '115%',
    marginRight : 50,
    marginLeft: 22,
    backgroundSize : 'contain',
    backgroundPosition : 'center',
    backgroundRepeat : 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      width : 100 ,
      height : '115%',
    },
  }
}));

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => ++value); // update the state to force render
}

export default function
  DataDashboard(props) {
  const classes = useStyles(props);
  const { mock, data = [], sliders, index = 0 } = props;
  const [image, setImage] = useState(dawnImage);
  const [height, setHeight] = useState(0);
  const [chartCompletion, setChartCompletion] = useState(0);
  const eChartsRef = useRef();
  const chartRef = useRef();


  useEffect(() => {

    if (chartRef && chartRef.current)
      setHeight(chartRef.current.clientHeight);


    /* setInterval( () => {
      const currentH = Number(moment(new Date()).format('HH'));
      if (currentH >= 17){
        setImage(sliders.image_2 ? sliders.image_2 : daylightImage)
      }
      else (
        setImage(sliders.image_1 ? sliders.image_1 : sunsetImage)
      )
    },1000) */
  }, []);

  let totalProd = 0;

  if (data.chart)
    Object.keys(data.chart).map(
      item => (totalProd = data.chart[item] ? totalProd + data.chart[item] : totalProd),
    );


  useEffect(() => {
    if (eChartsRef && eChartsRef.current && data.chart !== null) {

      if (chartRef && chartRef.current)
      setHeight(chartRef.current.clientHeight);

      const instance = eChartsRef.current.getEchartsInstance()
      instance.setOption({
        ...option({
          color: sliders.color,
          dataGraph: data.chart,
          is_group: data.is_group,
          index,
          lastValue: Object.entries(data.chart)
            .filter(([item, value]) => value !== 0)
            .slice(-1).length > 0 ? Object.entries(data.chart)
            .filter(([item, value]) => value !== 0)
            .slice(-1)[0][0] : 0
        })
      })

    }

  }, [data]);



  function getChartCompletion(){

    if (data.chart) {

      let lastData = Object.entries(data.chart)
        .filter(([item, value]) => value !== 0)
        .slice(-1);

      lastData = (lastData.length > 0) ? lastData[0][0] : '03:00' ;


      lastData = Number(lastData ? lastData.split(':')[0] : 0)

      const completion = lastData / 22 * 100

      return completion.toFixed(0)
    }
    return 0
  }


  const {
    diagnostic = { max_power: 0, power_sum: 0, co2: 0, todayPaclast: 0, total_daily_production: 0 },
  } = data;
  const chartNumber =
    data &&
    data.chart &&
    Object.entries(data.chart)
      .filter(([item, value]) => value !== 0)
      .slice(-1)[0]
      ? Object.entries(data.chart)
          .filter(([item, value]) => value !== 0)
          .slice(-1)[0][1]
      : 0;

  const forceUpdate = useForceUpdate();


  useEffect(() => {
    forceUpdate();
  }, [props.index]);

  return (
    <div
      className={classes.wrapper}
      style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover' }}
    >
      <div className={classes.titleContainer}>

        <div className={classes.logoContainer} style={{backgroundImage : `url(${sliders.logo ? sliders.logo : mockLogo})`}} />


        <Typography className={classes.plantText}>
  Total installed power
          {' '}
          {diagnostic && diagnostic.power ? Math.round(diagnostic.power).toLocaleString('en', {
          maximumFractionDigits: 2,
        }) : '0'}
          {' '}
kWp
        </Typography>

        <div style={{marginRight : 32}}>
          <DateText lng="en" color="#fff" />
        </div>

      </div>
      <div className={classes.graphContainer} style={(window.innerWidth <= 1440) ? {maxHeight : '100% !important', height : '100% !important'} :{}}>
        <Paper
          className={classes.bigGraph}
          ref={chartRef}
        >
          <div
            style={{ padding: '24px 40px 0px 40px', display: 'flex' }}
            className={classes.headerContainer}
          >
            {
              (getChartCompletion() !== 0) && (
              <Typography
                className={classes.unitText}
                style={
                (window.innerWidth > 812) ?
                (getChartCompletion() < 86) ?
                  {position : 'absolute', bottom : '34%', left : `${getChartCompletion()}%`}
                  :
                  {position : 'absolute', bottom : '68%', left : '86%'}
                  :
                  (getChartCompletion() < 80) ?
                    {position : 'absolute', bottom : '34%', left : `${getChartCompletion()}%`}
                    :
                    {position : 'absolute', bottom : '67%', left : '80%'}
              }
              >
                {totalProd
                ? Math.round(diagnostic.todayPaclast).toLocaleString('en', {
                    maximumFractionDigits: 2,
                  })
                : 0}
                {' '}
kW
                <Typography className={classes.descunitText}>
                CURRENT POWER
                </Typography>
              </Typography>
            )
}
          </div>

          <ReactEcharts
            lazyUpdate={false}
            option={option({
                color: sliders.color,
                dataGraph: data.chart ? data.chart : {"00:00": 0,},
                is_group: data.is_group,
                index,
                lastValue: chartCompletion
              })}
            ref={eChartsRef}
            style={{
                height: '100%',
                width: '100%',
              }}
          />

        </Paper>

        <div className={classes.smallGraphContainer}>

          <Paper className={classes.smallGraph}>
            <Co2Graph
              color={'#478861'}
              value={diagnostic && diagnostic.co2 ? diagnostic.co2 : 0}
              language="en"
            />
          </Paper>

          <Paper className={classes.smallGraph}>
            <ProductionGraph
              color={'#478861'}
              plantName={data?.plant_name}
              value={
                diagnostic && diagnostic.power_sum
                  ? Math.round(diagnostic.power_sum).toLocaleString('en', {
                      maximumFractionDigits: 2,
                    })
                  : 0
              }
              language="en"
            />
          </Paper>


          <Paper className={classes.smallGraph}>
            <DailyProductionBox
              value={chartNumber !== 0 ? Math.round(diagnostic.total_daily_production) : 0}
              color={'#478861'}
              language="en"
              valueText={
                diagnostic && diagnostic.total_daily_production
                  ? Math.round(diagnostic.total_daily_production).toLocaleString('en', {
                    maximumFractionDigits: 2,
                  })
                  : 0
              }
            />
          </Paper>

          <Paper className={classes.smallGraph}>
            <CurrentProductionGraph
              color={'#478861'}
              value={
                diagnostic && diagnostic.todayPaclast
                  ? Math.round(diagnostic.todayPaclast).toLocaleString('en', {
                    maximumFractionDigits: 2,
                  })
                  : 0
              }
              language='en'
            />
          </Paper>
        </div>
      </div>
    </div>
  );
}
