import Error404 from "../../views/errors/Error404";

export const ErrorsRoutes = [
    {
        path: '/404',
        name: '404',
        component: Error404,
        exact: true,
        private:false
    },
];
