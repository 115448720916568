import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge/Badge';
import Icon from '@material-ui/core/Icon';
import React, { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const useStyles = makeStyles(theme => ({
  root: {
    width:300,
    background:'#fff',
    height:'95vh',
    borderRight: '1px solid #e0e0e0',
    boxShadow: '-3px 0px 5px #f2f2f2',
    overflow:'auto'
  },

  listItem:{
    padding: '22px 20px 24px 30px',
    borderBottom : '1px solid #e1e1e1',
    fontFamily: 'Nunito, sans-serif',
    fontSize : 16
  }
}));

export default function SideBarMenu(props){
  const classes = useStyles(props);
  const {} = props;
  const [selectedIndex, setSelectedIndex] = React.useState(0);


  useEffect(() => {
    if (props.data.length > 0) {
      props.getSelected(props.data[0])
      setSelectedIndex(props.data[0].id)
    }
  }, [props.data]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index.id);
    props.getSelected(index)
  };

  return(
    <div className={classes.root}>
      <List button>


        {props.openDialog && (
          <ListItem
            button
            disableGutters
            className={classes.listItem}
            onClick={() => props.openDialog()}
          >
            Aggiungi Visual
            <i className="icon-aggiungi" style={{ marginLeft : 12 , fontSize : 16, color : '#000'  }} />
          </ListItem>
        )}



        {(props.data.length > 0)?
             (Array.from(props.data).map((item, index) => (
               <ListItem
                 button
                 disableGutters
                 className={classes.listItem}
                 selected={selectedIndex === item.id}
                 onClick={event => handleListItemClick(event, item)}
               >
                 {item.name}
               </ListItem>
             )
             )): null}
      </List>
    </div>
  )
}
