import { withStyles } from '@material-ui/core/styles'
import { compose } from 'redux'
import {
  FormGenerator,
  apiCall, addMessage,
  getBreakpoints, ConfirmDialog,
} from '@cobrains/react-kit';
import {connect} from 'react-redux'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from '@material-ui/core/IconButton';
import CrudFields from './settings/crud/CrudFields'
import CrudFieldsEdit from './settings/crud/CrudFieldsEdit'
import {
  getGroups,
  editGroup,
  uploadImage,
  createGroup,
  deleteGroup,
  groupSelected,
  setDialog,
  groupResetSelected,
  prepareGroup,
  resetPrepareGroup,
  addGroupQueryFilter,
  resetGroupsData,
} from '../../../redux/pages/custom-dash-page-redux';
import SideBarMenu from '../../../components/ui/sidebar-menu/SideBarMenu';
import ColorPicker  from '../../../components/ui/colorpicker/ColorPicker';
import FileCard from '../../../components/cards/FileCard';
import ShareDialog from './components/ShareDialog'
import { translate } from '../../../translations/i18n';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth : '100%' ,
    overflow : 'auto'
  },
  outlinedRoot:{
    borderRadius: '40px !important',
    padding : '8px !important',
    minWidth: 60
  },
  textField: {
    padding : '0px 32px 0px 16px',
    [`& fieldset`]: {
      borderRadius: 40,
      minWidth: '100%'
    },
    [`& input`]: {
      padding : '8px !important',
      minWidth: '100%'
    },
    [`& select`]: {
      padding: 4
    },
    [`& textarea`]:{
      padding: '2px 12px'
    }
  },
  card: {
    justifyContent: 'space-between',
    flex: '1 1 auto',
    maxWidth: 345,
    display: 'flex',
    padding: 8
  },
  media: {
    width: '100%',
    background : '#f0f0f0'
    // paddingTop: '56.25%', // 16:9
  },
  container : {
    display : 'flex',
    flexDirection : 'column',
    flex: 1,
    maxWidth : '100%'
  },
  column: {
    display:'flex',
    width:'100%',
    height:'100%',
    padding : '10px 20px',
    overflow : 'auto',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]:
      {    flexDirection: 'row',
      flex: 1,
      flexWrap: 'wrap'
      }
  },
  row: {
    display:'flex',
    width:'100%',
    padding: 20,
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  action: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  titleContainer:{
    padding : '34px 10px',
    height : 63,
    background : '#fff',
    position : 'sticky',
    top : 0,
    borderBottom: '1px solid #dcdcdc',
    display : 'flex',
    alignItems : 'center',
    justifyContent : 'flex-start'
  },
  apiEdit:{
    textAlign: 'left',
    cursor : 'pointer',
    textDecoration: 'underline',
    textStyle:'italic',
    font: '15px/26px Nunito sans-serif',
    letterSpacing: 0,
    color: '#2B2B2B',
    opacity: 0.5,
  },
  section : {
    display : 'flex',
    flexDirection : 'column',
    justifyContent : 'flex-start',
    alignItems : 'flex-start',
  },
  sectionTitle : {
    padding : '20px 0px 10px 0px',
    fontSize : 16 ,
    fontWeight : 600,
  },
  sectionContainer : {
    display : 'flex',
    flexWrap : 'wrap',
    width : '100%'
  },
  multiline:{
    padding: '10px 8px !important'
},
  secondarySection:{
    display : 'flex',
    justifyContent:'space-between',
    flex : 1,
    [theme.breakpoints.down('sm')]:{
      flexWrap : 'wrap'
    }
  },
  plantSection:{
    display : 'flex' ,
    flexDirection : 'column' ,
    flex : 1 ,
    paddingLeft : 40 ,
    borderLeft : '1px solid rgba(0,0,0,0.12)',
    [theme.breakpoints.down('sm')]:{
      paddingLeft : 0,
      borderLeft : 'unset',
    }
  }
}));

function CustomDashboardCrud (props){
  const classes = useStyles(props);
  const [index, setIndex] = React.useState(null);
  const [name, setName] = React.useState('');
  const [access, setAccess] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const {groups} = props;

  useEffect(() => {
    props.onResetDataGroups();
    props.onResetSelected();
    props.onGetGroups();
  }, []);

  useEffect(() => {
   setAccess(props.groups.selected.private)
  } , [props.groups.selected.private])

  function setAccessCall(value){
    setAccess(value);
    props.onEditGroup({private : value} , props.groups.selected.id, false)
  }

  return (
    (!props.loaders.get && props.groups.selected) ? (
      <div className={classes.root} style={{maxHeight:`calc(100vh - 56px)`, overflow:'hidden', display:'flex'}}>
        <SideBarMenu
          data={props.groups.data.content.items}
          getSelected={(item) => props.onGroupSelected({ ...item })}
          openDialog={() => {
            props.onSetDialog('add', true);
            props.onPrepareGroup()
          }}
          openDialogEdit={(id) => {
            props.onSetDialog('edit', true);
            props.onPrepareGroup(id)
          }}
        />

        <div className={classes.container}>
          <div className={classes.titleContainer}>
            <Typography style={{fontSize : 20 , fontWeight : 600 , flex : 1 , paddingLeft : 20}}>
              {(props.groups.selected) ? props.groups.selected.name : ''}
              <Button onClick={() => {
                props.onSetDialog('edit', true);
                props.onPrepareGroup(props.groups.selected.id)
              }}
              >
                <i className="icon-modifica" style={{ fontSize : 16,color : '#000'  }} />
              </Button>
            </Typography>




            <Icon  style={{margin : '0px 12px', cursor : 'pointer', color : '#000'}} onClick={() => props.onSetDialog('delete', true)}>delete</Icon>

            <Button
              endIcon={<Icon>share</Icon>}
              variant='contained'
              color='primary'
              onClick={() => setOpen(true)}
              style={{borderRadius : 18 , marginLeft : 4}}
            >
            CONDIVIDI
            </Button>
          </div>

          <div className={classes.column}>
            <div className={classes.section}>
              <Typography className={classes.sectionTitle}>Dashboard settings</Typography>
              <div className={classes.sectionContainer}>
                <FileCard
                  title="Logo"
                  image={(groups.selected.logo)?(groups.selected.logo):(null)}
                  onDrop={(image) => {
                    const formData = new FormData();
                    formData.append('logo', image[0])
                    props.onUploadImage(formData, props.groups.selected.id, false)
                  }}
                  onDelete={() => {
                    props.onUploadImage({logo : null}, props.groups.selected.id, false)
                  }}
                />

                <FileCard
                  title="Immagine 1"
                  image={(groups.selected.image_1)?(groups.selected.image_1):(null)}
                  onDrop={(image) => {
                    const formData = new FormData();
                    formData.append('image_1', image[0])

                    for (var key of formData.entries()) {
                      console.log(key[0] + ', ' + key[1]);
                    }

                    props.onUploadImage(formData , props.groups.selected.id , false)
                  }}
                  onDelete={() => {
                    props.onUploadImage({image : null}, props.groups.selected.id, false)
                  }}
                />
                <FileCard
                  title="Immagine 2"
                  image={(groups.selected.image_2)?(groups.selected.image_2):(null)}
                  onDrop={(image) => {
                    const formData = new FormData();
                    formData.append('image_2', image[0])
                    props.onUploadImage(formData , props.groups.selected.id , false)
                  }}
                  onDelete={() => {
                    props.onUploadImage({image : null}, props.groups.selected.id, false)
                  }}
                />
                <FileCard
                  title="Immagine 3"
                  image={(groups.selected.image_3)?(groups.selected.image_3):(null)}
                  onDrop={(image) => {
                    const formData = new FormData();
                    formData.append('image_3', image[0])
                    props.onUploadImage(formData , props.groups.selected.id, false)
                }}
                  onDelete={() => {
                    props.onUploadImage({video : null}, props.groups.selected.id, false)
                  }}
                />
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems : 'center' ,
                  padding: '30px 0px' ,
                  flex: 1,
                  width: '48%',
                  maxWidth: '50%',
                  justifyContent: 'flex-start'}}
                />
              </div>
            </div>

            <div className={classes.secondarySection}>
              {/* <div style={{display : 'flex' , flexDirection : 'column',flex : 0.5}}>
                <Typography className={classes.sectionTitle}>Dashboard settings</Typography>
                <div style={{display: 'flex', flexDirection: 'column' , padding : '20px 0px' , alignItems : 'flex-start' ,  flex : 1, minHeight:155, justifyContent : 'space-between'}}>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems : 'center', padding: '12px 0px'}}>
                    <Typography style={{marginRight : 12, fontSize : 16}}>Lingua</Typography>
                    <FormControl className={classes.textField} style={{borderRadius: 40}}>
                      <Select
                        classes={{outlined : classes.outlinedRoot}}
                        variant="outlined"
                        name="language"
                        value={(groups.selected.language)?(groups.selected.language):(null)}
                        onChange={(e)=> handleEdit(e)}
                      >
                        <MenuItem value="" />
                        <MenuItem value="it">IT</MenuItem>
                        <MenuItem value="en">ENG</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div> */}
            </div>


            {(props.prepare.fields.length > 0) && (
              <FormGenerator
              options={{
                  options:{
                  columns: 2,
                  inputSize:'small'
                },
                }}
                buttonVariant='contained'
                fields={[props.prepare.fields, CrudFields]}
                data={{}}
                isPending={false}
                layoutStyle={{    minWidth:'calc(38% - 12px)'}}
                onSubmit={(group) => {
                  props.onCreateGroup(group);
                }}
                onCancel={() => {
                  props.onSetDialog('add', false);
                  props.onResetPrepareGroup();
                }}
                translate={translate}
                errors={props.errors.create.data.messages}
                dialog
                dialogProps={{
                  onClose: () => {
                    props.onSetDialog('add', false);
                    props.onResetPrepareGroup();
                  },
                  open:props.dialogs.add,
                  title:'Aggiungi una nuova Visual',
                  subtitle:'Compila i campi per aggiungere una nuova Visual'
                }}
                actionsProps={{
                  cancelProps : {confirm: true}
                }}
              />
            )}


            {(props.prepare.fields.length > 0) && (
              <FormGenerator
                options={{
                  options:{
                    columns: 2,
                    inputSize:'small'
                  },
                }}
                buttonVariant='contained'
                fields={[[...props.prepare.fields,
                  {
                    component: "select",
                    key: "type",
                    multiple: false,
                    required: true,
                    type: "enum",
                    value: (props.groups.selected.plant && props.groups.selected.plant.value !== null) ?  1 : 2,
                    values : [{label : 'Impianto', value : 1},{label : 'Gruppo', value : 2}]
                  }], CrudFieldsEdit]}
                data={props.groups.selected}
                isPending={false}
                layoutStyle={{    minWidth:'calc(38% - 12px)'}}
                onSubmit={(group) => {
                  props.onEditGroup(group , props.groups.selected.id);
                  props.onGroupSelected({ ...group });
                }}
                onCancel={() => {
                  props.onSetDialog('edit', false);
                  props.onResetPrepareGroup();
                }}
                errors={props.errors.edit.data.messages}
                dialog
                dialogProps={{
                  onClose: () => {
                    props.onSetDialog('edit', false);
                    props.onResetPrepareGroup();
                  },
                  open:props.dialogs.edit,
                  title:'Modifica la Visual',
                  subtitle: `Compila i campi per modificare la Visual`
                }}
                actionsProps={{
                  cancelProps : {confirm: true}
                }}
              />
            )}


            {(props.dialogs.delete) && (
              <ConfirmDialog
                open={props.dialogs.delete}
                title="Eliminazione Visual"
                message={"Sei sicuro di voler eliminare la Visual?"}
                onCancel={() => props.onSetDialog('delete', false)}
                onSubmit={() => {
                  props.onDeleteGroup(props.groups.selected.id)
                }}
                cancelProps={{ label: 'Annulla'}}
                submitProps={{ label: 'Elimina'}}
              />
            )}

            <ShareDialog
              open={open}
              onCancel={() => setOpen(false)}
              name={(props.groups.selected) ? props.groups.selected.name : ''}
              title="Link per la condivisione"
              onCopy={() => props.onAddMessage('Link copiato negli appunti ','success')}
            />
          </div>
        </div>
      </div>
  )
      : (
        <div style={{height : '90vh' , width : '100%',display : 'flex',justifyContent : 'center',alignItems:'center'}}>
          <CircularProgress size={40} color="#000" />
        </div>
    )
    )
}


const mapStateToProps = state => ({
  user: state.auth.user,
  groups: state.customPage,
  dialogs: state.customPage.dialogs,
  loaders: state.customPage.loaders,
  errors: state.customPage.errors,
  prepare: state.customPage.prepare,
  filters: state.customPage.filters
});

const mapActionsToProps = {
  onApiCall : apiCall,
  onGetGroups: getGroups,
  onGroupSelected: groupSelected,
  onSetDialog: setDialog,
  onCreateGroup: createGroup,
  onEditGroup: editGroup,
  onUploadImage: uploadImage,
  onDeleteGroup: deleteGroup,
  onResetSelected: groupResetSelected,
  onPrepareGroup: prepareGroup,
  onResetPrepareGroup: resetPrepareGroup,
  /* Common */
  onAddMessage : addMessage,
  onAddQueryFilter: addGroupQueryFilter,
  onResetDataGroups: resetGroupsData
};

export default compose(
    withStyles({withTheme: true}),
  connect(mapStateToProps , mapActionsToProps)
)(CustomDashboardCrud)
