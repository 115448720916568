import React, { useEffect, useState } from 'react';
import { addMessage, apiCall } from '@cobrains/react-kit';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Lottie from 'react-lottie';
import DataSideBarMenu  from './components/DataSideBarMenu';
import DataDashboard from './components/DataDashboard';
import { getSlider } from '../../../redux/pages/slider-page-redux';
import rotate from '../../../assets/icons/rotate';
import sliderload from '../../../assets/loaders/loader.json';
import SignInFormSlider from '../../auth/components/SignInFormSlider';
import i18n from 'i18next';
import { changeLocale } from '../../../translations/i18n';


const useStyles = makeStyles(theme => ({
  wrapper: {
    display : 'flex',
    alignItems : 'center',
    height : '100vh'
  },
  dataContainer : {
    flex  : 1 ,
    display : 'flex' ,
    justifyContent : 'center',
    backgroundColor : '#ededed',
    height : '100%',
  }
}));

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: sliderload,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

let timeId;


function DataSlide(props) {
  const classes = useStyles(props);
  const language = 'en'
  const [index , setIndex] = useState(0);
  const [orientation , setOrentation] = useState('null');
  const [login , setLogin] = useState(false);
  const [loader , setLoader] = useState(true);

  function setScreenOrientation() {

    if (window.matchMedia("(orientation: landscape)").matches) {
      setOrentation('landscape')
    }
    if (window.matchMedia("(orientation: portrait)").matches) {
      setOrentation('portrait')
    }
  }


  useEffect(() => {
    if (props.slide && props.slide.language) {
      i18n.init({ preload: [`${props.slider.data.content.sliders.language}`] });
      i18n.loadLanguages([`${props.slider.data.content.sliders.language}`]);
      changeLocale(`${props.slider.data.content.sliders.language}`);
    }
  }, [props.slider.data.content.sliders]);

  function Refresh(){
    props.onGetSlider(props.match.params.id)
  }

  function setDashboardRefreshInterval() {
    timeId = setInterval(() => Refresh(), 600000
    );
  }

  useEffect(() => {
    if (window.matchMedia("(orientation: landscape)").matches) {
      setOrentation('landscape')
    }
    if (window.matchMedia("(orientation: portrait)").matches) {
      setOrentation('portrait')
    }

    window.addEventListener('resize', setScreenOrientation);


    props.onApiCall({method:'GET',url:`group/update-data/${props.match.params.id}/auth`}).then(
      res => {
        setDashboardRefreshInterval()
        props.onGetSlider(props.match.params.id)
        setLoader(false)
      },
      err =>{
        if(err.response && err.response.data && err.response.data.messages && err.response.data.messages[0].message === "unauthorized") {
          setLoader(false);
          setLogin(true);
        }
        else if (err.response && err.response.data && err.response.data.messages && err.response.data.messages[0].message === "group not found") {
          setLoader(true);
          setTimeout(() => {
            window.location.href = `/cerca`
          },3000);
        }
        else{
          setLoader(true);
          setTimeout(() => {
            window.location.href = `/cerca`
          },2000);
        }

      })
  }, []);

  function getError() {
    if (props.errors.get.data.messages && props.errors.get.data.messages.length > 0 && props.errors.get.data.messages[0].message === 'unauthorized' || login === true){
      return(
        <SignInFormSlider onLogin={() => {
          props.onGetSlider(props.match.params.id)
        }}
        />
      )}
    if ( (props.errors.get.data.messages && props.errors.get.data.messages.length > 0) || (props.errors.get.data && typeof props.errors.get.data === 'string')){
      setLoader(true);
      setTimeout(() => {
        window.location.href = `/cerca`
      },3000);
      props.onAddMessage('Erorre nella chiamata controllare la conessione internet', 'warning');
      return(
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',height : '100%'}}>
          <div style={{width:'50%'}}>
            <Lottie
              options={defaultOptions}
              isStopped={false}
              isPaused={false}
            />
          </div>
        </div>
      )
    }
    return null
  }


  return(
    (!props.loaders.get && !loader)  ?
      (props.slide === undefined) ?
        getError()
        :
        (
          <>
            {(orientation === 'portrait') && (
            <div
              className={classes.overlayNoTouch}
              style={{backgroundColor : '#fff',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height : '100vh',
              }}
            >
              <div style={{width : '30%'}}>
                {rotate()}
              </div>
              <Typography style={{
                    fontSize: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    wordBreak: 'break-word',
                    textAlign: 'center',
                    margin: '10%',
                    zIndex : 10000,
                    color: '#bcbcbc',
                    fontStyle: 'italic',
                    visibility: (orientation === 'portrait') ? 'visible' : 'hidden'
                    // fontWeight: '700
                    // '
                  }}
              >
                    Ruota il dispositivo per visualizzare la dashboard
              </Typography>
            </div>
              )}
            <>
              <div className={`wrapper ${classes.wrapper}`}>
                <DataSideBarMenu
                  mock={props.mock}
                  data={props.slider.data.content.items}
                  index={index}
                  setIndex={(index) => setIndex(index)}
                  sliders={props.slider.data.content.sliders}
                  language={props.slider.data.content.sliders.language}
                />

                <div className={classes.dataContainer}>
                  <DataDashboard
                    index={index}
                    sliders={props.slider.data.content.sliders}
                    language={props.slider.data.content.sliders.language}
                    mock={props.mock}
                    data={(props.slider.data.content.items[index]) ? props.slider.data.content.items[index] : props.slider.data.content.items[0]}
                  />
                </div>
              </div>
            </>
          </>
        )
      : (
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',height : '100%'}}>
          <div style={{width:'50%'}}>
            <Lottie
              options={defaultOptions}
              isStopped={false}
              isPaused={false}
            />
          </div>
        </div>
    )
  )
}


const mapStateToProps = state => ({
  slider: state.sliderPage,
  errors:state.sliderPage.errors,
  slide: state.sliderPage.data.content.sliders,
  loaders: state.sliderPage.loaders,
  user: state.auth.user,
});

const mapActionsToProps = {
  onGetSlider : getSlider,
  onApiCall : apiCall,
  onAddMessage : addMessage,
};

export default compose(
  connect(mapStateToProps , mapActionsToProps)
)(DataSlide)
