import {createMuiTheme} from '@material-ui/core'
const Theme = createMuiTheme({
  palette: {
    primary:{
      background: '#f5f6f7',
      light: '#f9a955',
      main: '#478861',
      dark: '#121316',
      contrastText: '#fff'
    },
    secondary:{
      light: '#f9a955',
      main: '#478861',
      dark: '#23262b',
      contrastText: '#ffffff'
    },
    active:{
      main: '#5CA14D'
    },
    background:{
      main: '#fff'
    },
    success: {
      main: '#40ce6a',
      dark: '#2cb346'
    },
    warning: {
      main: '#ffcc5e',
      dark: '#ffb340'
    },
    danger: {
      main: '#ff6851',
      dark: '#ff5544'
    },
    info: {
      main: '#2e4770',
      dark: '#58c2f9'
    },
    gray: {
      main: '#7d878c',
      secondary: '#cccccc',
      dark: '#4b4b4b'
    },
    /* using on layout/ui-blur/. */
    backgroundBlur: {
      backdropFilter: 'blur(10px) contrast(.1)',
      background: 'rgba(255,255,255,0.88)'
    }
  },
  page : {
    padding : 24,
    backgroundColor  : '#cecece'
  },
  overrides: {
    MuiTypography:{
      body1:{
        fontFamily: 'Nunito, sans-serif',
        fontSize : 16
      },
      h6:{
        fontFamily: 'Montserrat, sans-serif'
      },
      h5:{
        fontSize : 28,
        fontFamily: 'Montserrat, sans-serif'
      },
      subtitle2:{
        fontSize : 20,
        fontFamily: 'Montserrat, sans-serif'
      }
    },
    MuiInputBase:{
      input:{
        borderTopLeftRadius: '18px !important',
        borderBottomLeftRadius: '18px !important',
      }
    },
    MuiToolbar:{
      regular : {
        minHeight : '56px important'
      }
    },
    MuiPaper:{
      elevation1 : {
        boxShadow : 'unset'
      },
      rounded:{
        borderRadius : 18
      }
    },
    MuiDialogActions:{
      root: {
        borderBottomLeftRadius: 18,
        borderBottomRightRadius: 18,
      }
    },
    MuiAvatar : {
      colorDefault : {
        border : '1px solid rgb(71, 136, 97)',
        color : '#fff !important',
        background : '#478861 !important'
      }
    },
    MuiList:{
      padding : {
        paddingTop : 0
      }
    },
    MuiButton:{
      root:{
        fontSize : 14
      }
    },
    MuiIconButton:{
      edgeStart : {
        marginLeft : 0
      },
    },
    MuiTablePagination:{
      caption:{
        fontFamily: 'Nunito, sans-serif'
      },
      select:{
        fontFamily: 'Nunito, sans-serif'
      }
    },
    MuiTableRow:{
      root:{
        verticalAlign : 'center'
      }
    },
    MuiTableCell:{
      head:{
        fontSize : 16,
        fontWeight:600,
        minWidth: 216
      },
      root:{
        fontSize : 16,
        fontFamily: 'Nunito, sans-serif',
      },
      body : {
        minWidth: 216,
        maxWidth: 216,
      }
    }
  },
  typography: {
    // Use the font Poppins import on public/index.html
    fontSize: 13,
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(',')
  },
  pageContainerPadding: {
    unit: 40
  },
});
export default Theme
