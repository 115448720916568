import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  root: {
    margin:'8px 0px',
    display:'flex',
    justifyContent:'flex-start',
    alignItems:'flex-start',
    flexDirection:'column',
    borderRadius:50,
    transitionDuration:'0.4s',
    '&:hover':{
      background:'#e1e1e1',
      cursor:'pointer',
    },
    width: 184
  },
  title:{
    color:'#111',
    fontSize : 14,
    fontWeight:600
  },
  description:{
    fontSize:12,
    textAlign:'center',
    maxWidth: 130,
    minHeight: 42,
    marginTop:4,
    color: '#606060'
  },
  icon:{
    width: 32,
    height: 32,
    marginRight: 8,
    display:'flex',
    alignItems:'left',
    justifyContent: 'center'
  },
  label :{
    alignItems : 'center'
  }
}));

function DashboardCard (props){
  const classes = useStyles(props);
  const {
    title,
    icon,
    color,
    size=20,
    url
  } = props;

  const iconStyle = {
    marginRight: 8,
    display:'flex',
    alignItems:'left',
    justifyContent: 'center'
  };

  return (
    <Link to={url} style={{textDecoration:'unset',width: 184}}>
      <Button variant="outlined" color="primary" className={classes.root} classes={{label : classes.label}}>
        <i className={`icon-${icon}`} style={{ fontSize: size , color , ...iconStyle }} />
        <Typography className={classes.title}>{title}</Typography>
      </Button>
    </Link>
  )

}

export default withStyles({withTheme: true})(DashboardCard)
