import React from 'react';

function NotAuthenticatedLayout(props){

    return(
        <React.Fragment>
            {props.children}
        </React.Fragment>
    )
}

export default NotAuthenticatedLayout;
