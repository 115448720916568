import React from 'react';

export default function Rain(props){
  const {color = 'rgb(0, 0, 0)'} = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Livello_1" data-name="Livello 1" viewBox="0 0 96 96">
      <title>rain</title>
      <path fill={color} d="M76.85,37.78a16.65,16.65,0,0,0-7.41-1.88H68.8a16,16,0,0,0-6.93-13.32c-3.76-2.69-6.41-3.23-9.47-3.23-1.17,0-2.4.08-3.55.18C43.33,20,37,25.61,35.23,29.07l-.06.1a13.91,13.91,0,0,0-2.58-.23h0a10.58,10.58,0,0,0-5.83,1.55C22.83,33,22.13,37.55,22,39.74,18,40.84,12.3,44.16,11.82,51c-.38,5.4,1.34,8.46,2.79,10a7.66,7.66,0,0,0,5.26,2.61H72.39a11.38,11.38,0,0,0,11.5-9.47C84.28,52.21,85.78,42.24,76.85,37.78ZM79,53.18a6.43,6.43,0,0,1-6.6,5.45H20.13a2.7,2.7,0,0,1-1.8-.95c-1.18-1.27-1.72-3.52-1.52-6.33.39-5.6,7-6.9,7.3-7l.95-.17a2.5,2.5,0,0,0,2-2.66L27,40.61s-.23-4.2,2.37-5.88a5.67,5.67,0,0,1,3.07-.8h.1a10.45,10.45,0,0,1,2.27.27l.68.19a2.51,2.51,0,0,0,2.63-.84l.45-.56a10.23,10.23,0,0,0,1.07-1.61c1.2-2.39,6.19-6.59,9.59-6.87,1-.08,2.16-.16,3.15-.16,2.22,0,3.85.35,6.55,2.29A10.73,10.73,0,0,1,63.7,36.89l-.33,1.69a2.53,2.53,0,0,0,.76,2.31,2.49,2.49,0,0,0,2.36.57l1.6-.44a6,6,0,0,1,1.27-.12,11.72,11.72,0,0,1,5.22,1.33C76,42.93,80.47,45.8,79,53.18Z" />
      <path fill={color} d="M62.68,66.36a1.5,1.5,0,1,0-2.56,1.55l4.26,7a1.49,1.49,0,0,0,1.28.72,1.55,1.55,0,0,0,.78-.21,1.5,1.5,0,0,0,.5-2.06Z" />
      <path fill={color} d="M54.05,66.36a1.5,1.5,0,1,0-2.56,1.55l4.26,7a1.49,1.49,0,0,0,1.28.72,1.55,1.55,0,0,0,.78-.21,1.5,1.5,0,0,0,.5-2.06Z" />
      <path fill={color} d="M45.42,66.36a1.5,1.5,0,1,0-2.56,1.55l4.26,7a1.49,1.49,0,0,0,1.28.72,1.55,1.55,0,0,0,.78-.21,1.5,1.5,0,0,0,.5-2.06Z" />
      <path fill={color} d="M36.79,66.36a1.5,1.5,0,1,0-2.57,1.55l4.27,7a1.49,1.49,0,0,0,1.28.72,1.55,1.55,0,0,0,.78-.21,1.5,1.5,0,0,0,.5-2.06Z" />
    </svg>
    )
}
