import React, {useEffect, useState , useRef} from 'react';
import {connect} from "react-redux";
import { CircularProgress, MuiThemeProvider } from "@material-ui/core";
import {SnackbarProvider} from "notistack";
import {BrowserRouter, Switch} from "react-router-dom";
import {MessagesProvider, UserProvider} from '@cobrains/react-kit'
import history from "./routing/history";
import Routing from "./routing/Routing";

function Main(props) {
  const {environment} = props;
  const Layout = environment && environment.layout;
  const routes = environment && environment.routes;

  return (
    <>
      <UserProvider>
        {({ isPending }) => (
          <>
            {props.environment && (
              <MuiThemeProvider theme={props.environment.theme}>
                {(!isPending) ? (
                  <SnackbarProvider maxSnack={5} preventDuplicate>
                    <BrowserRouter history={history}>
                      <Layout>
                        <Routing routes={routes} />
                      </Layout>
                    </BrowserRouter>
                    <MessagesProvider />
                  </SnackbarProvider>
                )
                : (
                  <div style={{width:'100%',height:'100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <CircularProgress size={52} color="primary" />
                  </div>
              )}
              </MuiThemeProvider>
            )}
          </>
          )}
      </UserProvider>
    </>
  );
}

const mapStateToProps = state => ({
    environment: state.environment,
    auth: state.auth,
})

export default connect(mapStateToProps)(Main);
