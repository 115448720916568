import React from 'react';
import { Provider } from 'react-redux'
import {composeWithDevTools} from "redux-devtools-extension";
import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";
import { initSettings } from '@cobrains/react-kit';
import {loadState, saveState} from "./redux/ReduxPersist";
import rootReducer from "./redux/rootReducer";
import Main from "./Main";
import Settings from './settings/Settings';
import "./fontello/css/fontello.css"

// Create the Redux Store checking the local storage
const store = createStore(rootReducer, loadState(), composeWithDevTools(applyMiddleware(thunk)));

// Init @cobrains/react-kit
initSettings(Settings.REACT_KIT_SETTINGS, loadState());

// On the change's state save it on local storage
store.subscribe(() => {saveState(store.getState())})

function App() {
  return (
    <Provider store={store}>
      <Main />
    </Provider>
  );
}

export default App;
