import Settings from '../settings/Settings';
import {initialState} from "./initialState";


export const LocalStorageKey = Settings.STATE_KEY_LOCAL_STORAGE;

// Define the initial default state
const defaultState = initialState;

// Load state of redux from LocalStorage
export const loadState = () => {
    try {
        const serializedState = localStorage.getItem(LocalStorageKey);
        const store = Object.assign(defaultState,JSON.parse(serializedState))
        return store;
    }catch(err){
        return undefined;
    }
};

// Save state of redux to LocalStorage
export const saveState = (state) => {
    try {
        // Define the new Local State
        const localState = {
            auth: {
                user: state.auth.user
            },
            config: {}
        };
        const serializedState = localStorage.getItem(LocalStorageKey);
        if(!compare(localState,JSON.parse(serializedState))){
            const serializedState = JSON.stringify(localState);
            localStorage.setItem(LocalStorageKey, serializedState);
        }
    }catch(err){
        // Ignore write errors
    }
};

// Compare the redux state and the local storage
function compare(a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
}
