import React ,{useEffect,useState, useRef}from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Helmet } from 'react-helmet';
import './style/styles.css';
import Lottie from 'react-lottie';
import Typography from '@material-ui/core/Typography';
import { addMessage, apiCall } from '@cobrains/react-kit';
import LogoSlide from '../../../components/slider/logo/LogoSlide'
import ImgSlider from '../../../components/slider/img/ImgSlider'
import VideoSlider from '../../../components/slider/video/VideoSlider'
import DataSlide from '../../../components/slider/data/DataSlide'
import mock from './mock'
import {getSlider} from '../../../redux/pages/slider-page-redux';
import sliderload from '../../../assets/loaders/loader.json'
import SignInFormSlider from '../../auth/components/SignInFormSlider';
import rotate from '../../../assets/icons/rotate'
import i18n from 'i18next';
import { changeLocale } from '../../../translations/i18n';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor : '#fff',
    width : '100vw',
  },
  overlay : {
    position: 'absolute',
    width: '100%!important',
    height: '100%!important',
    background : '#fff',
    top: 0,
    left: 0,
    zIndex: 1300,
  },
  overlayNoTouch : {
    position: 'absolute',
    width: '100%!important',
    height: '100%!important',
    background : 'transparent',
    top: 0,
    left: 0,
    zIndex: 10001,
  }
}));

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: sliderload,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

function getSliderAnimation() {


  switch(mock.theme.animation){
    case 'slide':
      return (
        <style>
          {`
        .awssld--moveLeft{
          -webkit-animation:slideToLeft var(--slider-transition-duration) 
          both var(--transition-bezier);animation:slideToLeft 
          var(--slider-transition-duration) both var(--transition-bezier)
        }
        .awssld__timer--run{
        visibility : hidden;
        }
        .awssld--moveRight{
        -webkit-animation:slideFromRight 
        var(--slider-transition-duration) 
        both var(--transition-bezier);animation:slideFromRight 
        var(--slider-transition-duration) both var(--transition-bezier)
        }
        `}
        </style>
      );
    case 'fade':
      return (
        <style type="text/css">
          {`
        .awssld--moveLeft{
        -webkit-animation:fadeOut 
        var(--slider-transition-duration) 
        both var(--transition-bezier);animation:fadeOut 
        var(--slider-transition-duration) 
        both var(--transition-bezier)
        }
        .awssld__timer--run{
        visibility : hidden;
        }
        .awssld--moveRight{
        -webkit-animation:fadeIn 
        var(--slider-transition-duration) 
        both var(--transition-bezier);animation:fadeIn 
        var(--slider-transition-duration) both var(--transition-bezier)
        }
        `}
        </style>
      );
    default:
      return (
        <style>
          {`
        .awssld--moveLeft{
          -webkit-animation:slideToLeft var(--slider-transition-duration) 
          both var(--transition-bezier);animation:slideToLeft 
          var(--slider-transition-duration) both var(--transition-bezier)
        }
        .awssld__timer--run{
        visibility : hidden;
        }
        .awssld--moveRight{
        -webkit-animation:slideFromRight 
        var(--slider-transition-duration) 
        both var(--transition-bezier);animation:slideFromRight 
        var(--slider-transition-duration) both var(--transition-bezier)
        }
        `}
        </style>
      );
  }
}

let timeId;

function DashboardSlider(props){
  const classes = useStyles(props);
  const SliderRef = useRef();
  const [play , setPlay] = useState(true);
  const [selected , setSelected] = useState(0);
  const [login , setLogin] = useState(false);
  const [loader , setLoader] = useState(false);
  const [orientation , setOrentation] = useState('null');
  const [interval] = useState(3000);


  const slides = (props.slide) ? Object.keys(props.slide).map((item, index) => {
    if (props.slide[item])
    switch(item){
      case 'logo': return (
        <div className={`container ${classes.container}`}>
          <LogoSlide logo={props.slide[item]} />
        </div>
      );
      case 'image': return (
        <div className={`container ${classes.container}`}>
          <ImgSlider image={props.slide[item]} slang={props.slide.slang} />
        </div>
      );
      case 'video': return (
        <div className={`container ${classes.container}`}>
          <VideoSlider
            video={props.slide[item]}
            setPlay={() => {
                         setPlay(false);
                       }}
            changeSlide={() => {
                         setPlay(true);
                         SliderRef.current.state.selected = SliderRef.current.state.selected + 1;
                       }}
          />
        </div>
      );
      default: return null;
    }
    else
      return null
  }).filter(item => item !== null) : [];



  const dashboard = [
    <div className={`container ${classes.container}`}>
      <DataSlide
        data={props.slider.data.content.items}
        sliders={props.slider.data.content.sliders}
        setPlay={() => {
          setPlay(false);
        }}
        changeSlide={() => {
          setPlay(true);
          SliderRef.current.state.selected = 0;
        }}
      />
    </div>
  ];

  let SliderFinal =  (props.slider.data.content.items.length > 0) ?  slides.concat(dashboard) : slides;



  function setScreenOrientation() {

    if (window.matchMedia("(orientation: landscape)").matches) {
      setOrentation('landscape')
    }
    if (window.matchMedia("(orientation: portrait)").matches) {
      setOrentation('portrait')
    }
  }

  function Refresh(){
    props.onGetSlider(props.match.params.id)
  }

  function setSlideRefreshInterval() {
    timeId = setInterval(() => Refresh(), 600000
    );
  }



  useEffect(() => {
    if (window.matchMedia("(orientation: landscape)").matches) {
      setOrentation('landscape')
    }
    if (window.matchMedia("(orientation: portrait)").matches) {
      setOrentation('portrait')
    }

    window.addEventListener('resize', setScreenOrientation);


    props.onApiCall({method:'GET',url:`group/update-data/${props.match.params.id}/auth`}).then(
      res => {
        setSlideRefreshInterval();
        props.onGetSlider(props.match.params.id)
        setLoader(false)
      },
      err =>{
        if(err.response && err.response.data && err.response.data.messages && err.response.data.messages[0].message === "unauthorized") {
          setLoader(false);
          setLogin(true);
        }
        else if (err.response && err.response.data && err.response.data.messages && err.response.data.messages[0].message === "group not found") {
          setLoader(true);
          setTimeout(() => {
            window.location.href = `/cerca`
          },3000);
        }
        else{
          setLoader(true);
          setTimeout(() => {
            window.location.href = `/cerca`
          },2000);
        }

      })
  }, []);

  useEffect(() => {
    SliderFinal = (props.slider.data.content.items.length === 0) ? slides.concat(dashboard) : slides;
  }, [props.slider.data.content.items]);


  useEffect(() => {
    if (props.slide && props.slide.language) {
      i18n.init({ preload: [`${props.slider.data.content.sliders.language}`] });
      i18n.loadLanguages([`${props.slider.data.content.sliders.language}`]);
      changeLocale(`${props.slider.data.content.sliders.language}`);
    }
  }, [props.slider.data.content.sliders]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: sliderload,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  function getError() {
      if (props.errors.get.data.messages && props.errors.get.data.messages.length > 0 && props.errors.get.data.messages[0].message === 'unauthorized' || login === true){
        return(
          <SignInFormSlider onLogin={() => {
            props.onGetSlider(props.match.params.id)
          }}
          />
        )}
      if ( (props.errors.get.data.messages && props.errors.get.data.messages.length > 0) || (props.errors.get.data && typeof props.errors.get.data === 'string')){
        setLoader(true);
        setTimeout(() => {
          window.location.href = `/cerca`
        },3000);
        props.onAddMessage('Erorre nella chiamata controllare la conessione internet', 'warning');
        return(
          <div style={{display:'flex',justifyContent:'center',alignItems:'center',height : '100%'}}>
            <div style={{width:'50%'}}>
              <Lottie
                options={defaultOptions}
                isStopped={false}
                isPaused={false}
              />
            </div>
          </div>
        )
      }
  }


  const awesomeSlider = (!props.loaders.get && !loader)  ?
    (props.slide === undefined) ?
      getError()
          :
    (
      <>
        {(orientation === 'portrait') ? (
          <div
            className={classes.overlayNoTouch}
            style={{backgroundColor : '#fff',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div style={{width : '30%'}}>
              {rotate()}
            </div>
            <Typography style={{
                fontSize: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                wordBreak: 'break-word',
                textAlign: 'center',
                margin: '10%',
                zIndex : 10000,
                color: '#bcbcbc',
                fontStyle: 'italic',
                visibility: (orientation === 'portrait') ? 'visible' : 'hidden'
                // fontWeight: '700
                // '
              }}
            >
                Ruota il dispositivo per visualizzare la dashboard
            </Typography>
          </div>
          )
          :
          <div className={classes.overlayNoTouch} />}

        <AutoplaySlider
          organicArrows
          selected={selected}
          ref={SliderRef}
          onTransitionEnd={() => setSelected(selected + 1)}
          fillParent
          play={play}
          bullets={false}
          interval={interval}
        >
          {SliderFinal}
        </AutoplaySlider>
      </>
  )
    : (
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',height : '100%'}}>
        <div style={{width:'50%'}}>
          <Lottie
            options={defaultOptions}
            isStopped={false}
            isPaused={false}
          />
        </div>
      </div>
);

  return(
    <div className={`overlay ${classes.overlay}`}>
      <Helmet>
        {getSliderAnimation()}
      </Helmet>
      {awesomeSlider}
    </div>
  )

}


const mapStateToProps = state => ({
  slider: state.sliderPage,
  errors:state.sliderPage.errors,
  slide: state.sliderPage.data.content.sliders,
  loaders: state.sliderPage.loaders,
  user: state.auth.user,
});

const mapActionsToProps = {
  onGetSlider : getSlider,
  onApiCall : apiCall,
  onAddMessage : addMessage,
};

export default compose(
  connect(mapStateToProps , mapActionsToProps)
)(DashboardSlider)
