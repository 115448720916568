import React from 'react';
import PlantDashboard from "./PlantDashboard";


function PlantDashboardLayout(props){
  return(
    <div style={{
      top: 0,
      left: 0,
      height: '100vh',
      width: '100vw',
      zIndex: 1300,
      position: 'absolute',
      background: '#fff'
    }}>
      <PlantDashboard  {...props} />
    </div>
  )
}

export default PlantDashboardLayout;
