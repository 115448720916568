import React from 'react';

export default function Sun(props){
  const {color = 'rgb(0, 0, 0)'} = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Livello_1" data-name="Livello 1" viewBox="0 0 96 96">
      <title>sun</title>
      <g id="Group_45" data-name="Group 45"><g id="Ellipse_2" data-name="Ellipse 2">
        <path fill={color} d="M48,66.32A18.32,18.32,0,1,1,66.32,48,18.34,18.34,0,0,1,48,66.32Zm0-31.64A13.32,13.32,0,1,0,61.32,48,13.34,13.34,0,0,0,48,34.68Z"/></g><g id="Line_54" data-name="Line 54">
        <path fill={color} d="M29.36,32.16a2.49,2.49,0,0,1-1.77-.73l-5.51-5.51a2.5,2.5,0,0,1,3.54-3.54l5.51,5.52a2.5,2.5,0,0,1-1.77,4.26Z"/></g><g id="Line_55" data-name="Line 55">
        <path fill={color} d="M47.79,24.36a2.5,2.5,0,0,1-2.5-2.5v-7.8a2.5,2.5,0,0,1,5,0v7.8A2.5,2.5,0,0,1,47.79,24.36Z"/></g><g id="Line_56" data-name="Line 56">
        <path fill={color} d="M66.34,31.86a2.5,2.5,0,0,1-1.77-4.27l5.51-5.51a2.5,2.5,0,0,1,3.54,3.54L68.1,31.13A2.45,2.45,0,0,1,66.34,31.86Z"/></g><g id="Line_57" data-name="Line 57">
        <path fill={color} d="M81.94,50.29h-7.8a2.5,2.5,0,0,1,0-5h7.8a2.5,2.5,0,0,1,0,5Z"/></g><g id="Line_58" data-name="Line 58">
        <path fill={color} d="M72.15,74.35a2.53,2.53,0,0,1-1.77-.73L64.87,68.1a2.5,2.5,0,0,1,3.54-3.53l5.51,5.51a2.5,2.5,0,0,1,0,3.54A2.53,2.53,0,0,1,72.15,74.35Z"/></g><g id="Line_59" data-name="Line 59">
        <path fill={color} d="M48.21,84.44a2.5,2.5,0,0,1-2.5-2.5v-7.8a2.5,2.5,0,0,1,5,0v7.8A2.5,2.5,0,0,1,48.21,84.44Z"/></g><g id="Line_60" data-name="Line 60">
        <path fill={color} d="M24.15,74.65a2.49,2.49,0,0,1-1.77-.73,2.52,2.52,0,0,1,0-3.54l5.52-5.51a2.5,2.5,0,0,1,3.53,3.54l-5.51,5.51A2.49,2.49,0,0,1,24.15,74.65Z"/></g><g id="Line_61" data-name="Line 61">
        <path fill={color} d="M21.85,50.71H14.06a2.5,2.5,0,0,1,0-5h7.79a2.5,2.5,0,0,1,0,5Z"/>
      </g></g>
    </svg>
  )
}

