import React from 'react';
import SignInForm from "./components/SignInForm";
import Environments from "../../enviroments/Environments";


function SignIn(props){

  const SignInLayout = (props) => Environments.notAuthenticated.settings.signIn.layout(props);


    return(
        <SignInLayout>
            <SignInForm autocomplete={false} />
        </SignInLayout>
    )
}

export default SignIn;
