import React, { useEffect , createRef  } from 'react';
import 'video-react/dist/video-react.css'
import { Player } from 'video-react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Helmet } from "react-helmet";
import GemVideo from '../../../assets/videos/GEM _ PROMO_OFFICIAL 2017.mp4'

const useStyles = makeStyles(theme => ({
  wrapper: {
    position : 'absolute',
    top : 0,
    left : 0,
    height : '100%',
    width : '100%',
    display : 'flex',
    justifyContent : 'center',
    alignItems : 'center'
  },
}));

export default function VideoSlider(props) {
  const classes = useStyles(props);
  const {video = GemVideo , changeSlide , setPlay} = props;

  useEffect(() => {
    setPlay(false);
  }, []);



  return(
    <div className={classes.wrapper}>
      <Helmet>
        <style type="text/css">
          {`
        .video-react-control-bar{display : none !important;}
        .video-react .video-react-big-play-button.big-play-button-hide{display : none !important;}
        `}
        </style>
      </Helmet>
      <Player autoPlay muted fluid {...props} onEnded={() => changeSlide()}>
        <source src={video} />
      </Player>
    </div>
  )
}
