import echarts from 'echarts';


const option = props => {
  const { color = 'rgb(78, 131, 60)', dataGraph, index } = props;
  const start = Object.keys(dataGraph).findIndex(item => item === '04:00');
  const end = Object.keys(dataGraph).findIndex(item => item === '22:00');
  const dateReal =
    start === -1 || end === -1 ? Object.keys(dataGraph) : Object.keys(dataGraph).slice(start, end);
  const dataReal =
    start === -1 || end === -1
      ? Object.values(dataGraph)
      : Object.values(dataGraph).slice(start, end);

  const interval = ["00", "15", "30", "45"];
  let times = [];
  for(let i = 0; i < 24; i++){
    for(let j = 0; j < 4; j++){
      let time = `${i  }:${  interval[j]}`;
      if(i < 10){
        time = `0${  time}`;
      }
      times.push(time);
    }
  }

  times = start === -1 || end === -1 ? times : times.slice(start, end);




  return {
    animation:false,
    xAxis: {
      type: 'category',
      data: index === 0 ? dateReal : times,
      boundaryGap: false,
      splitLine: { show: false },
    },
    yAxis:
      window.innerHeight <= 425
        ? {
            type: 'value',
            splitLine: { show: false },
            boundaryGap: [0, '100%'],
            splitNumber: 4,
          }
        : {
            type: 'value',
            splitLine: { show: false },
            boundaryGap: [0, '100%'],
          },
    grid: {
      left: 70,
      right: 40,
      bottom: 50,
      top: 20,
    },
    series: [
      {
        name: 'test',
        type: 'line',
        symbol: 'none',
        itemStyle: {
          color,
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(78, 131, 60, 1, [
            {
              offset: 0,
              color: 'rgb(255, 255, 255)',
            },
            {
              offset: 1,
              color,
            },
          ]),
        },
        data: dataReal,
      },
    ],
  };
};

export default option;
