import { withStyles } from '@material-ui/core/styles'
import { compose } from 'redux'
import {
  Search,
  FormGenerator,
  apiCall,
  FunctionsToolbar,
  ConfirmDialog,
  getDataFilter,
  getDataPagination,
  TableGenerator,
} from '@cobrains/react-kit';
import {connect} from 'react-redux'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import CrudFields from './settings/crud/CrudFields'
import TokenCrudFields from './settings/crud/TokenCrudFields'
import { translate } from '../../../translations/i18n';
import { getItems } from './settings/functions-toolbar/Items';
import TableFields from './settings/table/TableFields';
import {
  getPlants,
  editPlant,
  createPlant,
  deletePlant,
  plantSelected,
  preparePlantTable,
  setDialog,
  plantResetSelected,
  preparePlant,
  prepareToken,
  createToken,
  resetPreparePlant,
  addPlantQueryFilter,
  resetPlantsData,
} from '../../../redux/pages/plants-page-redux';

const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    padding: 20
  }
}));

function PlantCrud (props){
  const classes = useStyles(props);
  useEffect(() => {
    props.onResetData();
    props.onResetSelected();
    props.onPreparePlantTable();
    props.onGetPlants();
  }, []);

  return (
    <div className={classes.root}>
      <div style={{display: 'flex'}}>
        <FunctionsToolbar items={getItems(props)} style={{ flex: 1 }} />
        <Search
          buttonStyle={{borderTopRightRadius : 18 , borderBottomRightRadius : 18 , boxShadow : 'unset'}}
          data={props.plants.data.plants} // data list
          keySearch={['first_name', 'last_name', 'plantname']} // key that have to search
          placeholder="Ricerca per nome, cognome o plantname" // placeholder
          onConfirm={(value, search) => {
                props.onResetData();
                getDataFilter(props.onGetPlants, {props, search})
              }} // action after search
          style={{flex: 1}}
        />
      </div>
      {(props.prepare.fields.length > 0 && props.dialogs.api) && (
        <FormGenerator
          buttonVariant='contained'
          fields={[props.prepare.fields, TokenCrudFields]}
          isPending={false}
          onSubmit={(token) => {
            props.onCreateToken({id : props.plants.selected[0].id, ...token})
            props.onSetDialog('api', false)
            props.onResetPreparePlant();
          }}
          onCancel={() => {
            props.onSetDialog('api', false)
            props.onResetPreparePlant();
          }}
          errors={props.errors.api}
          dialog
          dialogProps={{
            onClose: () => {
              props.onSetDialog('api', false);
              props.onResetPreparePlant();
            },
            open:props.dialogs.api,
            title:'Gestione Token',
            subtitle: `${props.plants.selected[0].name} - Aggiungi o sostituisci i token delle API`
          }}
        />
      )}
      {(props.prepare.fields.length > 0) && (
      <FormGenerator
        buttonVariant='contained'
        fields={[props.prepare.fields, CrudFields]}
        isPending={false}
        layoutStyle={{width : '30%'}}
        onSubmit={(plant) => {
              props.onCreatePlant(plant);
            }}
        onCancel={() => {
              props.onSetDialog('add', false);
              props.onResetPreparePlant();
            }}
        translate={translate}
        errors={props.errors.create.data.messages}
        dialog
        dialogProps={{
              onClose: () => {
                props.onSetDialog('add', false);
                props.onResetPreparePlant();
              },
              open:props.dialogs.add,
              title:'Aggiungi un nuovo impianto',
              subtitle:'Compila i campi per aggiungere un nuovo impianto'
            }}
        actionsProps={{
              cancelProps : {confirm: true}
            }}
      />
          )}
      {(props.prepare.fields.length > 0) && (
      <FormGenerator
        buttonVariant='contained'
        fields={[props.prepare.fields, CrudFields]}
        data={props.plants.selected}
        isPending={false}
        layoutStyle={{width : '30%'}}
        onSubmit={(plant) => {
              props.onEditPlant(plant , props.plants.selected[0].id);
            }}
        onCancel={() => {
              props.onSetDialog('edit', false);
              props.onResetPreparePlant();
            }}
        errors={props.errors.edit.data.messages}
        dialog
        dialogProps={{
              onClose: () => {
                props.onSetDialog('edit', false);
                props.onResetPreparePlant();
              },
              open:props.dialogs.edit,
              title:'Modifica un impianto',
              subtitle: `Compila i campi per modificare l'impianto`
            }}
        actionsProps={{
              cancelProps : {confirm: true}
            }}
      />
          )}
      <Paper style={{overflow: 'auto', marginTop:20}}>
        <TableGenerator
          labelRowsPerPage="Righe per pagina"
          loader={props.loaders.get}
          data={props.plants.data.content.items}
          rowsPerPage={25}
              // maxSelectableElements={1}
          onOrderChange={(order , orderBy) => console.log('order',order , orderBy)}
          selectedElements={props.plants.selected}
          onChangeSelected={(data) => props.onPlantSelected(data)}
          fields={[props.plants.table.fields ,TableFields]}
          onChangePage={(page, limit, offset, filters) => getDataPagination(props.onGetPlants, {props, limit, offset, filters})}
          onFiltersChange={(page, limit, offset, filters) => getDataFilter(props.onGetPlants, {props, limit, offset, filters})}
        />
      </Paper>
      {(props.dialogs.delete) && (
      <ConfirmDialog
        open={props.dialogs.delete}
        title="Eliminazione impianto"
        message={"Sei sicuro di voler eliminare l'impianto?"}
        onCancel={() => props.onSetDialog('delete', false)}
        onSubmit={() => props.plants.selected.map((plant , index) => {
                props.onDeletePlant(plant.id);
                if (props.plants.selected.length === index + 1)
                  props.onResetSelected()
              })}
        cancelProps={{ label: 'Annulla'}}
        submitProps={{ label: 'Elimina'}}
      />
          )}
    </div>
    )
}


const mapStateToProps = state => ({
  user: state.auth.user,
  plants: state.plantsPage,
  dialogs: state.plantsPage.dialogs,
  loaders: state.plantsPage.loaders,
  errors: state.plantsPage.errors,
  prepare: state.plantsPage.prepare,
  filters: state.plantsPage.filters
});

const mapActionsToProps = {
  onApiCall : apiCall,
  onGetPlants: getPlants,
  onPlantSelected: plantSelected,
  onSetDialog: setDialog,
  onCreatePlant: createPlant,
  onEditPlant: editPlant,
  onDeletePlant: deletePlant,
  onResetSelected: plantResetSelected,
  onPreparePlant: preparePlant,
  onResetPreparePlant: resetPreparePlant,
  onPreparePlantTable:preparePlantTable,
  onCreateToken: createToken,
  onPrepareToken: prepareToken,
  /* Common */
  onAddQueryFilter: addPlantQueryFilter,
  onResetData: resetPlantsData
};

export default compose(
    withStyles({withTheme: true}),
  connect(mapStateToProps , mapActionsToProps)
)(PlantCrud)
