
const crudFields = [
  {
    key: 'name',
    label: 'Name',
    required : 'true'
  },
  {
    key: 'group' ,
    label: 'Gruppi',
  },
  {
    key: 'address' ,
    label: 'Indirizzo',
    component : 'input'
  },
];

export default crudFields
