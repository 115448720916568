import React from 'react';

export default function total_daily_production(props) {
  const { color = "rgb(78, 131, 60)" } = props;

  return (
  <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96">
    <path
      fill={color}
      d="M37.3,70.53A26.07,26.07,0,1,1,70.17,48.64a2.5,2.5,0,0,0,5,.62,31.26,31.26,0,0,0-22.5-33.77A31.07,31.07,0,0,0,36,75.35a2.57,2.57,0,0,0,.67.09,2.5,2.5,0,0,0,.67-4.91Z"/>
    <path
      fill={color}
      d="M43,29.81V45.29L32.64,55.61a2.5,2.5,0,1,0,3.53,3.53l11-11A2.47,2.47,0,0,0,48,46.33V29.81a2.5,2.5,0,0,0-5,0Z"/>
    <path
      fill={color}
      d="M82,52.56a2.49,2.49,0,0,0-2.14-.67L68.93,53.68a2.5,2.5,0,0,0,.81,4.94l3.17-.52L60.69,70.74l-5.94-5.81a2.5,2.5,0,0,0-3.54,0l-12,12.44a2.5,2.5,0,1,0,3.59,3.48L53.05,70.27,59,76.08a2.44,2.44,0,0,0,1.78.71A2.52,2.52,0,0,0,62.53,76L76.88,61.19,76.38,65a2.5,2.5,0,0,0,2.16,2.8l.32,0a2.5,2.5,0,0,0,2.48-2.18l1.42-11A2.51,2.51,0,0,0,82,52.56Z"/>
  </svg>
  )
}
