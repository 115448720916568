import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import co2_saved from '../../../../assets/icons/co2_saved'
import { translate } from '../../../../translations/i18n';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width:'100%',
    height : '100%',
    display : 'flex',
    justifyContent : 'flex-start',
    alignItems : 'center',
    [theme.breakpoints.down('md')]:{
      paddingBottom : '0px !important'
    },
  },
  wrapperIcon : {
    width: '15%',
    margin : '0px 0px 0px 15%',
    [theme.breakpoints.down('md')]:{
      margin : '18% 0px 15%',
      width: '25%',
    },
    [theme.breakpoints.down('sm')]:{
      margin : 0,
      width: '12%',
    },
    [theme.breakpoints.up(2560)]:{
      width: '15%',
      margin : '0px 0px 0px 4%',
    },
  },

  unitText:{
    // fontSize : 40 ,
    fontFamily: 'Nunito sans',
    fontSize : '2.5rem',
    [theme.breakpoints.down('sm')]:{
      fontSize : '1rem !important'
    },
    [theme.breakpoints.up(2560)]:{
      fontSize : '3.5rem'
    },
    fontWeight : 'bold'
  },
  descText:{
    fontFamily: 'Nunito sans',
    textTransform: 'uppercase',
    // fontSize : 16,
    fontSize : '1.3rem',

    [theme.breakpoints.down('sm')]:{
      fontSize : '0.6rem !important'
    },
    [theme.breakpoints.up(2560)]:{
      fontSize : '2.0rem'
    },
    color : '#888888',
    textAlign : 'center'
  }

}));

export default function Co2Graph(props) {
  const classes = useStyles(props);
  const { color = 'rgb(78, 131, 60)' , value = 0} = props

  return(
    <div className={classes.wrapper} style={(window.innerWidth <= 1440) ? {} : {}}>
      <div className={classes.wrapperIcon}>
        {co2_saved({ color })}
      </div>


      <div style={{alignItems : 'start' , display : 'flex' , flexDirection : 'column', marginLeft : 16 }}>
        <Typography className={classes.unitText} style={(window.innerWidth <= 1440) ? {fontSize : 28,} : {}}>
          {(value) ? Math.round(value * 1000).toLocaleString(props.language, {maximumFractionDigits:2}) : 0}
          {' '}
          kg
        </Typography>

        <Typography className={classes.descText} style={(window.innerWidth <= 1440) ? {fontSize : 16,} : {}}>
          CO2 emission saved
        </Typography>
      </div>
    </div>
  )
}
