import React , {useEffect}from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import GemImg from '../../../assets/img/insegna-GEM-Casalpusterlengo-pannelli-solary-green-energy.jpg'
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  wrapper: {
    position : 'absolute',
    top : 0,
    left : 0,
    height : '100%',
    width : '100%',
    display : 'flex',
    justifyContent : 'center',
    alignItems : 'center',
  },
}));

export default function ImgSlider(props){
  const classes = useStyles(props);
  const {image = GemImg , slang = ''} = props;

  return(
    <div className={classes.wrapper} style={{
      width : '100%',
      backgroundImage : `url(${image})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize : 'cover'
    }}>
      {/*<img alt='' src={image} style={{width : 'auto' , height : '100%'}} {...props} />*/}
      <Typography style={{
        fontSize: '5rem',
        wordBreak: 'break-word',
        textAlign: 'center',
        margin: '0px 10%',
        color: 'rgb(255, 255, 255)',
        textShadow: '1px 1px #000',
        fontWeight: '700'
      }}>
        {slang}
      </Typography>
    </div>
  )
}
