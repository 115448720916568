import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import GemLogo from '../../../assets/logos/logo-gem-green-energy.png'

const useStyles = makeStyles(theme => ({
  wrapper: {
    flex : 1,
    display : 'flex',
    height : '100vh',
    justifyContent : 'center',
    alignItems : 'center'
  },
}));

export default function LogoSlide(props) {
  const classes = useStyles(props);
  const {logo = GemLogo} = props;


  return(
    <div className={classes.wrapper}>
      <img alt='' src={logo} style={{width : '30%'}} {...props} />
    </div>
  )
}
