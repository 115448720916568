import React from 'react';
import {TitleSubtitleLayout} from "@cobrains/react-kit";
import Users from "../../views/application/users/UserCrud";
import Plants from "../../views/application/plants/PlantCrud";
import Groups from "../../views/application/group/GroupCrud";
import {ErrorsRoutes} from "../../routing/routes/ErrorsRoutes";
import {DashboardRoutes} from "../../routing/routes/DashboardRoutes";
import Dashboard from '../../views/application/dashboard/Dashboard';
import Dashboards from '../../views/application/dashboardgroup/DashboardCrud';
import CustomDashboardCrud from '../../views/application/dashboardcustom/CustomDashboardCrud';
import TokensPage from '../../views/application/token/TokensPage';
import Logout from '../../views/auth/Logout';

export const SuperAdminRoutes = [
    {
      path: '/',
      name: 'Dashboard',
      component: Dashboard,
      // layout: (props) => (
      //   <TitleSubtitleLayout title="Console di amministrazione" subtitle="Benvenuto">
      //     {props.children}
      //   </TitleSubtitleLayout>
      // ),
      seo:{
        title: 'Users',
        description: 'This is a example description for SEO Provider component',
      },
      exact: true,
      private:true
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
      layout: (props) => (
        <TitleSubtitleLayout title="Utenti" subtitle="Gestisci gli utenti">
          {props.children}
        </TitleSubtitleLayout>
      ),
      seo:{
        title: 'Users',
        description: 'This is a example description for SEO Provider component',
      },
        exact: true,
        private:true
    },
    {
        path: '/plants',
        name: 'Impianti',
        component: Plants,
      layout: (props) => (
        <TitleSubtitleLayout title="Impianti" subtitle="Gestisci gli impianti">
          {props.children}
        </TitleSubtitleLayout>
      ),
        seo:{
          title: 'Plants',
          description: 'This is a example description for SEO Provider component',
        },
        exact: true,
        private:true
    },
  {
    path: '/groups',
    name: 'Gruppi',
    component: Groups,
    layout: (props) => (
      <TitleSubtitleLayout title="Gruppi" subtitle="Gestisci i gruppi">
        {props.children}
      </TitleSubtitleLayout>
    ),
    seo:{
      title: 'Groups',
      description: 'This is a example description for SEO Provider component',
    },
    exact: true,
    private:true
  },
  {
    path: '/dashboards',
    name: 'Dashboards',
    component: Dashboards,
    seo:{
      title: 'Dashboards',
      description: 'This is a example description for SEO Provider component',
    },
    exact: true,
    private:true
  },
  {
    path: '/visual',
    name: 'Dashboards',
    component: CustomDashboardCrud,
    seo:{
      title: 'Dashboards',
      description: 'This is a example description for SEO Provider component',
    },
    exact: true,
    private:true
  },
  {
    path : '/logout',
    name: 'Logout',
    component: Logout,
    exact: true,
  },
  {
    path : '/cerca',
    name: 'Tokens',
    component: () => (
      <>
        <TokensPage />
      </>
    ),
    exact: true,
  },
    ...ErrorsRoutes,
  ...DashboardRoutes,
];
