import React from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';

export default function ColorPIcker(props) {
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
  const {color = '#ffffff'} = props

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker )
  };

  const handleClose = () => {
    setDisplayColorPicker( false)
  };

    const styles = reactCSS({
      'default': {
        color: {
          width: 20,
          height: 20,
          border: '1px solid #000000',
          background: color,
          cursor: 'pointer',
          marginRight: 36
        },
        popover: {
          top : '50%',
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div style={{display: 'flex', flexDirection: 'row' ,padding: '12px 0px'}}>
          <div style={styles.color} onClick={handleClick}/>
        <Typography >Colore principale: {color}</Typography>
        { displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={handleClose} />
            <SketchPicker color={color} onChangeComplete={props.onChangeComplete} />
          </div>
) : null }

      </div>
    )
}

