import React from 'react';

export function getItems(props){
  return [{
      key: 'add',
      label: 'Aggiungi',
      component: 'button',
      variant: 'contained',
      color: 'primary',
    icon: (defaultStyle) => <i className="icon-aggiungi" style={{ ...defaultStyle }} />,
      func : () => {
        props.onSetDialog('add', true);
        props.onPreparePlant()
      },
      style:{borderRadius:40, marginRight:8}
    },
    {
      key: 'edit',
      label: 'Modifica',
      component: 'button',
      variant: 'outlined',
      icon: (defaultStyle) => <i className="icon-modifica" style={{ ...defaultStyle }} />,
      disabled: (props.plants.selected.length !== 1),
      func : () => {
        props.onSetDialog('edit', true);
        props.onPreparePlant(props.plants.selected[0].id)
      },
      style:{borderRadius:40, marginRight:8}
    },
    {
      key: 'delete',
      component: 'button',
      label: 'Elimina',
      variant: 'outlined',
      icon: (defaultStyle) => <i className="icon-elimina" style={{ ...defaultStyle }} />,
      disabled: (props.plants.selected.length < 1),
      func : () => props.onSetDialog('delete', true),
      style:{borderRadius:40, marginRight:8}
    },
    {
      key: 'api',
      component: 'button',
      label: 'Manage Api',
      variant: 'outlined',
      icon: (defaultStyle) => <i className="icon-contratti" style={{ ...defaultStyle }} />,
      disabled: (props.plants.selected.length !== 1),
      func : () => {
        props.onPrepareToken(props.plants.selected[0].id);
        props.onSetDialog('api', true);
      },
      style:{borderRadius:40, marginRight:8}
    }
  ]
}
