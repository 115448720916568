import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import i18n from 'i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DataSideBarMenu  from './components/DataSideBarMenu';
import DataDashboard from './components/DataDashboard';
import { changeLocale } from '../../../translations/i18n';


const useStyles = makeStyles(theme => ({
  wrapper: {
    display : 'flex',
    alignItems : 'center',
    height : '100vh'
  },
  dataContainer : {
    flex  : 1 ,
    display : 'flex' ,
    justifyContent : 'center',
    backgroundColor : '#ededed',
    height : '100%',
  }
}));

let timeId;

export default function DataSlide(props) {
  const classes = useStyles(props);
  const {data = [] , sliders} = props
  const {language = 'en'} = sliders
  const [index , setIndex] = useState(0);


  function setSlideInterval() {
    timeId = setInterval(() => timer(), 10000);
  }

  useEffect(() => {
    props.setPlay(false);
    setSlideInterval();
  }, []);


  function exit() {
    props.changeSlide();
    setIndex(0);
    if (sliders.image || sliders.logo || sliders.video)
    clearInterval(timeId);
  }

  function timer(){
    setIndex(s =>    s + 1);
  }
  return(
    <>
      <Helmet>
        <style type="text/css">
          {`
        @media screen and (min-width: 320px) and (max-width: 769px) and (orientation: portrait) {
        .wrapper{
        width: 100vh !important;
        height: 100vw !important;
          }
        }
        `}
        </style>
      </Helmet>
      <div className={`wrapper ${classes.wrapper}`}>
        <DataSideBarMenu
          mock={props.mock}
          data={data}
          index={index}
          sliders={sliders}
          language={language}
          exit={() => exit()}
          changeSlide={() => props.changeSlide()}
        />

        <div className={classes.dataContainer}>
          <DataDashboard
            index={index}
            sliders={sliders}
            language={language}
            mock={props.mock}
            data={(data[index]) ? data[index] : data[0]}
          />
        </div>
      </div>
    </>
  )
}
