import React from 'react';

export default function Cloud(props){
  const {color = 'rgb(0, 0, 0)'} = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Livello_1" data-name="Livello 1" viewBox="0 0 96 96"><title>cloud</title><g id="Path_76-3" data-name="Path 76-3">
      <path fill={color} d="M72.39,70.14H19.87a7.74,7.74,0,0,1-5.26-2.61c-1.45-1.56-3.17-4.63-2.79-10C12.3,50.68,18,47.36,22,46.25c.1-2.25.81-6.74,4.71-9.25a10.63,10.63,0,0,1,5.83-1.55,14.09,14.09,0,0,1,2.59.23.47.47,0,0,0,0-.1c1.75-3.46,8.11-9.08,13.63-9.53,1.16-.1,2.39-.19,3.55-.19,3.06,0,5.71.55,9.47,3.24A16,16,0,0,1,68.8,42.42h.65a16.8,16.8,0,0,1,7.4,1.87c8.93,4.47,7.43,14.44,7,16.39A11.38,11.38,0,0,1,72.39,70.14Zm-52.26-5H72.39A6.45,6.45,0,0,0,79,59.69c1.48-7.38-3-10.25-4.41-10.95a11.72,11.72,0,0,0-5.22-1.33,6.07,6.07,0,0,0-1.27.12l-1.6.44a2.5,2.5,0,0,1-3.12-2.88l.33-1.7A10.72,10.72,0,0,0,59,33.16c-2.7-1.94-4.33-2.3-6.55-2.3-1,0-2.09.08-3.15.17-3.4.28-8.39,4.48-9.6,6.87a9.55,9.55,0,0,1-1.06,1.61l-.45.56a2.51,2.51,0,0,1-2.63.84l-.68-.2a8.46,8.46,0,0,0-2.27-.26h-.1a5.71,5.71,0,0,0-3.07.8C26.84,42.89,27,46.92,27,47.1l.08,1a2.5,2.5,0,0,1-2,2.66l-.95.17c-.27,0-6.91,1.36-7.3,7-.2,2.81.34,5.06,1.52,6.32A2.67,2.67,0,0,0,20.13,65.13Z"/></g></svg>
  )
}

