// eslint-disable-next-line import/no-cycle
import Environments from "../enviroments/Environments";
import { translate } from '../translations/i18n';

const config = window;
const pjson = require('../../package.json')

class Settings {

  static APP_NAME = 'Dashboard GEM Green Energy';
  static BASE_URL = (config.REACT_APP_API_BASE_URL) ? config.REACT_APP_API_BASE_URL : process.env.REACT_APP_API_BASE_URL;
  static BEARER_KEY = (config.REACT_APP_BEARER_KEY) ? config.REACT_APP_BEARER_KEY : process.env.REACT_APP_BEARER_KEY;
  static STATE_KEY_LOCAL_STORAGE = `${pjson.name}:state`;

  static REACT_KIT_SETTINGS = {
    APP_NAME: this.APP_NAME,
    STATE_KEY_LOCAL_STORAGE: this.STATE_KEY_LOCAL_STORAGE,
    BASE_URL: this.BASE_URL,
    BEARER_KEY: this.BEARER_KEY,
    ENVIRONMENTS: Environments,
    TRANSLATE: translate,
  }
}

export default Settings
