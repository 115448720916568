import React, { useEffect, useState } from 'react';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import {AccountCircle, Lock, Visibility, VisibilityOff} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import {compose} from "redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  apiCall,
  setEnvironmentWithUserType,
  loginSuccess,
  apiMessage
} from "@cobrains/react-kit"
import FullScreenLogoLoader from "../../../components/loaders/FullScreenLogoLoader";
import Logo from  '../../../assets/logos/logo-gem-green-energy.png'


const useStyles = makeStyles(theme => ({
    container:{
        display:'flex',
        width:'80%',
        background:'#fff',
        height:'80%',
        padding:20,
        borderRadius:12,
        boxShadow: theme.shadows[4]
    },
    title:{
        marginBottom:40,
        textAlign:'center'
    },
    subtitle:{
        marginBottom:40,
        textAlign:'center',
    },
    formContainer:{
        width:'100%',
        display:'flex',
        flexDirection:'column',
        alignItems:'center'
    },
    textField:{
      backgroundColor : 'rgba(255,255,255,0.9)',
        marginTop:20,
        width:'80%'
    },
    button:{
        width:'80%',
        marginTop:20
    },
  loaderBtn:{
    color:theme.palette.primary.contrastText
  },
  img:{
    width:300,
    [theme.breakpoints.down('sm')]:{
      width: '100%',
    },
  }
}));

function SignInForm(props){
    const classes = useStyles(props);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loader, setLoader] = useState(false);
    const [loaderFullScreen, setLoaderFullScreen] = useState(false);
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [redirect, setRedirect] = useState();
    const {auth} = props;



    function onSignIn(e) {
        e.preventDefault();
        setLoader(true);

        const body = {username, password};
        props.onApiCall({url: 'user/auth', data: body, config: {authorization: false, baseUrl: true}}).then(
          res => {
            setTimeout(() => {
              props.onFetchLoginSuccess(res.data);
              props.onSetEnvWithUserType(res.data.type)
            },220);
          },
          err => {
            setTimeout(() => {
              setLoader(false);
            },400);
            setLoaderFullScreen(false);
          })
    }



    return(
      (loaderFullScreen) ? (
        <FullScreenLogoLoader
          logo={Logo}
          circularProgressColor="primary"
          message="Recupero delle informazioni in corso..."
          background="#fff"
        />
      )
        : (
          <>
            <img src={Logo} alt='' style={{marginBottom : 20}} className={classes.img}/>
            <Typography variant="h3" className={classes.title}>{props.title}</Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>{props.subtitle}</Typography>
            <form onSubmit={(e) => onSignIn(e)} className={classes.formContainer}>
              <TextField
                id='username'
                label='Username'
                variant="outlined"
                className={classes.textField}
                value={username.toLowerCase()}
                onChange={(e) => setUsername(e.target.value)}
                disabled={loader}
                required
                InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <i className="icon-utent1" style={{ fontSize: 20 }} />
                          </InputAdornment>
                        ),
                    }}
              />
              <TextField
                id='password'
                label='Password'
                variant="outlined"
                autoComplete={(!props.autocomplete)?'new-password':null}
                className={classes.textField}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={(!passwordVisibility)?'password':'text'}
                disabled={loader}
                required
                InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <i className="icon-password" style={{ fontSize: 20 }} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setPasswordVisibility(!passwordVisibility)}
                              onMouseDown={(e) => e.preventDefault()}
                            >
                              {(passwordVisibility) ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                    }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={auth.pending || username === '' || password === ''}
                className={classes.button}
              >
                {(!loader) ?
                        'Accedi'
                        :
                        <CircularProgress size={16} className={classes.loaderBtn} />}
              </Button>
            </form>
            {/* <Typography variant="body2" style={{marginTop:20}}>Password dimenticata?</Typography> */}
          </>
      )

    )
}

const mapStateToProps = state => ({
    auth: state.auth,
})

const mapActionsToProps = {
    onApiCall: apiCall,
    onApiMessage: apiMessage,
    onFetchLoginSuccess: loginSuccess,
    onSetEnvWithUserType : setEnvironmentWithUserType,
}


export default compose(connect(mapStateToProps, mapActionsToProps))(SignInForm);
