import React, {
  Component
} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

class FullScreenLogoLoader extends Component {
  render() {
    const {
      classes
    } = this.props
    const {
      logo,
      background,
      message,
      circularProgressColor = '#222'
    } = this.props;

    return (
      <div>
        <div className={classes.root} style={{background: background}}>
          <img alt='logo' src={logo} width={240} /><br />
          <Typography className={classes.message}>{message}</Typography>

          <CircularProgress size={20} color={circularProgressColor} />
        </div>
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    zIndex: 99999,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    overflowY: 'hidden',
    overflowX: 'hidden'
  },
  centerIcon: {
    display: 'flex'
  },
  linearProgress: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0
  },
  message: {
    color: '#fff',
    marginBottom: 8
  }
})

export default withStyles(styles)(FullScreenLogoLoader)
