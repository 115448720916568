import React from 'react';

export default function solarPannel(props){
  const {color = 'rgb(0, 0, 0)'} = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Livello_1" data-name="Livello 1"
         viewBox="0 0 96 96">
      <defs>
      </defs>
      <title>Tavola disegno 69 copia 2</title>
      <path className="cls-1"
            fill={color}
            d="M83.94,55.68s0-.11,0-.16a1.37,1.37,0,0,0,0-.28.13.13,0,0,0,0-.06l-9-31.24a2,2,0,0,0-1.92-1.44H23.44a2,2,0,0,0-1.91,1.42L12.14,55.16a.17.17,0,0,1,0,.07,2.33,2.33,0,0,0-.05.28c0,.05,0,.1,0,.16a.13.13,0,0,0,0,.06V63.1a2,2,0,0,0,2,2H42.75v4.4H38.29a2,2,0,1,0,0,4h20a2,2,0,1,0,0-4H54.14V65.1h27.8a2,2,0,0,0,2-2V55.68Zm-50-2,1-7.14H46v7.14Zm-17.15,0,2.15-7.14H30.82l-1,7.14Zm5.37-17.87H32.26l-.9,6.73H20.1ZM46,26.5v5.36H36.83l.72-5.36Zm13.14,0,.59,5.36H50V26.5ZM76.06,42.59H64.93l-.74-6.73h9.92Zm-15.15,0H50V35.86H60.17ZM46,35.86v6.73H35.39l.91-6.73Zm4,10.73H61.35l.78,7.14H50Zm16.16,7.14-.79-7.14H77.22l2.06,7.14ZM73,31.86H63.75l-.59-5.36h8.25Zm-48-5.36h8.59l-.72,5.36H23.32Zm25.21,43H46.75V65.1h3.39Zm2-8.4H16.06V57.73H79.94V61.1Z"/>
    </svg>
  )
}
