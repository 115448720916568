
const crudFields = [
  {
    key: 'id ',
    visible: false
  },
  {
    key: 'diagnostics',
    label: 'Token diagnostica',
    required : false
  },
  {
    key: 'plant_status',
    label: 'Token Grafico',
    required : false,
  },
  {
    key: 'weather',
    label: 'Token Meteo',
    required : false,
  },
  {
    key: 'kw_powers_ac',
    label: 'Token Meteo',
    required : false,
    visible: false
  },
  {
    key: 'inverter_states',
    label: 'Token Meteo',
    required : false,
    visible: false
  },
  {
    key: 'inverter_powers',
    label: 'Token Meteo',
    required : false,
    visible: false
  },
  {
    key: 'weather',
    label: 'Token Meteo',
    required : false,
    visible: false
  }
];

export default crudFields
