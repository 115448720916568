import React from 'react';

export default function Fog(props){
  const {color = 'rgb(0, 0, 0)'} = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Livello_1" data-name="Livello 1" viewBox="0 0 96 96">
      <title>fog</title>
      <g id="Path_119-4" data-name="Path 119-4">
        <path fill={color} d="M62.4,34.79a61.81,61.81,0,0,1-14.15-1.92A58.51,58.51,0,0,0,34.78,31h-.06c-10.18,0-16.1,1.75-16.15,1.77A2.5,2.5,0,0,1,17.1,28c.27-.08,6.62-2,17.62-2h.06a63.7,63.7,0,0,1,14.55,2,56.89,56.89,0,0,0,13.22,1.8c9.81,0,14.63-1.71,14.68-1.73A2.5,2.5,0,0,1,79,32.76c-.22.08-5.55,2-16.39,2Z" />
      </g>
      <g id="Path_119-5" data-name="Path 119-5">
        <path fill={color} d="M62.4,46.1a61.89,61.89,0,0,1-14.15-1.92,59,59,0,0,0-13.47-1.85h-.06c-10.18,0-16.1,1.76-16.15,1.78a2.5,2.5,0,0,1-1.47-4.78c.27-.08,6.62-2,17.62-2h.06a63.66,63.66,0,0,1,14.55,2,55.33,55.33,0,0,0,13.22,1.8c9.81,0,14.63-1.71,14.68-1.73A2.5,2.5,0,1,1,79,44.06c-.22.09-5.55,2-16.39,2Z" />
      </g>
      <g id="Path_119-6" data-name="Path 119-6">
        <path fill={color} d="M62.4,57.41a62.37,62.37,0,0,1-14.15-1.92,58.49,58.49,0,0,0-13.47-1.85h-.06c-10.18,0-16.1,1.76-16.15,1.78a2.51,2.51,0,0,1-3.12-1.67,2.48,2.48,0,0,1,1.65-3.11c.27-.09,6.62-2,17.62-2h.06a63.11,63.11,0,0,1,14.55,2,57.59,57.59,0,0,0,13.07,1.8h.15c9.81,0,14.63-1.71,14.68-1.73A2.5,2.5,0,1,1,79,55.37c-.22.08-5.55,2-16.39,2Z" />
      </g>
      <g id="Path_119-7" data-name="Path 119-7">
        <path fill={color} d="M62.4,68.72a61.7,61.7,0,0,1-14.15-1.93A59.06,59.06,0,0,0,34.78,65h-.06c-10.18,0-16.1,1.76-16.15,1.77a2.5,2.5,0,0,1-1.47-4.78c.27-.08,6.62-2,17.62-2h.06a63.7,63.7,0,0,1,14.55,2,55.9,55.9,0,0,0,13.22,1.81c9.81,0,14.63-1.72,14.68-1.73A2.5,2.5,0,1,1,79,66.68c-.22.08-5.55,2-16.39,2Z" />
      </g>
    </svg>
  )
}
