import React, {useState , useEffect} from 'react';
import {Paper , Typography}from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReactEcharts from 'echarts-for-react';
import option from '../charts/ChartOptions'
import DateText from './DateText'
import CircularGraph from './CircularGraph'
import ProductionGraph from './ProductionGraph'
import Co2Graph from './Co2Graph'
import CurrentPowerGraph from './CurrentPowerGraph'
import { translate } from '../../../../translations/i18n';
import sun from '../../../../assets/icons/sun'
import suncloud from '../../../../assets/icons/suncloud'
import cloud from '../../../../assets/icons/cloud'
import thunder from '../../../../assets/icons/thunderstrorm'
import rain from '../../../../assets/icons/rain'
import snow from '../../../../assets/icons/snow'
import fog from '../../../../assets/icons/fog'
import image from '../../../../assets/img/datadashboard.jpg'

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding : '40px',
    [theme.breakpoints.down('md')]:{
      padding : '20px',
    },
    [theme.breakpoints.down('sm')]:{
    padding : 0
    },
    width : '100%',
    // height : '100%',
    display : 'flex',
    flexDirection : 'column'
  },
  titleContainer:{
    height : 80,
    padding  : '0px 20px 20px 20px',
    alignItems : 'flex-end',
    display : 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]:{
      display : 'none'
    }
    },
  text : {
    fontSize : 28,
    fontWeight : 'bold',
    [theme.breakpoints.down('md')]:{
      fontSize : 18,
    },
    [theme.breakpoints.down('sm')]:{
      fontSize : 20
    }
  },
  tileText : {
    flex : 1,
    fontSize : 28,
    fontWeight : 700,
    [theme.breakpoints.down('md')]:{
      fontSize : 22,
    },
    [theme.breakpoints.down('sm')]:{
      fontSize : 16,
    }
  },
  graphContainer : {
    display : 'flex' ,
    flexDirection : 'column'  ,
    width : '100%',
    height : '100%'
  },
  bigGraph : {
    boxShadow: '0px 0px 15px #00000029',
    margin  : 20 ,
    flex : 1 ,
    height: '60%',
    display : 'flex',
    flexDirection: 'column',
    borderRadius : 16,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]:{
     margin : 10,
    },
      [theme.breakpoints.down('sm')]:{
      margin : 0,
        background: 'rgba(255,255,255,0.85)',
        borderRadius : 0,
        boxShadow: 'unset',
        border : '1px solid #ecedec'
      },
    },
  smallGraphContainer:{
    display : 'flex',
    height: '44%',
  },
  smallGraph:{
    boxShadow: '0px 0px 15px #00000029',
    borderRadius : 16,
    flex : 1 ,
    margin  : 20,
    [theme.breakpoints.down('md')]:{
    margin  : 10,
    },
    [theme.breakpoints.down('sm')]:{
      margin : 0,
      borderRadius : 0,
      boxShadow: 'unset',
      background: 'rgba(255,255,255,0.85)',
      border : '1px solid #ecedec'
    },
    display : 'flex',
    justifyContent : 'center',
    alignItems : 'center'
  },
  headerContainer:{
  padding : '24px 40px 0px 40px' ,
  display : 'flex',
    [theme.breakpoints.down('sm')]:{
      padding : '20px 20px 0px 20px' ,
    },
  },
  unitText:{
    fontSize : 40 ,
    [theme.breakpoints.down('sm')]:{
      fontSize : '18px !important'
    },
    fontWeight : 'bold'
  },
  descText: {
    fontSize: 12,
    color: '#888888',
  },
}));

function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => ++value); // update the state to force render
}

export default function DataDashboard(props) {
  const classes = useStyles(props);
  const { mock , data = [] , sliders , index = 0} = props;
  let totalProd = 0;

  if (data.chart)
  Object.keys(data.chart).map(
    (item) => totalProd = (data.chart[item])  ? totalProd +  data.chart[item] :  totalProd
  );

  const {diagnostic = {max_power : 0 , power_sum : 0, co2:0 , todayPaclast:0, total_daily_production:0 } } = data;
  const chartNumber = (data && data.chart && Object.entries(data.chart).filter(([item , value]) => value !== 0).slice(-1)[0]) ? Object.entries(data.chart).filter(([item , value]) => value !== 0).slice(-1)[0][1] : 0;

  const forceUpdate = useForceUpdate();

  useEffect(() => {
      forceUpdate()
  },[props.index]);


  function getForecast(fore) {
    switch(fore){
      case '01d': return sun(sliders.color);
      case '02d':  return suncloud(sliders.color);
      case '03d':  return cloud(sliders.color);
      case '04d':  return cloud(sliders.color);
      case '09d':  return rain(sliders.color);
      case '10d':  return rain(sliders.color);
      case '11d':  return thunder(sliders.color);
      case '13d':  return snow(sliders.color);
      case '50d':  return fog(sliders.color);
      default: return sun(sliders.color);
    }

  }


  return(
    <div className={classes.wrapper} style={(sliders.background_image) ? {backgroundImage : `url(${sliders.background_image})`, backgroundSize : 'cover'} : {}}>
      <div className={classes.titleContainer}>
        <Typography className={classes.tileText} style={{color : sliders.color}}>

          {translate('data.monitoring_system',{lgn : props.language})}

        </Typography>

        <DateText lng={props.language} />

        {(data.weather) && (
          <div style={{
            minWidth: '6%',
            padding: 4,
            marginLeft: 16
          }}
          >
            {getForecast(data.weather)}
          </div>
        )}
      </div>
      <div className={classes.graphContainer}>
        <Paper className={classes.bigGraph}>
          <div style={{padding : '24px 40px 0px 40px' , display : 'flex'}} className={classes.headerContainer}>
            <Typography className={classes.text} style={{flex : 1}}>
              {translate('data.total_daily_power',{lgn : props.language})}
              <Typography className={classes.descText}>
                kW
              </Typography>
            </Typography>

            <Typography className={classes.unitText} style={(window.innerWidth <= 1440) ? {fontSize : 28,} : {}}>
              {Math.round(diagnostic.todayPaclast).toLocaleString(props.language, {maximumFractionDigits:2})}
              {' '}kW
              <Typography className={classes.descText} style={{textAlign:'right'}}>
                {translate('data.current_power', { lgn: props.language })}
              </Typography>
            </Typography>
          </div>
          <ReactEcharts
            option={option({color : sliders.color , dataGraph : (data.chart) ? data.chart : [] , is_group : data.is_group, index})}
            style={{
              height: '85%',
              width: '100%',
            }}
          />
        </Paper>
        <div className={classes.smallGraphContainer}>

          <Paper className={classes.smallGraph}>

            <CurrentPowerGraph
              value={(chartNumber !== 0) ? Math.round(diagnostic.total_daily_production) : 0}
              color={sliders.color}
              language={props.language}
              valueText={(diagnostic && diagnostic.total_daily_production) ? Math.round(diagnostic.total_daily_production).toLocaleString(props.language, {maximumFractionDigits:2}) : 0}
            />
          </Paper>

          <Paper className={classes.smallGraph}>
            <Co2Graph color={sliders.color} value={diagnostic.co2} language={props.language} />
          </Paper>

          <Paper className={classes.smallGraph}>
            <ProductionGraph
              color={sliders.color}
              value={Math.round(diagnostic.power_sum).toLocaleString(props.language, {maximumFractionDigits:2})}
              language={props.language}
            />
          </Paper>
        </div>
      </div>
    </div>
  )
}
