import React from 'react';

export default function SunCloud(props){
  const {color = 'rgb(0, 0, 0)'} = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Livello_1" data-name="Livello 1" viewBox="0 0 96 96"><title>cloud sun</title>
      <path fill={color} d="M76.4,45.23a13.65,13.65,0,0,0-25.75-6.34A12.93,12.93,0,0,0,43.55,37c-1.09,0-2.23.09-3.22.17-5,.4-10.78,5.47-12.42,8.64a13.24,13.24,0,0,0-2.17-.18h0A9.68,9.68,0,0,0,20.32,47C17,49.17,16.07,53.09,16,55.37c-3.68,1.06-8.74,4.11-9.18,10.29-.35,5,1.24,7.78,2.57,9.21a7.18,7.18,0,0,0,4.91,2.43H61.56a10.5,10.5,0,0,0,10.6-8.72c.28-1.4,1.15-7.24-2.35-11.7A13.64,13.64,0,0,0,76.4,45.23ZM67.25,67.61a5.48,5.48,0,0,1-5.69,4.7H14.51a2.17,2.17,0,0,1-1.45-.78c-1-1.09-1.48-3-1.3-5.51.34-4.85,6.12-6,6.37-6l.85-.16a2.5,2.5,0,0,0,2-2.65L21,56.32h0s-.2-3.63,2-5.06a4.64,4.64,0,0,1,2.62-.68h.1a8.79,8.79,0,0,1,2,.23l.62.18a2.51,2.51,0,0,0,2.63-.84l.41-.5a10.8,10.8,0,0,0,1-1.5c1.06-2.11,5.55-5.82,8.44-6,.88-.07,1.9-.15,2.81-.15a8.6,8.6,0,0,1,5.76,2,9.43,9.43,0,0,1,4.18,9l-.3,1.52a2.51,2.51,0,0,0,3.12,2.89l1.43-.4a4.94,4.94,0,0,1,1.08-.1A10.32,10.32,0,0,1,63.41,58C68.27,60.45,67.58,66,67.25,67.61ZM65.44,53.46a15.32,15.32,0,0,0-6.54-1.61h-.33a14.52,14.52,0,0,0-3.89-9.78A8.66,8.66,0,1,1,65.44,53.46Z"/>
      <path fill={color} d="M47.21,32.73a2,2,0,0,0,2.83,0,2,2,0,0,0,0-2.82l-3.58-3.58a2,2,0,0,0-2.83,2.82Z"/>
      <path fill={color} d="M62.6,28.25a2,2,0,0,0,2-2V21.19a2,2,0,1,0-4,0v5.06A2,2,0,0,0,62.6,28.25Z"/>
      <path fill={color} d="M76.65,33.12a2,2,0,0,0,1.41-.58L81.64,29a2,2,0,0,0-2.83-2.83l-3.57,3.58a2,2,0,0,0,1.41,3.41Z"/>
      <path fill={color} d="M86.78,43.09H81.72a2,2,0,0,0,0,4h5.06a2,2,0,0,0,0-4Z"/>
      <path fill={color} d="M78.26,57.73a2,2,0,1,0-2.83,2.83L79,64.14a2,2,0,1,0,2.83-2.83Z"/>
    </svg>
  )
}

