import { withStyles } from '@material-ui/core/styles'
import { compose } from 'redux'
import {
  Search,
  FormGenerator,
  apiCall,
  FunctionsToolbar,
  ConfirmDialog,
  getDataFilter,
  TableGenerator,
  getDataPagination,
  addMessage
} from '@cobrains/react-kit';
import {connect} from 'react-redux'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import CrudFields from './settings/crud/CrudFields'
import { translate } from '../../../translations/i18n';
import { getItems } from './settings/functions-toolbar/Items';
import TableFields from './settings/table/TableFields';
import {
  getGroups,
  editGroup,
  createGroup,
  deleteGroup,
  groupSelected,
  setDialog,
  groupResetSelected,
  prepareGroupTable,
  prepareGroup,
  resetPrepareGroup,
  addGroupQueryFilter,
  resetGroupsData,
} from '../../../redux/pages/groups-page-redux';
import ShareDialog from '../dashboardgroup/components/ShareDialog';

const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    padding: 20
  }
}));

function GroupCrud (props){
  const classes = useStyles(props);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');

  useEffect(() => {
    props.onResetData();
    props.onResetSelected();
    props.onPrepareGroupTable();
    props.onGetGroups()
  }, []);


  return (
    <div className={classes.root}>
      <div style={{display: 'flex'}}>
        {(props.user.type !== 'CA') && (<FunctionsToolbar items={getItems(props)} style={{ flex: 1 }} />)}
        <Search
          buttonStyle={{borderTopRightRadius : 18 , borderBottomRightRadius : 18 , boxShadow : 'unset'}}
          data={props.groups.data.groups} // data list
          keySearch={['first_name', 'last_name', 'username']} // key that have to search
          placeholder="Ricerca per nome, cognome o username" // placeholder
          onConfirm={(value, search) => {
            props.onResetData();
            getDataFilter(props.onGetGroups, {props, search})
          }} // action after search
          style={{flex: 1, borderRadius: 40}}
        />
      </div>



      {(props.prepare.fields.length > 0) && (
      <FormGenerator
        buttonVariant='contained'
        fields={[props.prepare.fields, CrudFields]}
        data={{}}
        isPending={false}
        layoutStyle={{    minWidth:'calc(49% - 12px)'}}
        onSubmit={(group) => {
          props.onCreateGroup(group);
        }}
        onCancel={() => {
          props.onSetDialog('add', false);
          props.onResetPrepareGroup();
        }}
        translate={translate}
        errors={props.errors.create.data.messages}
        dialog
        dialogProps={{
          onClose: () => {
            props.onSetDialog('add', false);
            props.onResetPrepareGroup();
          },
          open:props.dialogs.add,
          title:'Aggiungi un nuovo gruppo',
          subtitle:'Compila i campi per aggiungere un nuovo gruppo'
        }}
        actionsProps={{
          cancelProps : {confirm: true}
        }}
      />
    )}

      {(props.prepare.fields.length > 0) && (
      <FormGenerator
        buttonVariant='contained'
        fields={[props.prepare.fields, CrudFields]}
        data={props.groups.selected}
        isPending={false}
        layoutStyle={{    minWidth:'calc(49% - 12px)'}}
        onSubmit={(group) => {
          props.onEditGroup(group , props.groups.selected[0].id)
        }}
        onCancel={() => {
          props.onSetDialog('edit', false)
          props.onResetPrepareGroup();
        }}
        errors={props.errors.edit.data.messages}
        dialog
        dialogProps={{
          onClose: () => {
            props.onSetDialog('edit', false);
            props.onResetPrepareGroup();
          },
          open:props.dialogs.edit,
          title:'Modifica un gruppo',
          subtitle: `Compila i campi per modificare il gruppo`
        }}
        actionsProps={{
          cancelProps : {confirm: true}
        }}
      />
    )}

      <Paper style={{overflow: 'auto', marginTop:20}}>
        <TableGenerator
          labelRowsPerPage="Righe per pagina"
          loader={props.loaders.get}
          data={props.groups.data.content.items}
          rowsPerPage={25}
          // maxSelectableElements={1}
          onOrderChange={(order , orderBy) => console.log('order',order , orderBy)}
          selectedElements={props.groups.selected}
          onChangeSelected={(data) => props.onGroupSelected(data)}
          fields={[props.groups.table.fields,TableFields]}
          customColumn={[{
            keyName : 'share',
            cellProps : {padding : 'checkbox'},
            component : (item)  => (
              <ButtonBase
                style={{padding: 9, borderRadius: '50%'}}
                onClick={() => {
                setName(item.name);
                setOpen(true)
              }}
              >
                <Icon>
                share
                </Icon>
              </ButtonBase>
)
          }]}
          onChangePage={(page, limit, offset, filters) => getDataPagination(props.onGetGroups, {props, limit, offset, filters})}
          onFiltersChange={(page, limit, offset, filters) => getDataFilter(props.onGetGroups, {props, limit, offset, filters})}
        />
      </Paper>


      <ShareDialog
        open={open}
        onCancel={() => setOpen(false)}
        name={(name) || ''}
        title="Link per la condivisione"
        onCopy={() => props.onAddMessage('Link copiato negli appunti ','success')}
      />

      {(props.dialogs.delete) && (
        <ConfirmDialog
          open={props.dialogs.delete}
          title="Eliminazione gruppo"
          message={"Sei sicuro di voler eliminare il gruppo?"}
          onCancel={() => props.onSetDialog('delete', false)}
          onSubmit={() => props.groups.selected.map((group , index) => {
            props.onDeleteGroup(group.id);
            if (props.groups.selected.length === index + 1)
              props.onResetSelected()
          })}
          cancelProps={{ label: 'Annulla'}}
          submitProps={{ label: 'Elimina'}}
        />
      )}
    </div>
    )
}


const mapStateToProps = state => ({
  user: state.auth.user,
  groups: state.groupsPage,
  dialogs: state.groupsPage.dialogs,
  loaders: state.groupsPage.loaders,
  errors: state.groupsPage.errors,
  prepare: state.groupsPage.prepare,
  filters: state.groupsPage.filters
});

const mapActionsToProps = {
  onApiCall : apiCall,
  onGetGroups: getGroups,
  onGroupSelected: groupSelected,
  onSetDialog: setDialog,
  onCreateGroup: createGroup,
  onEditGroup: editGroup,
  onDeleteGroup: deleteGroup,
  onResetSelected: groupResetSelected,
  onPrepareGroup: prepareGroup,
  onResetPrepareGroup: resetPrepareGroup,
  onPrepareGroupTable: prepareGroupTable,
  /* Common */
  onAddQueryFilter: addGroupQueryFilter,
  onResetData: resetGroupsData,
  onAddMessage: addMessage,
};

export default compose(
    withStyles({withTheme: true}),
  connect(mapStateToProps , mapActionsToProps)
)(GroupCrud)
