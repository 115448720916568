
import React from 'react';

export default function Snow(props){
  const {color = 'rgb(0, 0, 0)'} = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Livello_1" data-name="Livello 1" viewBox="0 0 96 96">
      <title>snow</title>
      <path fill={color} d="M76.85,35.62a16.76,16.76,0,0,0-7.4-1.87H68.8a16,16,0,0,0-6.93-13.33c-3.76-2.69-6.41-3.23-9.47-3.23-1.17,0-2.4.09-3.55.18-5.52.45-11.88,6.08-13.63,9.54a.41.41,0,0,1,0,.09,14.62,14.62,0,0,0-2.57-.23h0a10.71,10.71,0,0,0-5.83,1.55c-3.9,2.52-4.61,7-4.71,9.25C18,38.69,12.3,42,11.82,48.83c-.38,5.4,1.34,8.47,2.79,10a7.66,7.66,0,0,0,5.26,2.61H72.39A11.38,11.38,0,0,0,83.89,52C84.28,50.05,85.78,40.08,76.85,35.62ZM79,51a6.43,6.43,0,0,1-6.6,5.45H20.13a2.71,2.71,0,0,1-1.8-1c-.83-.89-1.78-2.72-1.52-6.32.39-5.6,7-6.91,7.3-7l.95-.18a2.5,2.5,0,0,0,2-2.65l-.08-1c0-.19-.18-4.21,2.37-5.86a5.65,5.65,0,0,1,3.07-.8h.1a10.38,10.38,0,0,1,2.27.27l.68.2a2.53,2.53,0,0,0,2.64-.84l.44-.56a9.55,9.55,0,0,0,1.06-1.61c1.21-2.4,6.2-6.6,9.6-6.87,1.06-.09,2.16-.17,3.15-.17,2.22,0,3.84.36,6.55,2.3A10.72,10.72,0,0,1,63.7,34.73l-.33,1.7a2.51,2.51,0,0,0,3.12,2.88l1.6-.44a5.42,5.42,0,0,1,1.27-.12,11.72,11.72,0,0,1,5.22,1.33C76,40.78,80.47,43.65,79,51Z" />
      <circle fill={color} cx="27.04" cy="65.73" r="1.84" />
      <path fill={color} d="M53.24,63.89a1.84,1.84,0,1,0,1.84,1.84A1.84,1.84,0,0,0,53.24,63.89Z" />
      <path fill={color} d="M40.14,63.89A1.84,1.84,0,1,0,42,65.73,1.84,1.84,0,0,0,40.14,63.89Z" />
      <path fill={color} d="M66.34,63.89a1.84,1.84,0,1,0,1.84,1.84A1.85,1.85,0,0,0,66.34,63.89Z" />
      <path fill={color} d="M33.59,68.26a1.84,1.84,0,1,0,1.83,1.84A1.84,1.84,0,0,0,33.59,68.26Z" />
      <path fill={color} d="M59.79,68.26a1.84,1.84,0,1,0,1.84,1.84A1.84,1.84,0,0,0,59.79,68.26Z" />
      <path fill={color} d="M46.69,68.26a1.84,1.84,0,1,0,1.84,1.84A1.84,1.84,0,0,0,46.69,68.26Z" />
      <circle fill={color} cx="20.48" cy="70.1" r="1.84" />
      <path fill={color} d="M73.1,68.26a1.84,1.84,0,1,0,1.84,1.84A1.83,1.83,0,0,0,73.1,68.26Z" />
      <circle fill={color} cx="26.82" cy="74.46" r="1.84" />
      <path fill={color} d="M53,72.62a1.84,1.84,0,1,0,1.84,1.84A1.85,1.85,0,0,0,53,72.62Z" />
      <path fill={color} d="M39.92,72.62a1.84,1.84,0,1,0,1.84,1.84A1.84,1.84,0,0,0,39.92,72.62Z" />
      <path fill={color} d="M66.12,72.62A1.84,1.84,0,1,0,68,74.46,1.84,1.84,0,0,0,66.12,72.62Z" />
    </svg>
  )
}
