import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import installed_power from '../../../../assets/icons/installed_power'
import { translate } from '../../../../translations/i18n';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width:'100%',
    height : '100%',
    display : 'flex',
    justifyContent : 'flex-start',
    alignItems : 'center',
    [theme.breakpoints.down('md')]:{
      paddingBottom : '0px !important'
    },
  },
  wrapperIcon : {
    width: '19%',
    margin : '0px 0px 0px 15%',
    [theme.breakpoints.down('md')]:{
      margin : '18% 0px 15%',
      width: '25%',
    },
      [theme.breakpoints.down('sm')]:{
      margin : 0,
      width: '15%',
    },
    [theme.breakpoints.up(2560)]:{
      width: '19%',
      margin : '0px 0px 0px 4%',
    },
    },

  unitText:{
    // fontSize : 40 ,
    fontSize : '2.5rem',
    fontFamily: 'Nunito sans',
    [theme.breakpoints.down('sm')]:{
      fontSize : '1rem !important'
    },
    [theme.breakpoints.up(2560)]:{
      fontSize : '3.5rem'
    },
    fontWeight : 'bold'
  },
  descText:{
    fontFamily: 'Nunito sans',
    textTransform: 'uppercase',
    padding : '0px 8px',
    fontSize : '1.2rem',
    [theme.breakpoints.down('sm')]:{
      fontSize : '0.60rem !important'
    },
    [theme.breakpoints.up(2560)]:{
      fontSize : '2.0rem'
    },
    // fontSize : 16,
    color : '#888888',
    textAlign : 'center'
  },
  textContainer : {
    alignItems : 'flexStart' ,
    display : 'flex' ,
    flexDirection : 'column',
    marginLeft : 16,
      [theme.breakpoints.down('sm')]:{
      marginLeft : 4
    },
  }


}));

export default function ProductionGraph(props) {
  const classes = useStyles(props);
  const { color = 'rgb(78, 131, 60)' , value = 0, plantName = ''} = props


  const elements = [
    "Cgs q",
    "Csg v1",
    "Csg w",
    "To dc 1",
    "Bo dc 13",
    "Bo dc 15",
    "Ms dca",
    "Ms dcc"
  ];

  const checkStringIncludesElement = (str) => {
    const lowerCaseStr = str.toLowerCase();
    for (let element of elements) {
      if (lowerCaseStr.includes(element.toLowerCase())) {
        return true;
      }
    }
    return false;
  };



  return(
    <div className={classes.wrapper}>
      <div className={classes.wrapperIcon}>
        {installed_power({ color })}
      </div>


      <div className={classes.textContainer}>
        <Typography className={classes.unitText} style={(window.innerWidth <= 1440) ? {fontSize : 28,} : {}}>
          {value}
          {' '}
kWh
        </Typography>

        <Typography className={classes.descText} style={(window.innerWidth <= 1440) ? {fontSize : 16} : {}}>
          {
            checkStringIncludesElement(plantName) ?
              'Total production'
:
              'Total production (last 12 months)'
          }
        </Typography>
      </div>


    </div>
  )
}
