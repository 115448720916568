import React, {useEffect , useState} from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from 'moment'
import 'moment/locale/it';
import 'moment/locale/en-gb';


const useStyles = makeStyles(theme => ({
  wrapper: {
    display : 'flex',
    flexDirection : 'column',
    alignItems : 'flex-end'
  },
  day:{
    fontSize : 26,
    fontWeight : 'bold',
    [theme.breakpoints.down('md')]:{
    fontSize :22,
    }
  },
  date:{
    fontSize : 18,
    color : '#adadad',
    [theme.breakpoints.down('md')]:{
    fontSize : 16,
    }
  }
}));

export default function DateText(props) {
  moment.locale((props.lng === 'it') ? props.lng : 'en-gb');
  const classes = useStyles(props);
  const [day, setDay] = useState(moment().format('HH:mm:ss'));
  const {date = `${moment().format('dddd Do MMMM')}`} = props;

  useEffect(() => {
    setInterval( () => {
      setDay(moment(new Date()).format('HH:mm:ss'))
    },1000)
  }, []);

  return(
    <div className={classes.wrapper}>
      <Typography className={classes.day}>{day}</Typography>
      <Typography className={classes.date}>{date}</Typography>
    </div>
  )
}
