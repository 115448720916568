import Typography from '@material-ui/core/Typography';
import { Dropzone } from '@cobrains/react-kit';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import React, { createRef, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import funnelLayout from 'echarts/src/chart/funnel/funnelLayout';
import { Player } from 'video-react';
import { Helmet } from 'react-helmet';
import VideoSlider from '../slider/video/VideoSlider';
import GemVideo from '../../assets/videos/GEM _ PROMO_OFFICIAL 2017.mp4'


const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    padding: 20
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 40,
      minWidth: 168
    },
    [`& input`]: {
      padding: 4,
      minWidth: 168
    },
    [`& select`]: {
      padding: 4
    },
  },
  card: {
    justifyContent: 'space-between',
    flex: '1 1 auto',
    width: '48%',
    [theme.breakpoints.down('sm')]:{
      minWidth: '100%',
      flexDirection : 'column'
    },
    maxWidth: '50%',
    display: 'flex',
    padding: 16
  },
  media: {
    width: '100%',
    background : '#f0f0f0'
    // paddingTop: '56.25%', // 16:9
  },
  column: {
    display:'flex',
    padding: 20,
    width:'100%',
    flexDirection: 'column'
  },
  row: { display:'flex',
    padding: 20,
    width:'100%',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  action: {
    display: 'flex',
    flexDirection: 'column-reverse',
    [theme.breakpoints.down('sm')]:{
      flexDirection: 'row-reverse',
    },
  },
  hidden : {
    visibility: 'hidden',
    position : 'absolute',
    opacity : 0
  },
  video:{
    '& video':{
      paddingTop : 0
    },
    '& div':{
      paddingTop : 0,
      backgroundColor : 'rgba(0,0,0,0)'
    },
    backgroundColor : 'rgba(0,0,0,0)',
    position : 'unset',
    paddingTop : 0
  },
  fileActionAdd:{
    backgroundColor : theme.palette.primary.main,
    padding : 6,
    margin : 6,
    border : '1px solid rgba(0,0,0,0.12)'
  },
  fileActionDelete:{
    border : '1px solid rgba(0,0,0,0.12)',
    padding : 6,
    margin : 6,
  }
}));

export default function FileCard(props){
  const classes = useStyles(props);
  const {maxMb = 5 , type='image' } = props;
  let dropzoneRef = null;
  const [image, setImage] = React.useState(props.image);


  useEffect(() => {
    setImage(props.image)
  } , [props.image])

  const onDrop = (imageFiles) => {
    setImage(URL.createObjectURL(imageFiles[0]));
    if (props.onDrop)
    props.onDrop(imageFiles)
  }

  const setRef = (ref) => {
    dropzoneRef = { current : ref }
  }

  const removeImage = () => {
    setImage(null);
    if (props.onDelete)
      props.onDelete()

  }



  return(
    <div className={classes.card} style={(window.innerWidth <= 1440) ? {    flexDirection: 'column'} : {}}>
      <Typography style={{fontSize : 16, width: 'fit-content', marginRight: 4, minWidth: '10%'}}>
        {props.title}
      </Typography>
      <div className={(!image) ? classes.normal : classes.hidden}>
        <Dropzone
          multiple={false}
          getRef={(ref) => setRef(ref)}
          onDrop={files => onDrop(files)}
          maxMb={maxMb}
          accept={(type === 'video') ? 'video/mp4, video/mov': 'image/jpeg, image/png'}
          zoneStyle={{margin: 0, width: '100%', background : '#f0f0f0'}}
        />
      </div>

      {( (image !== null) && type === 'video') ? (
        <>
          <Helmet>
            <style type="text/css">
              {`
              .video-react {
                display: block;
                vertical-align: top;
                box-sizing: border-box;
                color: #fff;
                /* background-color: #000; */
                /* position: relative; */
                font-size: 10px;
                line-height: 1;
                font-family: serif, Times, "Times New Roman";
                -webkit-user-select: none;
                user-select: none;
                padding-top: 0px !important;
              }
              .video-react .video-react-fullscreen-control {
                  cursor: pointer;
                  flex: none;
                  display: none;
              }
              `}
            </style>
          </Helmet>
          <div style={{ minHeight: 200, maxHeight : 200 ,width: '100%', position : 'relative'}}>
            <Player className={classes.video} muted width={200} height={200} src={image} />
          </div>
        </>
      )
        :
        ((image !== null)  && type === 'image') &&
        (
          <div
            className={classes.normal}
            style={{
            minHeight: 200,
            width: '100%',
          }}
          >
            <img
              className={classes.normal}
              style={{maxHeight : 200, width: '100%', objectFit: 'contain'}}
              alt=''
              src={image}
            />
          </div>
      )}

      <div className={classes.action} style={(window.innerWidth <= 1440) ? {flexDirection : 'row-reverse',width: '100%'} : {}}>
        <IconButton
          className={classes.fileActionAdd}
          onClick={() => (dropzoneRef.current !== null) ?
          dropzoneRef.current.open()
        :
          console.error('')}
        >
          <i className="icon-aggiungi" style={{ fontSize : 16,color : '#fff'  }} />
        </IconButton>

        <IconButton
          className={classes.fileActionDelete}
          onClick={() => removeImage()}
        >
          <i className="icon-elimina" style={{ fontSize : 16  }} />
        </IconButton>
      </div>
    </div>
  )
}
