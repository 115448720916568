import echarts from 'echarts';


const option = props => {
  const { color = 'rgb(78, 131, 60)', dataGraph, index, lastValue = 2648.5248145614  } = props;
  const start = Object.keys(dataGraph).findIndex(item => item === '04:00');
  const end = Object.keys(dataGraph).findIndex(item => item === '22:00');
  const dateReal =
    start === -1 || end === -1 ? Object.keys(dataGraph) : Object.keys(dataGraph).slice(start, end);
  let dataReal =
    start === -1 || end === -1
      ? Object.values(dataGraph)
      : Object.values(dataGraph).slice(start, end);


  const interval = ["00", "15", "30", "45"];
  let times = [];
  for(let i = 0; i < 24; i++){
    for(let j = 0; j < 4; j++){
      let time = `${i  }:${  interval[j]}`;
      if(i < 10){
        time = `0${  time}`;
      }
      times.push(time);
    }
  }

  times = start === -1 || end === -1 ? times : times.slice(start, end);

  return {
    animation:true,
    tooltip:{
      show : false,
      trigger : 'axis',
      axisPointer: {
        lineStyle: {
          width: 4,
          color: 'rgba(255,255,255,0.6)'
        },
        label : {
          formatter : '{value} kW \b CURRENT POWER '
        }
      }
    },
    xAxis: {
      type: 'category',
      data: index === 0 ? dateReal : times,
      id : 'xAxisVisual',
      boundaryGap: false,
      splitArea: {
        areaStyle :{
          color : 'rgba(200,200,200,0)'
        },
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLine : {
        lineStyle:{
          color : 'rgba(255,255,255, 0)'
        }
      },
      axisLabel :
        (window.innerWidth < 900) ?
          {
        color : 'rgba(255,255,255)',
        fontSize : 14,
        fontWeight : 'bold'
      }:
          (window.innerWidth < 2559) ?
            {
            color : 'rgba(255,255,255)',
            fontSize : 16,
            fontWeight : 'bold'
          }
          :
            {
              color : 'rgba(255,255,255)',
              fontSize : 24,
              fontWeight : 'bold'
            }
    },
    yAxis: (window.innerWidth < 900) ?
    {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle : {
                width : 3,
                color : 'rgba(255,255,255,0.4)'
              }
            },
          splitNumber: 3,
          boundaryGap: [0, '100%'],
          axisLine : {
            lineStyle:{
              color : 'rgba(255,255,255, 0)'
            }
          },
      axisLabel :

        {
        color : 'rgba(255,255,255)',
        fontSize : 14,
        fontWeight : 'bold'
      }
          } :
  {
    type: 'value',
      splitLine: {
    show: true,
      lineStyle : {
      width : 3,
        color : 'rgba(255,255,255,0.4)'
    }
  },
    splitNumber: 4,
      boundaryGap: [0, '100%'],
    axisLine : {
    lineStyle:{
      color : 'rgba(255,255,255, 0)'
    }
  },
    axisLabel :
      (window.innerWidth < 2559) ?
        {
          color : 'rgba(255,255,255)',
          fontSize : 16,
          fontWeight : 'bold'
        }
        :
        {
          color : 'rgba(255,255,255)',
          fontSize : 24,
          fontWeight : 'bold'
        }

  },
    grid: {
      left: 70,
      right: 40,
      bottom: 30,
      top: 20,
    },
    series: [
      {
        name: 'test',
        type: 'line',
        id : 'visual',
        symbol: 'none',
        smooth: true,
        itemStyle: {
          color,
        },
        lineStyle: {
          color: '#478861',
          width: 5
        },/**/
        markLine: {
          silent : true,
          symbol : 'none',
          precision : 1,
          label : {
            show :false
          },
          data: [
            {value : '', xAxis : lastValue},
          ],
          lineStyle : {
            color : 'rgba(0,0,0,0.9)',
            // type : 'solid',
            width : 2,
          }
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 1, 2, 3, [
            {
              offset: 0,
              color: '#478861',
            },
            {
              offset: 1,
              color: 'rgba(255, 255, 255, 0.5)',
            },
          ]),
        },
        data: dataReal,
      },
    ],
  };
};

export default option;
