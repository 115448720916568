import Auth from "../../views/auth/SignIn";

export const AuthRoutes = [
    {
        path: '/signin',
        name: 'Sign In',
        component: Auth,
        exact: true,
        private:false,
      seo:{
        title: 'Sign in',
      },
    },
];
