import {
  getCrud,
  crudActionsGenerator,
  resetDataCrud,
  crudReducerGenerator,
} from '@cobrains/react-kit';
import { initialState } from '../initialState';

/** plants-redux.js */
const visualPageActions = crudActionsGenerator("visualPage");
export const visualPageReducer = crudReducerGenerator("visualPage", initialState.visualPage);

/** Thunk */
export const getSlider = (id , params) =>  getCrud(visualPageActions, `custom-view/dashboard/${id}`, params);

export const resetSliderData = () => resetDataCrud(visualPageActions);
