import React from 'react';

export default function Thunder(props){
  const {color = 'rgb(0, 0, 0)'} = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Livello_1" data-name="Livello 1" viewBox="0 0 96 96">
      <title>thunderstorm</title>
      <path fill={color} d="M76.85,26.19a16.64,16.64,0,0,0-7.4-1.88H68.8A16,16,0,0,0,61.87,11c-3.76-2.69-6.41-3.23-9.47-3.23-1.17,0-2.4.08-3.55.18C43.33,8.4,37,14,35.22,17.49a.41.41,0,0,1,0,.09,13.91,13.91,0,0,0-2.58-.23h0a10.6,10.6,0,0,0-5.83,1.55c-3.9,2.51-4.61,7-4.71,9.25-4,1.1-9.74,4.41-10.22,11.25-.38,5.4,1.34,8.46,2.79,10A7.66,7.66,0,0,0,19.87,52H72.39a11.38,11.38,0,0,0,11.5-9.47C84.28,40.62,85.78,30.65,76.85,26.19ZM79,41.59A6.43,6.43,0,0,1,72.39,47H20.13a2.7,2.7,0,0,1-1.8-1c-1.18-1.27-1.72-3.52-1.52-6.33.39-5.6,7-6.9,7.3-7l.95-.17a2.5,2.5,0,0,0,2-2.66L27,29c0-.2-.18-4.22,2.37-5.87a5.63,5.63,0,0,1,3.07-.8h.1a9.74,9.74,0,0,1,2.27.27l.68.19A2.51,2.51,0,0,0,38.15,22l.44-.55a10.07,10.07,0,0,0,1.06-1.62c1.21-2.39,6.2-6.59,9.6-6.87,1.06-.08,2.16-.16,3.15-.16,2.22,0,3.84.35,6.55,2.29A10.73,10.73,0,0,1,63.7,25.3L63.37,27a2.53,2.53,0,0,0,.76,2.31,2.5,2.5,0,0,0,2.36.58l1.6-.45a6,6,0,0,1,1.27-.12,11.72,11.72,0,0,1,5.22,1.33C76,31.34,80.47,34.21,79,41.59Z" />
      <path fill={color} d="M56.22,55.92a1.5,1.5,0,0,0-2.68,1.35L58.42,67,51.48,69.5a1.51,1.51,0,0,0-.93,1.84l2.69,9-11.72-12L49.24,65A1.51,1.51,0,0,0,50,62.92l-3.64-7a1.5,1.5,0,1,0-2.66,1.39l2.9,5.58-8.25,3.55a1.5,1.5,0,0,0-.48,2.43L55.34,86.78a1.49,1.49,0,0,0,1.08.45,1.43,1.43,0,0,0,.75-.2,1.49,1.49,0,0,0,.68-1.72l-4-13.48L61,69.21a1.52,1.52,0,0,0,.88-.86,1.48,1.48,0,0,0,0-1.22Z" />
    </svg>
  )
}
