


import React from 'react';

export default function rotate(props){
  const {color = '#bcbcbc'} = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Livello_1" data-name="Livello 1" fill={color} viewBox="0 0 96 96">
      <title>Tavola disegno 1 copia</title>
      <path d="M64.85,19.67,51.7,21.21a1,1,0,0,0-.88,1.1,1,1,0,0,0,1,.89h.11l15.85-1.85a1,1,0,0,0,.79-.55,1,1,0,0,0,0-1L60.19,6.22a1,1,0,1,0-1.7,1l6.08,10A34.94,34.94,0,0,0,18.66,67a1,1,0,0,0,1.67-1.09A32.95,32.95,0,0,1,64.85,19.67Z"/><path d="M77.35,29a1,1,0,0,0-1.68,1.08A32.95,32.95,0,0,1,31.16,76.28l13-1.51A1,1,0,0,0,45,73.66a1,1,0,0,0-1.11-.88L28,74.63a1,1,0,0,0-.74,1.52l8.33,13.61a1,1,0,0,0,.85.48,1,1,0,0,0,.52-.15,1,1,0,0,0,.34-1.37L31.12,78.55A34.94,34.94,0,0,0,77.35,29Z"/><path d="M37.73,56.91a3.49,3.49,0,0,1-3.48-2.74.64.64,0,0,0-.89-.36l-.73.3a.62.62,0,0,0-.34.84,5.37,5.37,0,0,0,5.44,3.85c6.09,0,7.14-7.33,7.06-11.13a12.1,12.1,0,0,0-1-5A6.29,6.29,0,0,0,38,39.24a6.18,6.18,0,0,0-6.41,6.33,6.11,6.11,0,0,0,6.25,6.33,6.24,6.24,0,0,0,4.83-2.26C42.53,53,41.39,56.91,37.73,56.91ZM38,50a4.38,4.38,0,0,1,0-8.76,4.27,4.27,0,0,1,4.51,4.45A4.31,4.31,0,0,1,38,50Z"/><path d="M47.62,49c0,6,2.61,9.76,6.52,9.76s6.49-3.75,6.49-9.76-2.48-9.8-6.49-9.8S47.62,43,47.62,49Zm11,0c0,4.64-1.59,7.79-4.44,7.79S49.66,53.65,49.66,49s1.67-7.86,4.48-7.86S58.58,44.3,58.58,49Z"/><path d="M63.05,42.31a3.56,3.56,0,1,0,3.56-3.53A3.54,3.54,0,0,0,63.05,42.31Zm5.58,0a2,2,0,0,1-2,2,2,2,0,1,1,0-4A2,2,0,0,1,68.63,42.31Z"/></svg>
  )
}
