import { withStyles } from '@material-ui/core/styles'
import { compose } from 'redux'
import {
  Search,
  FormGenerator,
  apiCall,
  FunctionsToolbar,
  ConfirmDialog,
  getDataFilter,
  TableGenerator,
  getDataPagination,
  ChangePassword, addMessage,
} from '@cobrains/react-kit';
import {connect} from 'react-redux'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import CrudFields from './settings/crud/CrudFields'
import CrudEdit from './settings/crud/CrudEdit'
import { translate } from '../../../translations/i18n';
import { getItems } from './settings/functions-toolbar/Items';
import TableFields from './settings/table/TableFields';
import {
  getUsers,
  editUser,
  createUser,
  deleteUser,
  userSelected,
  setDialog,
  userResetSelected,
  prepareUser,
  resetPrepareUser,
  prepareUserTable,
  addUserQueryFilter,
  resetUsersData,
} from '../../../redux/pages/users-page-redux';

const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    padding: 20
  }
}));

function UserCrud (props){
  const classes = useStyles(props);
  const [openPassword, setOpenPassword] = useState(false); // integer state

  useEffect(() => {
    props.onResetData();
    props.onResetSelected();
    props.onPrepareUserTable();
    props.onGetUsers();
  }, []);


  function handleChangePassword(password) {
    const tmpForm = {
      password
    };
    props.onEditUser(tmpForm , props.users.selected[0].id);
    setOpenPassword(false);
    props.onAddMessage('Password cambiata con successo' , 'success');
  }


  return (
    <div className={classes.root}>
      <div style={{display: 'flex'}}>
        {(props.user.type !== 'CA') && (<FunctionsToolbar items={getItems(props , setOpenPassword)} style={{ flex: 1 }} />)}
        <Search
          buttonStyle={{borderTopRightRadius : 18 , borderBottomRightRadius : 18 , boxShadow : 'unset'}}
          data={props.users.data.users} // data list
          keySearch={['first_name', 'last_name', 'username']} // key that have to search
          placeholder="Ricerca per nome, cognome o username" // placeholder
          onConfirm={(value, search) => {
            props.onResetData();
            getDataFilter(props.onGetUsers, {props, search})
          }} // action after search
          style={{flex: 1}}
        />
      </div>

      {(props.prepare.fields.length > 0) && (
      <FormGenerator
        fields={[props.prepare.fields, CrudFields]}
        data={{}}
        isPending={false}
        onSubmit={(user) => {
          props.onCreateUser(user);
        }}
        onCancel={() => {
          props.onSetDialog('add', false);
          props.onResetPrepareUser();
        }}
        layoutStyle={{width : '31%'}}
        translate={translate}
        errors={props.errors.create.data.messages}
        dialog
        dialogProps={{
          onClose: () => {
            props.onSetDialog('add', false);
            props.onResetPrepareUser();
          },
          open:props.dialogs.add,
          title:'Aggiungi un nuovo utente',
          subtitle:'Compila i campi per aggiungere un nuovo utente'
        }}
        actionsProps={{
          cancelProps : {confirm: true}
        }}
      />
    )}

      {(props.prepare.fields.length > 0) && (
      <FormGenerator
        buttonVariant='contained'
        fields={[props.prepare.fields, CrudEdit]}
        data={props.users.selected}
        isPending={false}
        onSubmit={(user) => {
          props.onEditUser(user , props.users.selected[0].id)
        }}
        layoutStyle={{width : '31%'}}
        onCancel={() => {
          props.onSetDialog('edit', false)
          props.onResetPrepareUser();
        }}
        errors={props.errors.edit.data.messages}
        dialog
        dialogProps={{
          onClose: () => {
            props.onSetDialog('edit', false);
            props.onResetPrepareUser();
          },
          open:props.dialogs.edit,
          title:'Modifica un utente',
          subtitle: `Compila i campi per modificare l'utente`
        }}
        actionsProps={{
          cancelProps : {confirm: true}
        }}
      />
    )}

      <Paper style={{overflow: 'auto', marginTop:20}}>
        <TableGenerator
          labelRowsPerPage="Righe per pagina"
          loader={props.loaders.get}
          data={props.users.data.content.items}
          // maxSelectableElements={1}
          rowsPerPage={25}
          onOrderChange={(order , orderBy) => console.log('order',order , orderBy)}
          selectedElements={props.users.selected}
          onChangeSelected={(data) => props.onUserSelected(data)}
          fields={[props.users.table.fields , TableFields]}
          onChangePage={(page, limit, offset, filters) => getDataPagination(props.onGetUsers, {props, limit, offset, filters})}
          onFiltersChange={(page, limit, offset, filters) => getDataFilter(props.onGetUsers, {props, limit, offset, filters})}
        />
      </Paper>

      {(props.dialogs.delete) && (
        <ConfirmDialog
          open={props.dialogs.delete}
          title="Eliminazione utente"
          message={"Sei sicuro di voler eliminare l'utente?"}
          onCancel={() => props.onSetDialog('delete', false)}
          onSubmit={() => props.users.selected.map((user , index) => {
            props.onDeleteUser(user.id);
            if (props.users.selected.length === index + 1)
              props.onResetSelected()
          })}
          cancelProps={{ label: 'Annulla'}}
          submitProps={{ label: 'Elimina'}}
        />
      )}

      {(openPassword) && (
        <ChangePassword
          title="Cambia la password di un utente"
          onCancel={() => setOpenPassword(false)}
          open={openPassword}
          onSubmit={(value) =>  handleChangePassword(value)}
          cancelProps={{ label: 'Annulla' }}
          submitProps={{ label: 'Conferma' }}
        />
      )}
    </div>
    )
}


const mapStateToProps = state => ({
  user: state.auth.user,
  users: state.usersPage,
  dialogs: state.usersPage.dialogs,
  loaders: state.usersPage.loaders,
  errors: state.usersPage.errors,
  prepare: state.usersPage.prepare,
  filters: state.usersPage.filters
});

const mapActionsToProps = {
  onApiCall : apiCall,
  onGetUsers: getUsers,
  onUserSelected: userSelected,
  onSetDialog: setDialog,
  onCreateUser: createUser,
  onEditUser: editUser,
  onDeleteUser: deleteUser,
  onResetSelected: userResetSelected,
  onPrepareUser: prepareUser,
  onResetPrepareUser: resetPrepareUser,
  onPrepareUserTable :prepareUserTable,
  /* Common */
  onAddQueryFilter: addUserQueryFilter,
  onResetData: resetUsersData,
  onAddMessage: addMessage
};

export default compose(
    withStyles({withTheme: true}),
  connect(mapStateToProps , mapActionsToProps)
)(UserCrud)
