import React from 'react';

export default function installed_power(props) {
  const { color = "rgb(78, 131, 60)" } = props;

  return (
    <svg
      id="Livello_1"
      data-name="Livello 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 127.76 96"
    >
      <path
        fill={color}
        d="M18.48,58.71H63.34a2.5,2.5,0,0,0,0-5H54.5V46.88H65.74l.53,4.95a2.51,2.51,0,0,0,2.49,2.23L69,54a2.5,2.5,0,0,0,2.21-2.76l-.47-4.4H82.71l1.42,4.91a2.5,2.5,0,0,0,4.81-1.39l-8.11-28a2.51,2.51,0,0,0-2.4-1.81H25.94a2.51,2.51,0,0,0-2.4,1.78L13.59,55.49a.13.13,0,0,1,0,.06,2,2,0,0,0-.07.42s0,.09,0,.14,0,.06,0,.1V64a2.5,2.5,0,0,0,2.5,2.5H46v3.91H41.69a2.5,2.5,0,0,0,0,5H59.44a2.5,2.5,0,0,0,0-5h-.55v-4a2.49,2.49,0,0,0-.36-5h-40ZM51,70.44V66.53h2.85v3.91ZM21.4,46.88H33.34l-.92,6.83H19.35Zm3.42-11.39h10L34,41.88H22.9ZM49.5,25.56v4.93H40.58l.66-4.93Zm14,0L64,30.49H54.5V25.56Zm-14,9.93v6.39H39.05l.86-6.39Zm0,18.22h-12l.91-6.83H49.5Zm5-11.83V35.49h10l.68,6.39Zm15.74,0-.68-6.39h9.86l1.85,6.39ZM78,30.49H69l-.52-4.93h8ZM36.2,25.56l-.67,4.93H26.32l1.48-4.93Z"
      />
      <path
        fill={color}
        d="M113.52,40a2.51,2.51,0,0,0-2.15-.67l-12.47,2a2.5,2.5,0,1,0,.81,4.93l4.65-.76L89.46,60.9l-7-6.86a2.65,2.65,0,0,0-1.78-.71,2.48,2.48,0,0,0-1.76.76L65.21,68.26a2.5,2.5,0,1,0,3.59,3.47l12-12.36,7,6.86a2.61,2.61,0,0,0,1.78.72,2.56,2.56,0,0,0,1.77-.76l17.07-17.66-.7,5.43a2.49,2.49,0,0,0,2.16,2.79,1.73,1.73,0,0,0,.32,0,2.51,2.51,0,0,0,2.48-2.18l1.62-12.54A2.52,2.52,0,0,0,113.52,40Z"
      />
    </svg>
  )
}
