import React from 'react';

export default function leaf(props){
  const {color = "rgb(78, 131, 60)"} = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Livello_1" data-name="Livello 1" viewBox="0 0 140.75 96"><title>Tavola disegno 69 copia 7</title>
      <path fill={color} d="M94.1,23.24a1.15,1.15,0,0,0,.26,0,2.81,2.81,0,0,0,2-2.79,5.57,5.57,0,0,0-.43-1.93,3.7,3.7,0,0,1-.22-2.64c.4-1.36,1.33-1.38,1.49-1.37a1,1,0,1,0,.21-2,3.56,3.56,0,0,0-3.62,2.79A5.59,5.59,0,0,0,94,19.17a3.86,3.86,0,0,1,.31,1.25.81.81,0,0,1-.5.86,1,1,0,0,0,.27,2Z"/>
      <path fill={color} d="M100,23.7a1.12,1.12,0,0,0,.35-.06,1,1,0,0,0,.58-1.29,2.24,2.24,0,0,1,.34-2.23A5.08,5.08,0,0,1,103.45,19a5.22,5.22,0,0,0,1.38-.54c2.34-1.45,2-3.06,2-3.24a1,1,0,0,0-1.21-.8,1,1,0,0,0-.75,1.16s0,.51-1.1,1.18a3.92,3.92,0,0,1-.89.33,6.82,6.82,0,0,0-3,1.58,4.23,4.23,0,0,0-.82,4.4A1,1,0,0,0,100,23.7Z"/>
      <path fill={color} d="M109.43,39.77a.91.91,0,0,0,.35-.07,1,1,0,0,0,.58-1.28,2.24,2.24,0,0,1,.34-2.23,5,5,0,0,1,2.22-1.12,5.22,5.22,0,0,0,1.38-.54c2.34-1.45,2-3.06,2-3.24a1,1,0,0,0-1.2-.8,1,1,0,0,0-.76,1.16s0,.51-1.1,1.18a3.54,3.54,0,0,1-.89.32,7,7,0,0,0-3,1.59,4.21,4.21,0,0,0-.82,4.39A1,1,0,0,0,109.43,39.77Z"/>
      <path fill={color} d="M131.82,70.52H114.27V44.79a2,2,0,0,0-2-2h-6.65l-.16-1.07V28a2,2,0,0,0-2-2H90.23a2,2,0,0,0-2,2V41.72L85.09,62.09c0,.1,0,.2,0,.3v8.13h-2.4V36.41a2,2,0,0,0-2-2H69.19a2,2,0,0,0-2,2V46.67h-3.9V41.23a2,2,0,0,0-3.18-1.62l-7.06,5.11V41.23A2,2,0,0,0,52,39.44a2,2,0,0,0-2.08.17l-7.25,5.24V41.23a2,2,0,0,0-1.09-1.79,2,2,0,0,0-2.08.17L29.22,47a2,2,0,0,0-.82,1.62V70.52H8.93a2,2,0,1,0,0,4H131.82a2,2,0,0,0,0-4Zm-99.42,0V49.66l6.23-4.52v3.53a2,2,0,0,0,2,2,1.93,1.93,0,0,0,.42-.05,1.82,1.82,0,0,0,.93-.36l7.07-5.12v3.53a2,2,0,0,0,3.41,1.42l6.83-5v3.53a2,2,0,0,0,2,2h7.9a2,2,0,0,0,2-2V38.41h7.48V70.52Zm56.67,0v-8L92.2,42.17a1.53,1.53,0,0,0,0-.3V30h9.23v11.9a1.51,1.51,0,0,0,0,.3l.1.62H99.74a2,2,0,0,0-2,2V70.52Zm12.67,0V46.79h8.53V70.52Z"/>
    </svg>
  )
}
