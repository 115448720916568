import React, {useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {Typography} from "@material-ui/core";
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  CircularProgressbarWithChildren,
  buildStyles
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css';
import { translate , changeLocale } from '../../../../translations/i18n';

const BORDER_COLOR = {border: '1px solid #b0b0b0'};
const TEXT_COLOR = '#b0b0b0';

const useStyles = makeStyles(theme => ({
  feedbackButtons: {
    flex: 1,
    margin: 12,
    ...BORDER_COLOR,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8
  },
  feedbackTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    flex: 1
  },
  feedbackStatsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: TEXT_COLOR,
    fontSize: 20,
    padding: '8px 20px',
    flex: 1
  },
  circularText : {
    color: '#000',
    marginBottom: 4,
    padding: 8,
    textAlign: 'center',
    marginTop: 20
  },
  childrenContainer : {
    display : 'flex',
    flexDirection : 'column',
    alignItems : 'flex-start',
    justifyContent : 'baseline',
    marginTop: 8
  },
  descText:{
    fontSize : 16,
    marginBottom : 24,
    [theme.breakpoints.down('md')]:{
      fontSize : '12px !important',
    },
      // fontSize : '0.75rem',
    color : '#888888',
    textAlign : 'center'
  },
  hText:{
    lineHeight : 1,
    [theme.breakpoints.down('md')]:{
      fontSize : '18px !important'
    },
  },
  mesureText:{
    lineHeight : 1,
    [theme.breakpoints.down('md')]:{
      fontSize : '14px !important'
    },
  },
  percentText:{
    [theme.breakpoints.down('md')]:{
      fontSize : '12px !important'
    },
  },
  container : {
    width : '50%',
    padding: '8px 0px',
    [theme.breakpoints.down('md')]:{
    width : '64%',
    padding: '8px 0px',
    },
      [theme.breakpoints.down('sm')]:{
      width : '60%',
      }
    },
    marginMd:{
      margin: '6% 0px',
    [theme.breakpoints.down('md')]:{
    margin: '12% 0px'
    },
    [theme.breakpoints.down('sm')]:{
    margin: '6% 0px'
    }
    }
}));

function CircularProgressStatic (props){


  useEffect( () => {
    changeLocale(props.language)
  } , [])

  const classes = useStyles(props);
  const {value = 0, middleText = 'middleText', color =  '#df523c' , text = 'text', loader = false , valueText = 0} = props;
  const width = window.innerWidth;
  return (
    <div style={{flex : 1 , height : '100%', display : 'flex', flexDirection : 'column' , justifyContent : 'flex-end'}}>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}} className={classes.marginMd}>
        <div className={classes.container}>
          <CircularProgressbarWithChildren
            value={value}
            strokeWidth={5}
            width="100%"
            styles={buildStyles({
              strokeLinecap: 'round',
              width : '75%',
              flex : 1,
              pathColor: color,
              textColor: '#000',
              fontWeght: 'bold'
            })}
          >
            <div className={classes.childrenContainer}>
              <div style={{display : 'flex',alignItems:'baseline'}}>
                <Typography className={classes.hText} style={(window.innerWidth <= 1440) ? {fontSize : 30,fontWeight : 'bold'} : {fontSize : 40,fontWeight : 'bold'}}>{valueText}</Typography>
                <Typography className={classes.mesureText} style={(window.innerWidth <= 1440) ? {fontSize : 24,fontWeight : 'bold'} : {fontSize : 30,fontWeight : 'bold'}}>kW</Typography>
              </div>
            </div>
          </CircularProgressbarWithChildren>
        </div>
      </div>
      <Typography className={classes.descText} style={(window.innerWidth <= 1440) ? {fontSize : 12} : {}}>
        {translate('data.current_power',{lgn : props.language})}
      </Typography>

    </div>



  )
}




const mapStateToProps = state => ({
  user: state.user,
  api_control: state.api_control,
  router: state.router,
});


export default compose(
  withStyles({withTheme: true}),
  connect(mapStateToProps)
)(CircularProgressStatic);
