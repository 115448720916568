import React from 'react';

export default function total_production(props) {

  const { color = "rgb(78, 131, 60)" } = props;

  return (
    <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96">
      <path
        fill={color}
        d="M84.43,55.67a1.36,1.36,0,0,0,0-.2,2,2,0,0,0-.06-.35.19.19,0,0,0,0-.08l-9-31.24a2.48,2.48,0,0,0-2.4-1.8H23.44a2.5,2.5,0,0,0-2.39,1.78L11.67,55s0,.06,0,.1a1.86,1.86,0,0,0-.06.34,1.36,1.36,0,0,0,0,.2s0,.06,0,.08V63.1a2.5,2.5,0,0,0,2.5,2.5H42.25V69h-4a2.5,2.5,0,1,0,0,5h20a2.5,2.5,0,1,0,0-5H54.64V65.6h27.3a2.5,2.5,0,0,0,2.5-2.5V55.73S84.43,55.69,84.43,55.67Zm-5.81-2.44h-12l-.68-6.14H76.84ZM19.27,47.09h11l-.82,6.14h-12Zm3.22-10.73h9.2l-.77,5.73H20.77ZM45.5,27v4.36H37.4L38,27Zm13.19,0,.48,4.36H50.5V27Zm16.7,15.09h-10l-.63-5.73h9Zm-15,0H50.5V36.36h9.22ZM45.5,36.36v5.73H36l.76-5.73ZM35.3,47.09H45.5v6.14h-11Zm15.2,0H60.9l.67,6.14H50.5ZM72.29,31.36H64.2L63.72,27H71ZM32.94,27l-.58,4.36H24L25.3,27Zm16.7,42H47.25V65.6h2.39Zm2.52-8.4H16.56V58.23H79.44V60.6Z"
      />
    </svg>
  )
}
