import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    root:{
        width:'100%',
        height:'calc(100vh - 48px)',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center'
    }
}));

function Error404(props){
    const classes = useStyles(props);

    return(
        <div className={classes.root}>
            <Typography variant={'h1'}>404</Typography>
            <Typography style={{marginTop:20}}>
                We can't find the page you're looking for.<br/>
                You can either return to the previous page, visit our homepage or contact out support team
            </Typography>
        </div>
    )
}

export default Error404;
