/** groups-redux.js */
import {
  createCrud,
  deleteCrud,
  editCrud,
  getCrud,
  selectedCrud,
  setCrudDialog,
  resetSelectedCrud,
  prepareCrud,
  resetPrepareCrud,
  crudActionsGenerator,
  addQueryFilter,
  resetDataCrud,
  crudReducerGenerator, prepareTable, resetPrepareTable,
} from '@cobrains/react-kit';
import { initialState } from '../initialState';

/** groups-redux.js */
const groupsPageActions = crudActionsGenerator("groupsPage");
const tokensPageActions = crudActionsGenerator("tokensPage");
export const groupsPageReducer = crudReducerGenerator("groupsPage", initialState.groupsPage);
export const tokensPageReducer = crudReducerGenerator("tokensPage", initialState.tokensPage);

/** Thunk */
export const getGroups = (params) => getCrud(groupsPageActions, `group`, params);
export const prepareGroup = (id) => prepareCrud(groupsPageActions, `group/${(id)?`${id}/`:''}prepare`);
export const prepareToken = (id, params= {id}) => prepareCrud(tokensPageActions, `dashboard/api-token/prepare`, params);
export const resetPrepareGroup = () => resetPrepareCrud(groupsPageActions);
export const resetPrepareToken = () => resetPrepareCrud(tokensPageActions);
export const prepareGroupTable = (id) => prepareTable(groupsPageActions, `group/${(id)?`${id}/`:''}prepare`);
export const resetPrepareGroupTable = () => resetPrepareTable(groupsPageActions);
export const createGroup = (data) => createCrud(groupsPageActions, `group`, data);
export const createToken = (data) => createCrud(tokensPageActions, `dashboard/api-token`, data);
export const editGroup = (data , id , clear = true ) => editCrud(groupsPageActions, `group/${id}`, {data, id} , clear);
export const deleteGroup = (id) => deleteCrud(groupsPageActions, `group/${id}`, id);
export const groupSelected = (item) => selectedCrud(groupsPageActions, item);
export const groupResetSelected = () => resetSelectedCrud(groupsPageActions);
export const setDialog = (name, status) => setCrudDialog(groupsPageActions, {name, status});
export const setTokenDialog = (name, status) => setCrudDialog(tokensPageActions, {name, status});
export const addGroupQueryFilter = (filter) => addQueryFilter(groupsPageActions, filter);
export const resetGroupsData = () => resetDataCrud(groupsPageActions);
