/** users-redux.js */
import {
  createCrud,
  deleteCrud,
  editCrud,
  getCrud,
  prepareTable,
  resetPrepareTable,
  selectedCrud,
  setCrudDialog,
  resetSelectedCrud,
  prepareCrud,
  resetPrepareCrud,
  crudActionsGenerator,
  addQueryFilter,
  resetDataCrud,
  crudReducerGenerator
} from '@cobrains/react-kit';
import { initialState } from '../initialState';

/** users-redux.js */
const usersPageActions = crudActionsGenerator("usersPage");
export const usersPageReducer = crudReducerGenerator("usersPage", initialState.usersPage);

/** Thunk */
export const getUsers = (params) => getCrud(usersPageActions, `user`, params);
export const prepareUser = (id) => prepareCrud(usersPageActions, `user/${(id)?`${id}/`:''}prepare`);
export const resetPrepareUser = () => resetPrepareCrud(usersPageActions);
export const prepareUserTable = (id) => prepareTable(usersPageActions, `user/${(id)?`${id}/`:''}prepare`);
export const resetPrepareUserTable = () => resetPrepareTable(usersPageActions);
export const createUser = (data) => createCrud(usersPageActions, `user`, data);
export const editUser = (data , id) => editCrud(usersPageActions, `user/${id}`, {data, id});
export const deleteUser = (id) => deleteCrud(usersPageActions, `user/${id}`, id);
export const userSelected = (item) => selectedCrud(usersPageActions, item);
export const userResetSelected = () => resetSelectedCrud(usersPageActions);
export const setDialog = (name, status) => setCrudDialog(usersPageActions, {name, status});
export const addUserQueryFilter = (filter) => addQueryFilter(usersPageActions, filter);
export const resetUsersData = () => resetDataCrud(usersPageActions);
