import React from 'react';

const TableFields = [
  {
    keyName: 'name',
    columnName: 'Name',
  },
  {
    keyName: 'address.city',
    columnName: 'Indirizzo',
  },
  {
    keyName: 'group' ,
    columnName: 'Gruppi',
    component : (props) => {
      let combinedValue = [];
      if (props.value) {
        props.value.map(
          item => combinedValue = [...combinedValue, item.name]
        );
        combinedValue = combinedValue.join(', ');
      }
      else
        combinedValue = '-';
      return <div>{combinedValue}</div>
    }
  },
];

export default TableFields
