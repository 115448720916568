import React from 'react';

const iconStyle = {
  overflow: 'hidden',
  fontSize: 22,
  marginLeft : -4
};

const AdminSidebarMenu = [
    {
        path: '/',
        name: 'Dashboard',
      icon: (color) => <i className="icon-home" style={{ color : color , ...iconStyle }} />
    },
  {
    path: '/groups',
    name: 'Gruppi',
    icon: (color) => <i className="icon-aziende" style={{ color : color , ...iconStyle }} />
  },
  {
    path: '/plants',
    name: 'Impianti',
    icon: (color) => <i className="icon-impianti" style={{ color : color , ...iconStyle }} />
  },
  {
    path: '/dashboards',
    name: 'Dashboards',
    icon: (color) => <i className="icon-flussi_economici" style={{ color : color , ...iconStyle }} />
  },
  {
    path: '/visual',
    name: 'Visual',
    icon: (color) => <i className="icon-impostazioni" style={{ color : color , ...iconStyle }} />
  },
  {
    path: '/users',
    name: 'Utenti',
    icon: (color) => <i className="icon-utent1" style={{ color : color , ...iconStyle }} />
  },
];

const SuperAdminSettings = {
    sidebarmenu: AdminSidebarMenu,
};

export {SuperAdminSettings}
