import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import solarPannel from '../../../../assets/icons/solar-pannel'
import { translate } from '../../../../translations/i18n';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width:'100%',
    height : '100%',
    display : 'flex',
    flexDirection : 'column',
    justifyContent : 'flex-end',
    alignItems : 'center',
    [theme.breakpoints.down('md')]:{
      paddingBottom : '0px !important'
    },
  },
  wrapperIcon : {
    width: '37%',
    margin : '5% 0px',
    [theme.breakpoints.down('md')]:{
      margin : '20% 0px 15%',
      width: '40%',
    },
      [theme.breakpoints.down('sm')]:{
      margin : '10% 0px 5% 0px',
      width: '30%',
    }

    },

  unitText:{
    fontSize : 40 ,
    [theme.breakpoints.down('sm')]:{
      fontSize : '18px !important'
    },
    // fontSize : '1.3rem' ,
    fontWeight : 'bold'
  },
  descText:{
    padding : '0px 8px',
    // fontSize : '0.75rem',
    fontSize : 16,
    color : '#888888',
    textAlign : 'center'
  }
}));

export default function ProductionGraph(props) {
  const classes = useStyles(props);
  const { color = 'rgb(78, 131, 60)' , value = 0, plantName = '' } = props


  const elements = [
    "Cgs q",
    "Csg v1",
    "Csg w",
    "To dc 1",
    "Bo dc 13",
    "Bo dc 15",
    "Ms dca",
    "Ms dcc"
  ];

  const checkStringIncludesElement = (str) => {
    const lowerCaseStr = str.toLowerCase();
    for (let element of elements) {
      if (lowerCaseStr.includes(element.toLowerCase())) {
        return true;
      }
    }
    return false;
  };


  return(
    <div className={classes.wrapper} style={(window.innerWidth <= 1440) ? {paddingBottom : 20} : {}}>
      <div className={classes.wrapperIcon}>
        {solarPannel({color})}
      </div>

      <div style={{width : '100%' , alignItems : 'center' , display : 'flex' , flexDirection : 'column' , marginBottom : 24}}>
        <Typography className={classes.unitText} style={(window.innerWidth <= 1440) ? {fontSize : 28,} : {}}>
          {value}
          {' '}
kWh
        </Typography>

        <Typography className={classes.descText} style={(window.innerWidth <= 1440) ? {fontSize : 12} : {}}>



          {checkStringIncludesElement(plantName) ?
            translate('data.total_production_new',{lgn : props.language})
            :
            translate('data.total_production',{lgn : props.language})
          }
        </Typography>
      </div>


    </div>
  )
}
