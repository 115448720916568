/** plants-redux.js */
import {
  createCrud,
  deleteCrud,
  editCrud,
  getCrud,
  selectedCrud,
  setCrudDialog,
  resetSelectedCrud,
  prepareCrud,
  resetPrepareCrud,
  crudActionsGenerator,
  addQueryFilter,
  resetDataCrud,
  crudReducerGenerator, prepareTable, resetPrepareTable,
} from '@cobrains/react-kit';
import { initialState } from '../initialState';

/** plants-redux.js */
const plantsPageActions = crudActionsGenerator("plantsPage");
export const plantsPageReducer = crudReducerGenerator("plantsPage", initialState.plantsPage);

/** Thunk */
export const getPlants = (params) => getCrud(plantsPageActions, `plant`, params);
export const preparePlant = (id , params) => prepareCrud(plantsPageActions, `plant/${(id)?`${id}/`:''}prepare` , params);
export const prepareToken = (id, params= {id}) => prepareCrud(plantsPageActions, `dashboard/api-token/prepare`, params);
export const createToken = (data) => createCrud(plantsPageActions, `dashboard/api-token`, data);
export const resetPreparePlant = () => resetPrepareCrud(plantsPageActions);
export const preparePlantTable = (id) => prepareTable(plantsPageActions, `plant/${(id)?`${id}/`:''}prepare`);
export const resetPreparePlantTable = () => resetPrepareTable(plantsPageActions);
export const createPlant = (data) => createCrud(plantsPageActions, `plant`, data);
export const editPlant = (data , id) => editCrud(plantsPageActions, `plant/${id}`, {data, id});
export const deletePlant = (id) => deleteCrud(plantsPageActions, `plant/${id}`, id);
export const plantSelected = (item) => selectedCrud(plantsPageActions, item);
export const plantResetSelected = () => resetSelectedCrud(plantsPageActions);
export const setDialog = (name, status) => setCrudDialog(plantsPageActions, {name, status});
export const addPlantQueryFilter = (filter) => addQueryFilter(plantsPageActions, filter);
export const resetPlantsData = () => resetDataCrud(plantsPageActions);
