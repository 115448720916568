import React, {useEffect} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import {compose} from "redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import {logout} from "@cobrains/react-kit";

const useStyles = makeStyles(theme => ({
    root: {
        background: '#fff',
        width:'100%',
        height: '100vh',
        position:'absolute',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        top:0,
        left:0,
        zIndex:9999
    },
}));

function Logout(props){
    const classes = useStyles(props);

    useEffect(() => {
        logout();
        window.location.href = '/signin';
    }, []);

    return(
      <div className={classes.root}>
        <CircularProgress size={32} style={{color:'#333', marginTop:32}} />
      </div>
    )
}

export default Logout;
