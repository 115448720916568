import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Fade from "@material-ui/core/Fade";
import Background from '../../../assets/img/GoGreenForLife-Preview.png'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    maxHeight:'100vh',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    overflowY:'hidden',
    overflowX:'hidden',
  },
  container:{
    display:'flex',
    position:'absolute',
    top:0,
    left:0,
    width:'100%',
    height:'100%',
    background: theme.palette.background.main,
    backgroundImage:`url(${Background})`,
    backgroundSize:'60%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    [theme.breakpoints.down('sm')]:{
      backgroundImage:`url()`,
    },
  },
  overlay:{
    width:'100%',
    height:'100vh',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
  },
  signInContainer:{
    backgroundColor : 'transparent',
    display:'flex',
    height: '100%',
    minWidth:540,
    [theme.breakpoints.down('sm')]:{
      minWidth:'100%',
    },
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    zIndex:1,
  },
}));

function SignInVertical(props){
  const {position, margin} = props;
  const classes = useStyles(props);

  function getStyle(){
    let marginStyle = null;
    if(props.margin === true){
      marginStyle = {
        height: '92vh',
        margin:'36px 0',
      }
    }
    switch(position){
      case 'left': return { ...{justifyContent:'flex-start'}, ...marginStyle };
      case 'right':  return { ...{justifyContent:'flex-end'}, ...marginStyle };
      default: return { ...{justifyContent:'center'}, ...marginStyle };
    }

  }

  const ContainerImage = () => {return (
    <div className={classes.container}>
      <div className={classes.overlay}/>
    </div>
  )}

  const ContainerSignIn = () => {return (
    <Fade in={true} timeout={1000}>
      <div className={classes.signInContainer} style={(margin)?{borderRadius:4}:null}>
        {props.children}
      </div>
    </Fade>
  )};

  const MarginLayout = (props) => {
    if(props.margin === true){
      return(
        <div style={{margin:31}}>
          {props.children}
        </div>
      )
    }
    return(
      <>
        {props.children}
      </>
    )
  };

  return(
    <MarginLayout {...props}>
    <div className={classes.root} style={getStyle()}>
        <ContainerImage/>
        <ContainerSignIn/>
    </div>
    </MarginLayout>
  )
}

export default SignInVertical;
