import GemLogo from '../../../assets/logos/logo-gem-green-energy.png'
import CbLogo from '../../../assets/logos/Cobrains.png'
import GemImage from '../../../assets/img/insegna-GEM-Casalpusterlengo-pannelli-solary-green-energy.jpg'
import GemVideo from '../../../assets/videos/GEM _ PROMO_OFFICIAL 2017.mp4'


const mock = {
  slides : [
    {
      type : 'logo',
      source : GemLogo,
    },
    {
      type : 'image',
      source : GemImage,
    },
    {
      type : 'video',
      source : GemVideo,
    }
  ],
  dashboard : {
    logo: GemImage,
    content: {
      count: 15,
      items: Array.from(Array(11).keys()).map( (value , index) => ({test : `ciao ${index}`}))
    },
  },
  theme : {
    animation : 'fade',
    // #4e833c
    color : '#2e4770',
    font : '',
  },
};

export default mock
