import React, { useEffect, useState } from 'react';
import { addMessage, apiCall } from '@cobrains/react-kit';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Lottie from 'react-lottie';
import i18n from 'i18next';
import DataDashboard from './components/DataDashboard';
import { getSlider, resetSliderData } from '../../../redux/pages/custom-slider-page-redux';
import rotate from '../../../assets/icons/rotate';
import rotateBackground from '../../../assets/img/rotateBackground.jpeg';
import sliderload from '../../../assets/loaders/loader.json';
import SignInFormSlider from '../../auth/components/SignInFormSlider';
import { changeLocale } from '../../../translations/i18n';


export const Iframe = React.memo((props) => React.createElement('iframe', props));

const useStyles = makeStyles(theme => ({
  wrapper: {
    display : 'flex',
    alignItems : 'center',
    height : '100vh'
  },
  dataContainer : {
    flex  : 1 ,
    display : 'flex' ,
    justifyContent : 'center',
    backgroundColor : '#ededed',
    height : '100%',
  }
}));

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: sliderload,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

let timeId;




function DataSlide(props) {
  const classes = useStyles(props);
  const language = 'en'
  const [index , setIndex] = useState(0);
  const [slider , setSlider] = useState(null);
  const [orientation , setOrentation] = useState('null');
  const [login , setLogin] = useState(false);
  const [loader , setLoader] = useState(true);
  const [refresh , setRefresh] = useState(false);

  function setScreenOrientation() {

    if (window.matchMedia("(orientation: landscape)").matches) {
      setOrentation('landscape')
    }
    if (window.matchMedia("(orientation: portrait)").matches) {
      setOrentation('portrait')
    }
  }

  function getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }


  useEffect(() => {
    if (props.slide && props.slide.language) {
      i18n.init({ preload: [`${props.slider.data.content.sliders.language}`] });
      i18n.loadLanguages([`${props.slider.data.content.sliders.language}`]);
      changeLocale(`${props.slider.data.content.sliders.language}`);
    }
  }, [props.slider.data.content.sliders]);

  function Refresh(){
    setRefresh(true)
    props.onApiCall({method:'GET',url:`custom-view/dashboard/${props.match.params.id}`}).then(
      res=> {

        setSlider(res.data.content)
      },
      err => {

      }
    )
  }

  function setDashboardRefreshInterval() {
    timeId = setInterval(() => Refresh(), 30000
    );
  }

  useEffect(() => {
    if (window.matchMedia("(orientation: landscape)").matches) {
      setOrentation('landscape')
    }
    if (window.matchMedia("(orientation: portrait)").matches) {
      setOrentation('portrait')
    }

    window.addEventListener('resize', setScreenOrientation);


     props.onApiCall({method:'GET',url:`custom-view/dashboard/${props.match.params.id}`}).then(
      res => {
        setDashboardRefreshInterval()
        props.onGetSlider(props.match.params.id)
        setLoader(false)
      },
      err =>{
        if(err.response && err.response.data && err.response.data.messages && err.response.data.messages[0].message === "unauthorized") {
          setLoader(false);
          setLogin(true);
        }
        else if (err.response && err.response.data && err.response.data.messages && err.response.data.messages[0].message === "group not found") {
          setLoader(true);
          setTimeout(() => {
            window.location.href = `/cerca`
          },3000);
        }
        else{
          setLoader(true);
          setTimeout(() => {
            window.location.href = `/cerca`
          },2000);
        }

      })
  }, []);

  function getError() {
    if (props.errors.get.data.messages && props.errors.get.data.messages.length > 0 && props.errors.get.data.messages[0].message === 'unauthorized' || login === true){
      return(
        <SignInFormSlider onLogin={() => {
          props.onGetSlider(props.match.params.id)
        }}
        />
      )}
    if ( (props.errors.get.data.messages && props.errors.get.data.messages.length > 0) || (props.errors.get.data && typeof props.errors.get.data === 'string')){
      setLoader(true);
      setTimeout(() => {
        window.location.href = `/cerca`
      },3000);
      props.onAddMessage('Erorre nella chiamata controllare la conessione internet', 'warning');
      return(
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',height : '100%'}}>
          <div style={{width:'50%'}}>
            <Lottie
              options={defaultOptions}
              isStopped={false}
              isPaused={false}
            />
          </div>
        </div>
      )
    }
    return null
  }

  return(
    ((!props.loaders.get && !loader) || refresh)  ?
      (props.slide === undefined) ?
        getError()
        :
        (
          <>
            {(orientation === 'portrait') ? (
              <div style={{overflow : 'hidden', maxHeight : '100vh'}}>
              <Iframe
                src={window.location.href}
                style={{
                  border : 'none',
                  transform: `scale(${document.body.scrollWidth / 1289})`,
                  transformOrigin: `0`,
                  width: `1289px`,
                  height: '786px',
                  overflow: 'hidden'
                }}
              ></Iframe>
              </div>
            )
              : (
                <>
                  <div className={classes.dataContainer}>
                    <DataDashboard
                      name={props.match.params.id}
                      index={index}
                      sliders={(slider === null) ? props.slider.data.content.sliders : slider.sliders}
                      language="it"
                      mock={props.mock}
                      data={(slider === null) ? props.slider.data.content.items[0] : slider.items[0]}
                    />
                  </div>
                </>
          )}
          </>
        )
      : (
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',height : '100%'}}>
          <div style={{width:'50%'}}>
            <Lottie
              options={defaultOptions}
              isStopped={false}
              isPaused={false}
            />
          </div>
        </div>
      )
  )
}


const mapStateToProps = state => ({
  slider: state.visualPage,
  errors:state.visualPage.errors,
  slide: state.visualPage.data.content.sliders,
  loaders: state.visualPage.loaders,
  user: state.auth.user,
});

const mapActionsToProps = {
  onGetSlider : getSlider,
  onReset : resetSliderData,
  onApiCall : apiCall,
  onAddMessage : addMessage,
};

export default compose(
  connect(mapStateToProps , mapActionsToProps)
)(DataSlide)
