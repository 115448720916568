import { withStyles } from '@material-ui/core/styles'
import { compose } from 'redux'
import {
  addMessage,
  apiCall,
} from '@cobrains/react-kit';
import {connect} from 'react-redux'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useEffect, useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Background from '../../../assets/tokensBackground.png';
import Logo from  '../../../assets/logos/logo-gem-green-energy.png'
import SignInFormSlider from '../../auth/components/SignInFormSlider';

const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    flex : 1.5 ,
    display : 'flex',
    flexDirection : 'column',
    justifyContent:'center',
    alignItems:'center',
    alignSelf : 'flex-end' ,
    width : 512,
    [theme.breakpoints.down('sm')]:{
    width: '100%',
    },
  },
  overlay : {
    position: 'absolute',
    width: '100%!important',
    height: '100%!important',
    background : '#fff',
    top: 0,
    left: 0,
    zIndex: 1300,
  },
  main: {
    flex : 3,
    order: 2,
    height : '100%',
    background: theme.palette.background.main,
    backgroundImage:`url(${Background})`,
    [theme.breakpoints.down('sm')]: {
      backgroundImage : 'unset'
    },
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left',
    backgroundSize : '72%',
    minHeight: 512
  },
  textField:{
    backgroundColor : 'rgba(255,255,255,0.9)',
    marginTop:20,
    width:'60%',
    [theme.breakpoints.down('sm')]: {
      marginTop : 'auto'
    },
  },
  button:{
    width:'60%',
    marginTop:40,
    [theme.breakpoints.down('md')]: {
      marginBottom : 'auto'
    },
    '& disabled':{
      backgroundColor : 'rgba(233,233,233,0.9)',
    }
  },
  disabled:{
    backgroundColor : 'rgba(233,233,233,0.9)',
  },
  img:{
    width:300,
    marginBottom : 40,
    [theme.breakpoints.down('md')]: {
      width:300,
      margin : 'auto'
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      margin : 'auto'
    },
  }
}));

function TokensPage (props){
  const classes = useStyles(props);
  const [group , setGroup] = useState('');
  const [login , setLogin] = useState(false);




  function checkValidation(){
    props.onApiCall({method:'GET',url:`group/update-data/${group}/auth`}).then(
      res => {
        window.location.href = `/${group}/slider`
      },
      err=> {
        if (err.response && err.response.data && err.response.data.messages && err.response.data.messages[0].message === "unauthorized") {
          setLogin(true);
        }
      }
    )
  }


  return (
    <div className={classes.overlay}>
      <div style={{display : 'flex',width:'100%',height : '100%',alignItems:'center',justifyContent:'center',flexDirection:'column'}} className={classes.main}>
        {/*<div className={classes.main} />*/}
        {(login) ? (
          <SignInFormSlider onLogin={() => {
            window.location.href = `/${group}/slider`
          }}
          />
        )
          : (
            <div className={classes.container}>
              <img alt='' src={Logo} className={classes.img} />
              <TextField
                id='group_code'
                label='Nome Azienda'
                variant="outlined"
                className={classes.textField}

            // value={username.toLowerCase()}
                onChange={(e) => setGroup(e.target.value)}
                disabled={false}
                InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div style={{width:30}}>
                    <i className="icon-impianti" style={{ fontSize: 20 , color : 'rgba(0,0,0,0.7)' , marginLeft : -12 }} />
                  </div>
                </InputAdornment>
              ),
            }}
              />
              <Button
                onClick={checkValidation}
                variant="contained"
                color="primary"
                disabled={!group}
                classes={{disabled : classes.disabled}}
                className={classes.button}
              >
              Conferma
              </Button>
            </div>
      )}

      </div>
    </div>
  )
}


const mapStateToProps = state => ({
  user: state.auth.user,
  users: state.usersPage,
  dialogs: state.usersPage.dialogs,
  loaders: state.usersPage.loaders,
  errors: state.usersPage.errors,
  prepare: state.usersPage.prepare,
  filters: state.usersPage.filters
});

const mapActionsToProps = {
  onApiCall : apiCall,
  onAddMessage : addMessage,
};

export default compose(
  withStyles({withTheme: true}),
  connect(mapStateToProps , mapActionsToProps)
)(TokensPage)
