import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { translate } from '../../../../translations/i18n';
import CurrentPower from '../../../../assets/icons/current_power'


const useStyles = makeStyles(theme => ({
  wrapper: {
    width:'100%',
    height : '100%',
    display : 'flex',
    flexDirection : 'column',
    justifyContent : 'flex-end',
    alignItems : 'center',
    [theme.breakpoints.down('md')]:{
      paddingBottom : '0px !important'
    },
  },
  wrapperIcon : {
    width: '35%',
    margin : '5% 0px',
    [theme.breakpoints.down('md')]:{
      margin : '20% 0px 15%',
      width: '40%',
    },
    [theme.breakpoints.down('sm')]:{
      margin : '10% 0px 5% 0px',
      width: '30%',
    }

  },

  unitText:{
    fontSize : 40 ,
    [theme.breakpoints.down('sm')]:{
      fontSize : '18px !important'
    },
    fontWeight : 'bold'
  },
  descText:{
    fontSize : 16,
    color : '#888888',
    textAlign : 'center'
  },
  mesureText:{
    lineHeight : 1,
    [theme.breakpoints.down('md')]:{
      fontSize : '14px !important'
    },
  },
  percentText:{
    [theme.breakpoints.down('md')]:{
      fontSize : '12px !important'
    },
  },

}));

export default function Co2Graph(props) {
  const classes = useStyles(props);
  const {value = 0, middleText = 'middleText', color =  '#df523c' , text = 'text', loader = false , valueText = 0} = props;

  return(
    <div className={classes.wrapper} style={(window.innerWidth <= 1440) ? {paddingBottom : 20} : {}}>
      <div className={classes.wrapperIcon}>
        {CurrentPower({color})}
      </div>

      <div style={{width : '100%' , alignItems : 'center' , display : 'flex' , flexDirection : 'column' , marginBottom : 24}}>
        <div style={{display : 'flex',alignItems:'baseline'}}>
          <Typography className={classes.unitText} style={(window.innerWidth <= 1440) ? {fontSize : 30,fontWeight : 'bold'} : {fontSize : 40,fontWeight : 'bold'}}>{valueText}{' '}kWh</Typography>
        </div>

        <Typography className={classes.descText} style={(window.innerWidth <= 1440) ? {fontSize : 12,} : {}}>
          {translate('data.total_daily_production',{lgn : props.language})}
        </Typography>
      </div>
    </div>
  )
}
