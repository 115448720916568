import React from 'react';

export default function CurrentPower(props){
  const {color = 'rgb(0, 0, 0)'} = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Livello_1"
      data-name="Livello 1"
      viewBox="0 0 96 96"
    >
      <defs>
        <style type="text/css">{`cls-1{fill:${color};}`}</style>
      </defs>
      <title>Tavola disegno 69 copia 5</title>
      <path
        fill={color}
        className="cls-1"
        d="M36.47,72.38A28.19,28.19,0,1,1,72.21,45.22a29.1,29.1,0,0,1-.22,3.5,2.5,2.5,0,0,0,5,.62A33.19,33.19,0,1,0,35.13,77.2a2.63,2.63,0,0,0,.67.09,2.5,2.5,0,0,0,.67-4.91Z"
      />
      <path
        fill={color}
        className="cls-1"
        d="M42.77,28.48v16.7L31.64,56.31a2.52,2.52,0,0,0,0,3.54,2.51,2.51,0,0,0,3.54,0L47,48a2.51,2.51,0,0,0,.73-1.77V28.48a2.5,2.5,0,1,0-5,0Z"
      />
      <path
        fill={color}
        className="cls-1"
        d="M84.41,53.05a2.45,2.45,0,0,0-2.15-.67L70.51,54.3a2.5,2.5,0,1,0,.81,4.93l4-.64L61.63,72.69l-6.5-6.36a2.5,2.5,0,0,0-1.75-.71h0a2.49,2.49,0,0,0-1.76.76L38.67,79.73a2.51,2.51,0,0,0,.06,3.54,2.47,2.47,0,0,0,1.74.7,2.51,2.51,0,0,0,1.79-.76L53.43,71.66,59.93,78a2.44,2.44,0,0,0,1.78.71A2.52,2.52,0,0,0,63.48,78L79.27,61.65l-.61,4.68a2.49,2.49,0,0,0,2.16,2.8,1.77,1.77,0,0,0,.32,0A2.5,2.5,0,0,0,83.62,67l1.53-11.81A2.5,2.5,0,0,0,84.41,53.05Z"
      />
    </svg>
  )
}

