import {
  getCrud,
  crudActionsGenerator,
  resetDataCrud,
  crudReducerGenerator,
} from '@cobrains/react-kit';
import { initialState } from '../initialState';

/** plants-redux.js */
const sliderPageActions = crudActionsGenerator("sliderPage");
export const sliderPageReducer = crudReducerGenerator("sliderPage", initialState.sliderPage);

/** Thunk */
export const getSlider = (id = 1 , params) => {
  return getCrud(sliderPageActions, `group/update-data/${id}`, params);
}
export const resetSliderData = () => resetDataCrud(sliderPageActions);
