
const crudFields = [
  {
    key: 'id',
    visible:false
  },
  {
    key: 'username',
    label: 'Username',
  },
  {
    key: 'email',
    label: 'Email',
    type : 'email',
  },
  {
    key: 'type',
    label: 'Tipo di utente',
  },
  {
    key : 'group',
    label : 'Gruppo',
    refs: [{ key:'type', condition: 'eq', value: 'C'}]
  }
];

export default crudFields
