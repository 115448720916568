import React from "react";
import {AppBarSidebarLayout} from '@cobrains/react-kit'
import Helmet from 'react-helmet'
import {SuperAdminRoutes} from "./admin/SuperAdminRoutes";
import {CustomerAdminRoutes} from "./customer/CustomerAdminRoutes";
import {NotAuthenticatedRoutes} from "./not-authenticated/NotAuthenticatedRoutes";
import {SuperAdminSettings} from "./admin/SuperAdminSettings";
import Theme from '../theme/Theme';
import NotAuthenticatedLayout from "./not-authenticated/NotAuthenticatedLayout";
import SignInVertical from "../views/auth/layout/SignInVertical";
import Logo from '../assets/logos/logo.svg'
import AppBarMenu from '../components/ui/app-bar-menu/AppBarMenu';

const defaultSidebar = {
  sidebar: {
    openOnHover : false,
    menu: SuperAdminSettings.sidebarmenu,
    background: Theme.palette.primary.main,
    collapseLuminance:0.2,
    color: Theme.palette.primary.contrastText,
    activeRouteColor: Theme.palette.primary.main,
    activeRouteBackground: Theme.palette.primary.contrastText
  }
};

const Environments = {
  notAuthenticated: {
    key: 'notAuthenticated',
    type: null,
    routes: NotAuthenticatedRoutes,
    layout: (props) => (
      <NotAuthenticatedLayout {...props} />
    ),
    theme: Theme,
    settings: {
      signIn: {
        layout: (props) => <SignInVertical position="left" margin>{props.children}</SignInVertical>
      }
    }
  },
  superAdmin: {
    key: 'superAdmin',
    type: 'SA',
    routes: SuperAdminRoutes,
    layout: (props) => (
      <>
        <Helmet>
          <style type="text/css">
            {`
                  .makeStyles-appBar-11{
                  box-shadow : unset;
                  max-height : 58px;
                  border-bottom : 2px solid rgb(71, 136, 97);
                  }
                  .makeStyles-appBar-13{
                  box-shadow : unset;
                  max-height : 58px;
                  border-bottom : 2px solid rgb(71, 136, 97);
                  }
                  .makeStyles-appBar-208{
                  box-shadow : unset;
                  max-height : 58px;
                  border-bottom : 2px solid rgb(71, 136, 97);
                  }
                  .makeStyles-buttonIcon-210{
                  margin : 0px 24px;
                  padding : 4px;
                  color : rgb(71, 136, 97);
                  width : 22px !important;
                  height : 22px !important;
                  }
                  .makeStyles-buttonIcon-15{
                  margin : 0px 24px;
                  padding : 4px;
                  color : rgb(71, 136, 97);
                  width : 28px !important;
                  height : 28px !important;
                  }
                `}
          </style>
        </Helmet>
        <AppBarSidebarLayout {...props} />
      </>
    ),
    theme: Theme,
    settings: {
      appbar: {
        title: 'Console di amministrazione',
        logo: Logo,
        style: {
          background: '#fff',
          color: '#000'
        }
      },
      sidebar: {
        avatarColor: '#fff',
        avatarBackground: '#0B1C37',
        openOnHover: false,
        menu: SuperAdminSettings.sidebarmenu,
        collapseLuminance: 0.2,
        background: '#478861',
        color: Theme.palette.primary.contrastText,
        activeRouteColor: '#478861',
        activeRouteBackground: '#fff'
      }
    }
  },
  Customer: {
    key: 'Customer',
    type: 'C',
    routes: CustomerAdminRoutes,
    layout: (props) => (
      <NotAuthenticatedLayout {...props} />
    ),
    theme: Theme,
  }
}

export default Environments;
