
const crudFieldsEdit = [
  {
    key: 'name',
    label: 'Nome',
    required : true
  },
  {
    key: 'description',
    label: 'Descrizione',
    required : false,
  },
  {
    key: 'type',
    label: 'Tipo di associazione',
    type : 'enum',
    component : 'select',
    required : true,
    values : [{label : 'Impianto', value : 1},{label : 'Gruppo', value : 2}]
  },
  {
    key: 'plant',
    label: 'Impianto',
    required : true,
    refs: [{ key:'type', condition: 'eq', value: 1}]
  },
  {
    key: 'group',
    label: 'Gruppo',
    required : true,
    refs: [{ key:'type', condition: 'eq', value: 2}]
  },
  {
    key : "image_1",
    visible : false
  },
  {
    key : "image_2",
    visible : false
  },
  {
    key : "image_3",
    visible : false
  },
  {
    key : "logo",
    visible : false
  },
  {
    key : "id",
    visible : false
  },
];

export default crudFieldsEdit
