import React from 'react';
import {ErrorsRoutes} from "../../routing/routes/ErrorsRoutes";
import {DashboardRoutes} from "../../routing/routes/DashboardRoutes";
import TokensPage from '../../views/application/token/TokensPage';
import Logout from '../../views/auth/Logout';

export const CustomerAdminRoutes = [
  {
    path : '/logout',
    name: 'Logout',
    component: Logout,
    exact: true,
  },
  {
    path : '/',
    name: 'Tokens',
    component: () => (
      <>
        <TokensPage />
      </>
    ),
    exact: true,
  },
    ...ErrorsRoutes,
  ...DashboardRoutes,
];
