import React from 'react';
import {AuthRoutes} from "../../routing/routes/AuthRoutes";
import {ErrorsRoutes} from "../../routing/routes/ErrorsRoutes";
import {DashboardRoutes} from "../../routing/routes/DashboardRoutes";
import TokensPage from '../../views/application/token/TokensPage';

export const NotAuthenticatedRoutes = [
  {
    path : '/cerca',
    name: 'Tokens',
    component: () => (
      <>
        <TokensPage />
      </>
    ),
    exact: true,
  },
    ...AuthRoutes,
    ...ErrorsRoutes,
    ...DashboardRoutes,
]
