import React from 'react';
import DashboardSlider from "./DashboardSlider";


function Slider(props){
  return(
    <>
      <DashboardSlider {...props} />
    </>
  )
}

export default Slider;
