import React, { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { changeLocale, translate } from '../../../../translations/i18n';
import total_daily_production from '../../../../assets/icons/total_daily_production'


const useStyles = makeStyles(theme => ({
  wrapper: {
    width:'100%',
    height : '100%',
    display : 'flex',
    justifyContent : 'flex-start',
    alignItems : 'center',
    [theme.breakpoints.down('md')]:{
      paddingBottom : '0px !important'
    },
  },
  wrapperIcon : {
    width: '14%',
    margin : '0px 0px 0px 16%',
    [theme.breakpoints.down('md')]:{
      margin : '18% 0px 15%',
      width: '25%',
    },
    [theme.breakpoints.down('sm')]:{
      margin : 0,
      width: '12%',
    },
    [theme.breakpoints.up(2560)]:{
      width: '14%',
      margin : '0px 0px 0px 4%',
    },
  },
  unitText:{
    // fontSize : 40 ,
    fontFamily: 'Nunito sans',
    fontSize : '2.5rem',
    [theme.breakpoints.down('sm')]:{
      fontSize : '1rem !important'
    },
    [theme.breakpoints.up(2560)]:{
      fontSize : '3.5rem !important'
    },
    fontWeight : 'bold'
  },
  descText:{
    fontFamily: 'Nunito sans',
    textTransform: 'uppercase',
    fontSize : '1.3rem',
    // fontSize : 16,
    [theme.breakpoints.down('sm')]:{
      fontSize : '0.6rem !important'
    },
    [theme.breakpoints.up(2560)]:{
      fontSize : '2.0rem'
    },
    color : '#888888',
    textAlign : 'center'
  },
  mesureText:{
    lineHeight : 1,
    [theme.breakpoints.down('md')]:{
      fontSize : '14px !important'
    },
  },
  percentText:{
    [theme.breakpoints.down('md')]:{
      fontSize : '12px !important'
    },
  },

}));

export default function Co2Graph(props) {
  const classes = useStyles(props);
  const {value = 0, middleText = 'middleText', color =  'rgb(78, 131, 60)' , text = 'text', loader = false , valueText = 0} = props;


  return(
    <div className={classes.wrapper} style={(window.innerWidth <= 1440) ? {} : {}}>
        <div className={classes.wrapperIcon}>
          {total_daily_production({ color })}
        </div>


      <div style={{alignItems : 'flexStart' , display : 'flex' , flexDirection : 'column', marginLeft : 16}}>
        <div style={{display : 'flex',alignItems:'baseline'}}>
          <Typography className={classes.unitText} style={(window.innerWidth <= 1440) ? {fontSize : 30,fontWeight : 'bold'} : {fontSize : 40,fontWeight : 'bold'}}>{valueText}{' '}kWh</Typography>
        </div>

        <Typography className={classes.descText} style={(window.innerWidth <= 1440) ? {fontSize : 16,} : {}}>
          Total daily production
        </Typography>
      </div>
    </div>
  )
}
